import React from 'react';
import Grid from '@mui/material/Grid';
import "./Counter.scss";
type Props = {
    prefix?:String,
    number?:string,
    suffix?:String,
    title?:String | React.ReactNode,
    img?:String | React.ReactNode,
    id?: string
}
const Counter =({img,prefix,number,suffix,title, id}:Props)=> {
    return (
        <Grid item xs={12} sm ={12} md={12} lg={4} xl={4} className="counterMainDiv">
            <div className='counter-widget-main-container' id={id}>
                <div className='counter-box'>
                    <div className="counter-box-elements">
                        {
                            img && (
                                <div className='imgSection'>
                                    <img src={`${img}`} alt=""/>
                                </div>
                            )
                        }
                        <div className="number-counter-wrapper">
                            <div className="number-counter-prefix">{prefix}</div>
                            <div className="number-counter">{number}</div>
                            <div className="number-counter-suffix">{suffix}</div>
                        </div>
                        <div className="counter-title">
                         {title}
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    );
}

export default Counter;
import React from 'react';
import Grid from '@mui/material/Grid';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import RecentResources from '../common/RecentResources';
import "./RPAServices.scss";
import Cards from '../../../../common-components/card/Card';
import CardChip from '../../../../common-components/card/CardChip';
import { title } from 'process';
import CardWithLeftHeader from '../../../../common-components/card/CardWithLeftHeader';
import Monolith_to_Microservices from "../../../../../assets/Monolith_to_Microservices.svg";
import Community from '../../../../../assets/data-science/svgexport-23.svg';
import Magnifier from '../../../../../assets/data-science/svgexport-24.svg';
import Roots from '../../../../../assets/data-science/svgexport-25.svg';
import Arrow from '../../../../../assets/data-science/svgexport-26.svg';
const RPAServices = () => {
    const boxesArr = [
        {
            title: "25%",
            description: "Significant operational cost savings"
        },
        {
            title: "65%",
            description: "Enhanced customer service"
        },
        {
            title: "55%",
            description: "Increased Employee satisfaction"
        },
        {
            title: "70%",
            description: "Comparatively less downtime"
        },
        {
            title: "75%",
            description: "Quick and error-free data transfer"
        },

    ]
    const techArr = [
        { img: "https://hexaviewtech.com/wp-content/uploads/2022/04/flow-logo-2.svg" },
        { img: "https://hexaviewtech.com/wp-content/uploads/2022/04/m2NdD6Dx_400x400.svg" },
        { img: "https://hexaviewtech.com/wp-content/uploads/2022/04/rpa-tool-img-3.svg" },
        { img: "https://hexaviewtech.com/wp-content/uploads/2022/04/azure-logic-apps400x200.svg" },
        { img: "https://hexaviewtech.com/wp-content/uploads/2022/04/xenonstack-ui-path-services.svg" },

    ]
    return (
        <Grid container className='rpa-main-container paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/RPA-Consulting-banner.jpg"}
                h1Heading={"RPA Consulting & Development Services"}
                textSectionA={"Intelligent Robotic Process Automation Solutions for High-Tech Revolution"}
                isScheduleCall={true}
            />
            <section className="rpa-section-B Section">
                <div className='container'>
                    <Grid container wrap='wrap' justifyContent="space-between" className='element-inner-section'>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="text-section element-widget-wrap">
                                <h2>What is Robotic Process Automation and <span>Why your business needs it?</span></h2>
                                <p style={{fontWeight:'bold'}}>Robotic process automation (RPA) is a form of automation technology based on metaphorical software robots or AI which automates the business processes.</p>
                                <div className='pera'>
                                    <p>RPA implementation empowers digital workflow adoption to ensure proper utilization of the available resources. It handles repetitive tasks in a much faster and more consistent mode than people, without the need for breaks. As a part of our custom RPA development process, we develop intelligent RPA Solutions that can help you to achieve outstanding operational efficiency.</p>
                                    <p>At Hexaview, we help businesses to integrate AI & ML with RPA to transform the business ecosystem. With our end-to-end RPA consulting services, we incorporate smart automation and help our clients’ to reduce the operational cost significantly, enhance productivity, and create amazing customer experiences at scale.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className="immage-section">
                                <img width="100%" src='	https://hexaviewtech.com/wp-content/uploads/2022/04/rpa-img1.svg' loading="lazy" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="rpa-section-C Section">
                <div className='container'>
                    <div className="text-section element-widget-wrap">
                        <h2>RPA as a Service <span>Offerings</span></h2>
                        <div className='pera'>
                            <p>We can help you discover opportunities to automate the business processes and eliminate barriers to RPA via our Robotic Process Automation services.</p>
                        </div>
                    </div>
                    <div className='card-wrapper'>
                        <Cards
                            headerIcon={<div className='header-num'>{"01"}</div>}
                            contentHeading={"Robotic Process Automation Consulting Services"}
                            contentText="We understand your business process and identify the requirements of Robotic Process Automation in your organization. If required, our RPA Consulting Experts prepare a comprehensive document that states the needs, benefits, risk factors, impacts, etc. of RPA for your business."
                            id='first-card'
                        />
                        <Cards
                            headerIcon={<div className='header-num'>{"02"}</div>}
                            contentHeading={"RPA Development Services"}
                            contentText="Our team of RPA experts design and develop intelligent applications using various automation tools like UiPath, Pega, etc. And we always continue to improve the processes until the app becomes the best version of itself."
                            id='second-card'
                        />
                        <Cards
                            headerIcon={<div className='header-num'>{"03"}</div>}
                            contentHeading={"Infrastructure and Automation Support"}
                            contentText="Once your organization has successfully implemented the RPA technology, we further help you to identify new areas which can be automated with RPA bots. Our RPA team has end-to-end support for your business efficiency management."
                            id='third-card'
                        />
                    </div>
                    <div className="btn-wrapper">
                        <div className='hexa-primary-btn'>
                            <p>Talk to our Expert <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="rpa-section-D Section"
                style={{
                    backgroundImage: `url(https://hexaviewtech.com/wp-content/uploads/2022/04/benefits-of-Robotic-Process-Automation.jpg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}
            >
                <div className='container'>
                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                        <div className='elements-wrapper'>
                            <div className="text-section element-widget-wrap">
                                <h2>What are the benefits of Robotic <br />Process Automation Services?</h2>
                                <p>With our RPA consulting and development services, we empower organizations to create virtual workforces (software robots) that drive business agility and efficiency. As per experts, RPA implementation can achieve.</p>
                            </div>
                            <div className='boxes-wrapper'>
                                {
                                    boxesArr.map(item => (
                                        <div className="box-container">
                                            <div className="box">
                                                <div className="title">{item.title}</div>
                                                <div className="description">{item.description}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </Grid>
                </div>
            </section>
            <section className="rpa-section-E Section">
                <div className='container'>
                    <div className="text-section element-widget-wrap">
                        <h2>RPA Development Process</h2>
                        <h3>Our RPA consultants work with you at every step<br/> of the development</h3>
                    </div>
                    <div className="image-wrapper">
                        <img width="100%" src="https://hexaviewtech.com/wp-content/uploads/2022/05/RPA-Development-Process9.svg" alt="" loading="lazy" />
                    </div>
                </div>
            </section>
            <section className="rpa-section-F Section">
                <div className='container-fluid'>
                    <div className="text-section element-widget-wrap">
                        <h2>RPA tools and technologies we leverage</h2>
                        <h4>We apply a combination of open-source tools along<br/> with AI & ML to create smarter bots for you.</h4>
                    </div>
                    <div className="cards-wrapper">
                        {
                            techArr.map(item => (
                                <CardChip
                                    img={item.img}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>
            <section className="rpa-section-G Section">
                <div className='container'>
                    <div className="text-section element-widget-wrap">
                        <h2>Why Choose Hexaview for RPA Services</h2>
                        <h4>Hexaview’s RPA developers ensure to build a solution that understands your goals and objectives by providing tailored RPA development services for your business.</h4>
                    </div>
                    <div className="cards-wrapper">
                        <CardWithLeftHeader
                            headerImgUrl={Community}
                            contentHeading={"Extensive AI expertise"}
                            descriptionText={"Our AI capabilites empowers us to enhance the RPA impact and enable scalable intelligent automation."}
                        />
                        <CardWithLeftHeader
                            headerImgUrl={Roots}
                            contentHeading={"Flexible Engagement Models"}
                            descriptionText={"We provide multiple service models to align development with the pace of your business."}
                        />
                        <CardWithLeftHeader
                            headerImgUrl={Magnifier}
                            contentHeading={"Focus on you"}
                            descriptionText={"We have a 100% CSAT which is a testament to our top-notch quality services."}
                        />
                        <CardWithLeftHeader
                            headerImgUrl={Arrow}
                            contentHeading={"We’re still learning, too"}
                            descriptionText={"We have provided RPA technology solutions to companies in Banking, Healthcare, Computer Software, among many others. We strive to add more experience every day."}
                        />
                    </div>
                </div>
            </section>
            <RecentResources />
        </Grid>
    );
}

export default RPAServices;
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
type Props={
    icon?:string | string | React.ReactNode,
    description?:String
}
const IconBox =({icon=<CheckCircleIcon />,description}:Props)=> {
    return (
        <div className='icon-box-container'>
            <div className="icon-box-wrapper">
                <div className="icon">{icon}</div>
                <div className="description">{description}</div>
            </div>
            
        </div>
    );
}

export default IconBox;
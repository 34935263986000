import React from 'react';
import Grid from '@mui/material/Grid';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import CloudGetTouchBox from '../commonSection/cloudGetTouchBox/Index'
import ServicePartner from '../commonSection/servicePartner/Index'
import Company from '../commonSection/company/Index'
import Consultant from '../commonSection/consultant/Index'
import ContactMailIcon from '@mui/icons-material/ContactMail';
import "./style.scss";
import Cycle from '../../../../../assets/FinTech-wealth-management/capital-market/cycle.svg'
import Hands from '../../../../../assets/FinTech-wealth-management/capital-market/hands.svg'
import Devices from '../../../../../assets/FinTech-wealth-management/capital-market/devices.svg'
const Index = () => {

    return (
        <Grid container className='cloud-migration-solutions paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/cloud-banner.jpg"}
                h1Heading="Cloud Migration Solutions"
                textSectionA={"Hexaview’s cloud migration solutions and services help you migrate your data to the cloud with minimal disruption while modernizing your business."}
                isScheduleCall={true}
            />
            {/*...service text section...*/}
            <section className='hybrid-cloud-computing-solution-section-B Section'>
                <div className='container'>
                    <Grid container className='inn-container'>
                        <Grid className="hybrid-cloud-computing-solution-section-B-left" item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className='image-section'>
                                <img src='https://hexaviewtech.com/wp-content/uploads/2022/03/hybrid-cloud.png' style={{ width: '90%' }} />
                            </div>
                        </Grid>
                        <Grid className="hybrid-cloud-computing-solution-section-B-right" item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="heading">
                                <h2>Hybrid Cloud <br />Computing Solutions</h2>
                            </div>
                            <div className="text-section">
                                <div>
                                <p>Cloud adoption drives cost-effectiveness, robustness, scalability, and superior product quality. But one model fits all don’t apply to cloud deployments. Applications have different performance, security, and compliance requirements, and therefore, other approaches are needed.</p>
                                </div>
                            </div>
                            <div className="text-section">
                                <div>
                                <p>Hexaview’s Hybrid cloud computing solutions offer the right mix of private and public cloud to help optimize your IT resources and save your costs.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className='hybrid-cloud-section-C Section'>
                <div className='container'>
                    <Grid item xs={12}>
                        <div className='heading-wrapper'>
                            <h2>Solve your complex cloud</h2>
                            <h4>challenges with Hexaview Hybrid Cloud Computing Solutions</h4>
                            <p>With applications and data across multiple clouds and data centers, it’s no small feat to securely connect the different environments and drive network efficiencies. As defined, a “hybrid cloud” is a combination of on-premises infrastructure, private cloud services, and a public cloud—such as Amazon Web Services (AWS) or Microsoft Azure with orchestration among the various platforms. A hybrid cloud solution for small businesses provides greater flexibility by moving workloads between cloud solutions.</p>
                        </div>
                        <div className="service-wrapper-card">
                            <Grid container wrap='wrap' >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className='card'>
                                        <img src={Hands} alt="" />
                                        <h4>Optimize costs</h4>
                                        <p>Cost-effective solution and pay only for the resources you need right now</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className='card'>
                                    <img src={Devices} alt="" />
                                        <h4>Increase business agility</h4>
                                        <p>Scale swiftly to support your changing business requirements</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className='card'>
                                    <img src={Cycle} alt="" />
                                        <h4>Streamline IT process</h4>
                                        <p>an integrated platform and high-performance cloud connectivity</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} className="text-section">
                                    <div>
                                    <p>Hexaview’s Hybrid cloud computing solutions offer the right mix of private and public cloud to help optimize your IT resources and save your costs.</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </div>
            </section>
            <section className='hybrid-cloud-computing-solution-section-D Section'>
               <div className='container'>
                <Grid container >
                        <Grid className="hybrid-cloud-computing-solution-section-left" item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <div className="heading">
                                <h2 >Why should small businesses<br /><span>consider hybrid cloud solutions?</span></h2>
                            </div>
                            <div className="text-section">
                                <div>
                                <p>Small business owners should consider hybrid cloud solutions for small businesses for the following reasons and benefits</p>
                                </div>
                            </div>
                            <div className='hexa-primary-btn'>
                            <p>Talk to Expert <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                            </div>
                        </Grid>
                        <Grid className="hybrid-cloud-computing-solution-section-right" item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <div className="text-section">
                                <div>
                                    <h4>Low Cost</h4>
                                    <p>One of the primary driving forces behind small businesses' switch to the hybrid cloud is its cost-effectiveness. It's costly to buy and maintain your servers.</p>
                                </div>
                            </div>
                            <div className="text-section">
                                <div>
                                    <h4>Enhanced Security</h4>
                                    <p>Security can be a challenge, but with a hybrid cloud, you'll significantly decrease the risk compared to the public cloud. In the hybrid cloud model, you'll be able to employ the security of a private cloud with the power and capabilities of the public cloud.</p>
                                </div>
                            </div>
                            <div className="text-section">
                                <div>
                                    <h4>Increased Speed</h4>
                                    <p>While a hybrid cloud isn't necessarily faster than a public cloud, the advantage is that it can be optimized to reduce latency, making it easier for data to move to where it needs to be. A hybrid cloud can also leverage edge computing architectures to boost speed further.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
               </div>
            </section>
            <Consultant 
            heading="Let's Talk Cloud"
            description={"Reach out, and we'll chart hybrid cloud services built for the ever-changing business demands."}
            />
            <ServicePartner />
            <Company />
            <CloudGetTouchBox
                h2Heading="Looking for a Cloud Migration Partner?"
                textSection="Hexaview’s Cloud consultants and developers team carries out cloud migration solutions with minimum disruption and ensures you get sustainable business outcomes by leveraging cloud environments."
                buttonText="Migrate to Cloud"
            />
        </Grid>
    );
}

export default Index;
import React from 'react'
import './Security.scss';
import { Card, Grid } from '@mui/material';
import { IconText } from '../corporate-social-responsibility/women-welfare-network/WomenWelfareNetwork';
import StarIcon from '@mui/icons-material/Star';
import building from '../../../../assets/building.svg';
import CheckIcon from '@mui/icons-material/Check';
import SecondaryTopPageSection from '../../../common-components/pageTop-section/SecondaryTopPageSection';
import AntiVirus from '../../../../assets/corporate-overview/Security/svgexport-11.png';
import Encryption from '../../../../assets/corporate-overview/Security/svgexport-12.png';
import Wireless from '../../../../assets/corporate-overview/Security/svgexport-20.png';
import Internet from '../../../../assets/corporate-overview/Security/svgexport-19.png';
import Software from '../../../../assets/corporate-overview/Security/svgexport-18.png';
import Key from '../../../../assets/corporate-overview/Security/svgexport-17.png';
import Vpn from '../../../../assets/corporate-overview/Security/svgexport-16.png';
import Network from '../../../../assets/corporate-overview/Security/svgexport-15.png';
import Equipment from '../../../../assets/corporate-overview/Security/svgexport-14.png';
import Penetration from '../../../../assets/corporate-overview/Security/svgexport-13.png';
import Password from '../../../../assets/corporate-overview/Security/svgexport-10.png';
import Search from '../../../../assets/corporate-overview/Search.png';
import Registered from '../../../../assets/corporate-overview/Registered.png';
import Agreement from '../../../../assets/corporate-overview/Agreement.png';

const Security = () => {
    return (
        <div className="security-main-container paddingTopSection">
            <SecondaryTopPageSection
                img={"https://hexaviewtech.com/wp-content/uploads/2020/12/Security-1.png"}
                h1Heading="Your Data Security, Our First Priority" />

            <section className="security-section-B Section">
                <div className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="image-card">
                                <div className="heading">
                                    <h2>Technical Policy</h2>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="card-styling">
                                <IconText
                                    image={Password}
                                    heading={"Password Manager"}
                                    textSection={"Our operational suite uses a dedicated password manager to store and manage online credentials. These details are stored in an encrypted database backed by a master password."} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="card-styling">
                                <IconText
                                    image={AntiVirus}
                                    heading={"Mandatory Anti-virus"}
                                    textSection={"To keep your virtual assets tightly secured, we lay a state of the art anti-virus module across all system resources."} />
                            </Card></Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="card-styling">
                                <IconText
                                    image={Penetration}
                                    heading={"Third-Party Penetration Test"}
                                    textSection={"We include third-party pen testing to shut the window on any possibility of malicious activity or any unauthorized access."} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="card-styling">
                                <IconText
                                    image={Equipment}
                                    heading={"IT Equipment Security Policy"}
                                    textSection={"IT equipment policy covers computers and related tools (PC, laptop, etc.) along with communication tools (telephone, fax, etc.)"} />
                            </Card></Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="card-styling">
                                <IconText
                                    image={Network}
                                    heading={"Network Security Policy"}
                                    textSection={"Lays the rules for computer network access. Prescribes the most up-to-date anti-virus software on all computers directly connected to the internal network or via VPN."} />
                            </Card></Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="card-styling">
                                <IconText
                                    image={Software}
                                    heading={"Software Installation Policy"}
                                    textSection={"Policies regarding software cover licensed software that is used and selected from an approved software list."} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="card-styling">
                                <IconText
                                    image={Internet}
                                    heading={"Internet Usage Policy"}
                                    textSection={"Outlines principles for internet use. Usage monitoring is carried out through United Threat Management (UTM)."} />
                            </Card></Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="card-styling">
                                <IconText
                                    image={Wireless}
                                    heading={"Wireless Access Policy"}
                                    textSection={"Covers all wireless data communication devices (i.e., personal computers, cellular phones, PDAs, etc.) connected to the company's wireless network."} />
                            </Card></Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="card-styling">
                                <IconText
                                    image={Vpn}
                                    heading={"Secure VPN for WFH"}
                                    textSection={"Remote systems connect to Hexaview's VPN to become an extension of our data network and subject to the same network guidelines extended to any other host on the web."} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="card-styling">
                                <IconText
                                    image={Key}
                                    heading={"Multi-Factor Authentication"}
                                    textSection={"For security enhancement, we use MFA for network access to all privileged and non-privileged accounts, including network users and administrators."} />
                            </Card></Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card className="card-styling">
                                <IconText
                                    image={Encryption}
                                    heading={"Sensitive Data Encryption"}
                                    textSection={"This ensures your sensitive data cannot be accessed by database administrators, cloud administrators, or any non-authorized user."} />
                            </Card></Grid>
                    </Grid>
                </div>
            </section>

            <section className="security-section-C Section">
                <div className="image-background-overlay" />
                <div className="container">
                    <div className="subSection-C1">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <div className="heading">
                                    <h3>Compliance Policy</h3>
                                    <hr style={{ width: "12%" }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <ul>
                                    <li> <h3><StarIcon /> An internal audit conducted every quarter by Security Auditor </h3></li>
                                    <li><h3><StarIcon /> External Audit and Review of Policies conducted by ex- BCG Consultant</h3></li>
                                    <li><h3><StarIcon /> Members are barred from trading in US securities to avoid any conflict of interest</h3></li>
                                    <li><h3><StarIcon /> While working on assignments, team members must</h3>
                                        <ul style={{ marginLeft: "30px" }}>
                                            <li><CheckIcon />
                                                Sign project-specific NDA</li>
                                            <li><CheckIcon />
                                                Receive monitored access specific to participating members
                                            </li>
                                            <li><CheckIcon />
                                                Receive access to cloud-based secured project management tools</li>
                                            <li><CheckIcon />
                                                Keep customers' identities confidential in all internal and external communication</li>
                                            <li><CheckIcon />
                                                Adhere to other specific security guidelines, as indicated by the customer</li>
                                        </ul>
                                    </li>

                                </ul>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>


            <section className="subSection-C2 Section">
                <div className="container">
                    <div className="heading">
                        <h2>Employement Policy</h2>
                    </div>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card className="card-styling-C2">
                                <IconText
                                    image={Search}
                                    heading={"Background Verification"}
                                    textSection={"A comprehensive check includes corroboration of education, employment record, and criminal record before bringing an employee on board."} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card className="card-styling-C2">
                                <IconText
                                    image={Registered}
                                    heading={"Registered Employees"}
                                    textSection={"Employees are registered under Nasscom NSR (National Skills Registry)"} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card className="card-styling-C2">
                                <IconText
                                    image={Agreement}
                                    heading={"Non-Disclosure Agreement (NDA)"}
                                    textSection={"The members sign a comprehensive NDA and allegiance to the Security Policy at the time of joining. Contributing members sign a project-specific NDA."} />
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </section>


            <section className="security-section-D Section">
                <div className="container">
                    <div className="heading">
                        <h1>Privacy Policy</h1>
                    </div>
                    <hr style={{ width: "100%" }} />
                    <br />
                    <div className="text-section">
                        <p>
                        Hexaview follows global regulations and industry practices to maintain its customer’s data privacy and security. Our website <a href="/privacy-policies">privacy policy</a> is to inform you about our practices in connection with the collection, use and disclosure of the personal data you make available to us. Furthermore, our Policy describes the type of data that may be collected by our use of cookies and other technologies across our Website.</p>
                    <p>
                        <span style={{ fontWeight: "600" }}> GDPR Regulation:</span> To strengthen an individual’s rights to privacy, the European Union brought about the <span style={{ fontWeight: "600" }}> General Data Protection Regulation </span> or <span style={{ fontWeight: "600" }}> GDPR </span>, supporting existing directives on data protection. We extend these capabilities not only to customers in the EU but to all our customers worldwide</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Security
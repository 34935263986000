import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import OwlCarousel from '../../../common-components/owl-carousel/Index'
import Banner from '../../../../assets/home/banner.png'
import "./style.scss";


const Index = () => {


  return (
    <section className='finTech-Solution-section'>
              <div className='container'>
                <Grid container className='element-inner-section'>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <div className='content'>
                            <h1>Empowering<br /> Businesses with Powerful<br /><span>FinTech Solutions</span></h1>
                            <p>As a leading financial software development partner, Hexaview possesses deep domain expertise.</p>
                            <div className='btn-section'>
                                <div className='hexa-default-btn'>
                                <p>Explore More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <div className='banner'>
                          <div className='background-banner'/>
                            <img src={Banner} alt=""/>
                        </div>
                    </Grid>
                </Grid>  
                < OwlCarousel />            
              </div>
            </section>
  );
}

export default Index;
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
// import Slider from "react-slick";
import SwiperCore, { EffectCoverflow, Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/navigation';
import "./style.scss";

type Props = {
    array?: any
    slidesToShow?: any
}

SwiperCore.use([EffectCoverflow]);

const Index = ({array, slidesToShow = 3}: Props) => {


   

  

    return (
        <section className='case-study-section Section'>
        <div className='container-fluid'>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className='heading-elements'>
                    <h2 className='heading'>Case studies & stories</h2>
                    <p>We work together across the globe to make a world of difference.</p>
                </div>
                <Swiper
                      modules={[Navigation]}
                      centeredSlides
                      slideToClickedSlide
                      navigation
                      className="swiper-container"
                      loop= {true}
                      coverflowEffect={{
                        rotate: 35,
                        modifier: 1,
                        stretch: 10,
                        depth:100,
                        slideShadows: true
                      }}
                      effect="coverflow"
                      slidesPerView={window.innerWidth < 768 ? 1 : 4}
                    spaceBetween={10}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    >
                    {
                        array.map((item: any) => {
                            return (
                                <SwiperSlide>
                                <div className='outerCard'>
                                    <div className='card'>
                                        <div className='img'>
                                            <img src={item.image} />
                                        </div>
                                        <div className='content'>
                                            {item.heading1 && <h5>{item.heading1}</h5>}
                                            {item.heading2 && <h3>{item.heading2}</h3>}
                                            {item.text && <p>{item.text}</p>}
                                        </div>
                                    </div>
                                </div>
                                </SwiperSlide>
                            );
                        })
                    }
                    </Swiper>
                <div className='btn-section'>
                    <div className='hexa-default-btn'>
                        <p>see all stories <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                    </div>
                </div>
            </Grid>
        </div>
    </section>
      );
}

export default Index;
import React from 'react';
import "./Cards.scss";
type Props = {
    descriptionText?:String,
    img?:string,
    icon?:React.ReactNode,
    id?:string
}
const CardChip = ({descriptionText,img,icon,id}:Props) => {
    return (
        <div className='card-chip-container'>
            <div className='card-chip-elementory-column' id={id}>
                {img && (<img src={img} alt="" />)}
                {icon && (<div className='icon'>{icon}</div>)}
                {descriptionText &&(<p>{descriptionText}</p>)}
            </div>
        </div>
    );
}

export default CardChip;
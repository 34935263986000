import React from 'react';
import Grid from '@mui/material/Grid';
import { Button, Checkbox, Form, Input } from 'antd';
import "./ScheduleCall.scss";
const ScheduleCall = () => {
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div className="form-element-container">
            <div className="form-element">
                <div className="form-header">
                <div className="heading-wrapper">
                    <h4>Tell us a few things</h4>
                </div>
                <div className='note-info'>
                    <p>We’ll help you work through the contact details</p>
                    <div className='border-wrapper'>
                        <span className='border'></span>
                    </div>
                </div>
                </div>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='form-element-items'
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your full name!' }]}
                    >
                        <Input placeholder='Full Name*' />
                    </Form.Item>

                    <Form.Item
                        name="Email"
                        rules={[{ required: true, message: 'Please input your Email!' }]}
                    >
                        <Input placeholder='Business Email*' />
                    </Form.Item>
                    <Form.Item
                        name="Company Name"
                        rules={[{ required: false }]}
                    >
                        <Input placeholder='Company Name' />
                    </Form.Item>
                    <Form.Item
                        name="requirements"
                        rules={[{ required: true, message: 'Please input your requirements!' }]}
                    >
                        <Input placeholder='Tell us you requirements*' />
                    </Form.Item>

                    <Form.Item >
                        <Button className='schedule-a-call' type="primary" htmlType="submit" style={{width:'100%'}}>
                            SCHEDULE A CALL
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default ScheduleCall;
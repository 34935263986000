import React from 'react';

const ContainerWrapper = (props: any) => {
    return (
        <section className={props.className ? props.className : ''} style={props.style ? props.style : null}>
            <div className='container'>
                {props.children}
            </div>
        </section>
    );
}

export default ContainerWrapper;
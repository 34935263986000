import React,{useEffect} from 'react'
import ContainerWrapper from '../../../utils/custom-tags/ContainerWrapper'
import '../copyright.scss';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router-dom';
import Flags from '../../../../assets/Footer Images/flags.png';
const CookiePolicy = () => {

    useEffect(() => {
        return () => {
            restoreGoogleTranslator();
        };
    }, []);

    const restoreGoogleTranslator = ()=>{
        var iframe:any = document.getElementsByClassName('goog-te-banner-frame')[0];
            if(!iframe) return;
          
            var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
            var restore_el = innerDoc.getElementsByTagName("button");
          
            for(var i = 0; i < restore_el.length; i++){
              if(restore_el[i].id.indexOf("restore") >= 0) {
                restore_el[i].click();
                var close_el = innerDoc.getElementsByClassName("goog-close-link");
                close_el[0].click();
                return;
              }
            }
    }

    const navigate = useNavigate();
    function homePage() {
        navigate("/home")
    }
    return (
        <div className='main-container'>
            <div className="main-heading">
                <div className="heading">
                    <h1>Cookie Policy</h1>
                </div>
                <div className="google-translate">
                    <div id="google_translate_element"></div>
                </div>
            </div>
            <ContainerWrapper className="Section">
                <div className="section">
                    <div className="text-section">
                        <p>This Cookie Policy applies to <a onClick={homePage}> www.hexaviewtech.com</a> operated by Hexaview Technologies, Inc of 7, Quaker Dr. East Brunswick, NJ, 08816, USA (“we”, “our” or “us”) acting as data controller.</p>
                        <p>This Cookie Policy describes and informs you about our use of cookies and other tracking technologies such as flash cookies, server logs, web beacons or pixel gifs. Please note that third party websites, which may be linked to the Site, are not covered by this Cookie Policy.</p>
                        <p>If you do not agree to our use of cookies and similar tracking technologies in this way, you should set your cookie preferences or browser settings accordingly. You will always be able to withdraw your consent and change your choice by amending your cookie preferences or browser settings in the future.</p>
                        <p>f you disable cookies that we use, some parts of the Site may not work properly and some functionalities of the Site may be unavailable, depending on the type of cookie you have disabled.</p>
                    </div>
                </div>
                <div className="section">
                    <div className="heading">
                        <h4>What are cookies?</h4>
                    </div>
                    <div className="text-section">
                        <p>Cookies are small text files sent to your device by the Site. Cookies are uploaded onto your device, thus allowing the Site to recognize you and store certain information concerning you, in order to permit or improve the service offered. A cookie will usually contain the name of the website from which the cookie has come from, the “lifetime” of the cookie (i.e. how long the cookie will remain on your device), and a value, which is usually a randomly generated unique number.</p>
                        <p>As regards the lifetime of cookies, two types of cookies may be used, “session cookies” and “persistent cookies”. Session cookies are automatically deleted at the end of your browsing session. Persistent cookies remain longer on your device, for the duration of each specific cookie, and will remain valid until its set expiry date (unless deleted by the user before the expiry date).</p>
                        <p>Cookies can be used by web servers to identify and track users as they navigate different pages on a website and identify users returning to a website. Cookies do not contain any information that personally identifies you, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</p>
                        <p>We also use other types of tracking technologies, such as flash cookies, server logs, web beacons or pixel gifs in connection with our Website and services. These technologies are similar to cookies in that they are stored on your device and can be used to maintain information about your activities and preferences.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>What types of cookies does the Site use and what are they for?</h4>
                    </div>

                    <div className="text-section">
                        <p>We may use different types of cookies. We may use what we call “required” cookies to enable core site functionalities. These cookies do not collect personal information for marketing purposes  and can not be disabled.</p>
                        <p>Functionalcookies provide more advanced functions, such as remembering your preferences such as language and country, analyzing Site usage to measure and improve performance. Also these cookies do not collect information that can identify users.</p>
                        <p>Advertising cookies may be finally used to keep record of certain behaviours or preferences expressed by you, so as to present content that is more relevant to your interests, in compliance with the applicable data protection and privacy laws and upon collection of your express consent if required by law.</p>
                        <p>hese cookies may also enable you to share some Site content through social networks such as(<a href="https://www.facebook.com/policy.php"> Facebook</a>, <a href="https://twitter.com/en/privacy"> Twitter </a> and <a href="https://help.instagram.com/519522125107875"> Instagram </a>).</p>

                        <p>In any case, our cookies do not run programs on users’ device nor upload viruses on it, and do not allow any kind of control over the device.</p>

                        <p>Cookies commonly used on this Site are listed in the table below. From time to time, we may also use additional cookies and tracking technologies not listed in this table. You may obtain an updated list of all cookies and tracking technologies used on this Site at the time of your visit upon request by contacting us.</p>
                        <p> Cookie (<a href="https://www.google.com/policies/privacy/archive/20090127/">Google Doubleclick</a>): IDE;  </p>

                        <p>Cookie (<a href="https://www.youtube.com/intl/ALL_uk/howyoutubeworks/our-commitments/protecting-user-data/?utm_campaign=1008960&utm_source=paidsearch&yt_product=ytgen&yt_goal=eng&utm_medium=googlesearch&utm_content=txt&yt_campaign_id=hyw&yt_creative_id=&utm_keyword=youtube">Youtube</a>): YSC;</p>

                        <p>When you visit the Site, you may receive cookies from third party websites or domains. We do not control the placing of these cookies and you should check the relevant third party’s website for more information about these cookies. The relevant third party is responsible for providing you with information regarding the cookies they place and obtaining your consent before placing cookies on your device.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>How can you control cookies?</h4>
                        <h4>Your cookie preferences</h4>
                    </div>
                    <div className="text-section">
                        <p>By clicking the “cookie preferences” button on the Site’s Cookie Banner, you may choose whether the Site will use “Functional” cookies and/or “Advertising” cookies, as described above.</p>
                        <p>The “cookie preferences” function available on the Site’s Cookie Banner will inform you of which functionalities are available to you or not depending on the types of cookies you choose to authorize the Site to use.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Browser settings</h4>
                    </div>
                    <div className="text-section">
                        <p>If you wish to withdraw your consent to our use of cookies on this Site, or if you wish to delete or control the placing of cookies on your computer, you can also change your browser settings to block cookies or to alert you when cookies are being sent to your device. There are a number of ways to manage cookies. Please refer to your browser instructions or help screen to learn more about how to adjust or modify your browser settings at: <a href="https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer">Internet Explorer</a>, <a href="https://support.google.com/chrome/bin/answer.py?hl=en-GB&hlrm=nl&answer=95647">Chrome</a>, <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences?s=cookies&r=5&as=s">Firefox</a>, <a href="https://support.apple.com/kb/PH5042">Safari</a></p>

                        <p>If you disable the cookies that the Site uses, this may impact your experience while on the Site.</p>
                        <p>
                            You can also delete cookies already stored on your computer. Again, doing this may have a negative impact on the usability of many websites.</p>
                        <p>
                            If you disable the cookies that the Site uses, this may impact your experience while on the Site.</p>
                        <p>
                            You can also delete cookies already stored on your computer. Again, doing this may have a negative impact on the usability of many websites.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>How can we make changes to our Cookie Policy?</h4>
                    </div>

                    <div className="text-section">
                        <p>Except to the extent limited by applicable law, we reserve the right, in its sole discretion, to update and amend all or parts of this Cookie Policy, at any time. The version published on the Site is the version actually in force.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="text-section">
                        <ul className="lists">
                            <li>Last updated on: June 25, 2021</li>
                        </ul>
                    </div>
                </div>
            </ContainerWrapper>
        </div>
    )
}

export default CookiePolicy
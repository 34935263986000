import React from 'react';
import Grid from '@mui/material/Grid';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import img from "../../../../assets/bgImg.jpg";
import PageTopSection from '../../../common-components/pageTop-section/PageTopSection';
import "./Cloud.scss";
import Counter from '../../../common-components/counter-widget/Counter';
import Cards from '../../../common-components/card/Card';
import Flower from '../../../../assets/FinTech-wealth-management/flowerHands.svg';
import Chat from '../../../../assets/FinTech-wealth-management/capital-market/chat.svg';
import Monitor from '../../../../assets/FinTech-wealth-management/capital-market/monitor.svg';
import Settings from '../../../../assets/FinTech-wealth-management/capital-market/settings.svg';
import FlowerHands from '../../../../assets/FinTech-wealth-management/flowerHands.svg';
import Desktop from '../../../../assets/custom-software-services/Desktop.svg';
import Migration from '../../../../assets/svgexport-12.svg';
import CaseStudy from '../../../common-components/caseStudy/Index'
import caseStudy1 from '../../../../assets/caseStudy/1.jpg'
import caseStudy2 from '../../../../assets/caseStudy/2.jpg'
import caseStudy3 from '../../../../assets/caseStudy/3.jpg'
import caseStudy4 from '../../../../assets/caseStudy/4.jpg'
import caseStudy5 from '../../../../assets/caseStudy/5.jpg'

const Cloud = () => {
    const caseStudies = [
        {
          image: caseStudy1,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy2,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy3,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy4,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy5,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
      ]
    return (
        <Grid container className='cloud-elements-main-container paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/cloud-banner.jpg"}
                colorH1Heading="Architecture Experts"
                h1Heading="Your Cloud-Native"
                textSectionA={"Enabling your organization to do more with the most agile, secure, and flexible cloud IT solutions"}
                textSectionB={"We provide the most agile, secure, and flexible cloud computing on-demand solutions."}
                isScheduleCall={true}
            />
            {/*...service text section...*/}
            <section className='section-B Section'>
                <div className='container-fluid'>
                    <Grid container className='element-inner-section'>
                        <Grid className="section-B-left" item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className='image-section'>
                                <img src='https://hexaviewtech.com/wp-content/uploads/2022/04/alliences.jpg' style={{ width: '100%' }} />
                            </div>
                        </Grid>
                        <Grid className="section-B-right" item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className='element-widget-wrap'>
                                <div className="text-section">
                                    <div className="heading">
                                        <h2>Cloud Computing Consulting Services </h2>
                                    </div>
                                    <div>
                                        <p>Cloud Technology adoption is one of the most significant steps in your digital transformation journey advancing agility, scale, and favorable economics. However, the shift from legacy IT environments to private clouds or public clouds brings certain challenges like understanding how to plan, implement, and support a cloud strategy and these can be daunting.</p>

                                    </div>
                                </div>
                                <div className="text-section">
                                    <div>
                                        <p>Hexaview’s cloud computing consulting services can help you to recognize the different capabilities of the cloud business solutions available and implement the right strategies to match your technical and business requirements. Our cloud consulting services are designed to revamp your experience and help you in the crucial phase of your cloud journey. We enable you to adopt modern cloud platforms with zero business impact.</p>
                                    </div>
                                </div>
                                <div className="text-section">
                                    <div>
                                        <p>And if you have already moved to the cloud, and still facing problems, we will look for the gaps and optimize your existing processes, so you get the most of your cloud investments and become a valuable leader for innovation and growth. Whether you’re operating at Microsoft Azure or Amazon AWS platforms or Google Cloud Platform, we align your cloud strategy with your business goals

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="section-C">
                <div className='container'>
                    <Grid container>
                        <Counter prefix='' suffix='+' number="100" title="Successful Deployments" />
                        <Counter prefix='' suffix='%' number="21" title="Reduction in Cloud Infrastructure Cost" />
                        <Counter prefix='' suffix='+' number="10" id='counter-card-3' title="Years of Cloud Consulting Experience" />
                    </Grid>
                </div>
            </section>
            <section className="section-D">
                <div className='container-fluid'>
                    <div className="section-D-inner-text-row">
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className='text-1 element-widget-wrap'>
                                <h3 className="heading">Simple, Fast & Reliable Cloud Services for You</h3>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="text-2 element-widget-wrap">
                                <p>We offer cloud-based services to revamp your experience and help you to adopt modern cloud platforms with zero business impact. Our team analyses your data to determine which solutions in the cloud would meet your requirements. We deliver our niche through various technologies comprising AWS, Microsoft Azure & Google Cloud Platform.</p>
                            </div>
                        </Grid>

                    </div>
                    <div className='cards-row'>
                        <Cards id='card-c-1' headerIcon={<div className='icon'><img src={Chat}/></div>} contentHeading="DEVOPS" contentText="Hexaview's DevOps solutions bridge the gaps between software development, quality assurance, and IT operations. We empower your products and services with a quick turnaround and a positive surge in your operational capabilities."
                            isActionBtn={false} mSize={12}
                        />
                        <Cards id='card-c-1' headerIcon={<div className='icon'><img src={Migration} /></div>} contentHeading="CLOUD GOVERNANCE & SECURITY" contentText="Our expert team provides a comprehensive set of technologies, policies, and measures to secure your data and applications in the cloud ecosystem."
                            isActionBtn={false} mSize={12}
                        />
                        <Cards id='card-c-1' headerIcon={<div className='icon'><img src={Settings} /></div>} contentHeading="PUBLIC, PRIVATE & HYBRID CLOUD" contentText="With our multi-cloud strategy, you can successfully deploy cloud infrastructure at all major hosting platforms. Our team helps you explore the most optimum hosting platform to match your business requirements."
                            isActionBtn={false} mSize={12}
                        />
                        <Cards headerIcon={<div className='icon'><img src={Desktop} /></div>} contentHeading="APPLICATION DEPLOYMENT" contentText="Modernize and simplify your IT operations with our cloud IT solutions. Our in-house team helps you establish applications over scalable cloud platforms to reduce in-house IT and capital expenses. We focus on the scalability of your application through horizontal-vertical and manual-automatic scaling."
                            isActionBtn={false} mSize={12}
                        />
                        <Cards headerIcon={<div className='icon'><img src={Settings} /></div>} contentHeading="CLOUD OPTIMIZATION" contentText="We assist you in reducing your overall cloud cost and enhancing your application performance. Our optimization services strengthen your cloud's security and let you operate with confidence."
                            isActionBtn={false} mSize={12}
                        />
                        <Cards headerIcon={<div className='icon'><img src={FlowerHands} /></div>} contentHeading="CLOUD MIGRATION" contentText="With our unique multi-stage migration-as-a-service, we transform & simplify your modern cloud platforms' adoption and deliver a staggering solution to secure your resources."
                            isActionBtn={false} mSize={12}
                        />
                    </div>
                </div>
            </section>
            <CaseStudy array={caseStudies} />

        </Grid>
        
    )
}

export default Cloud;
import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../web/theme/GlobalStyle';
import { darkTheme, lightTheme } from "../web/theme/Theme";
import ErrorBoundary from './ErrorBoundary';
function Core(props: any) {
    return (
        <ThemeProvider theme={lightTheme}>
            <GlobalStyle />
            <BrowserRouter>
                <ErrorBoundary>
                    {props.children}
                </ErrorBoundary>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default Core;
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import WhoWeAre from '../../../../assets/home/whoWeAre.png'
import AwsPartner from '../../../../assets/home/aws-Partner.png'
import Salesforce from '../../../../assets/home/salesforce.png'
import GoldBadge from '../../../../assets/home/GoldBadge.png'
import Nvidia from '../../../../assets/home/nvidia.png'
import "./style.scss";


const Index = () => {

  return (
    <section className='who-we-are-section Section'>
        <div className='container'>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                    <div className='contentDiv'>
                        <div className='heading-elements'>
                            <h4>Who we are</h4>                
                            <h2>A Trusted FinTech Software<br /> Development Firm</h2>
                            <p>Hexaview has been providing feature-rich financial software solutions and products to clients, including leading banks and financial services institutions. Our solutions are developed by implementing a comprehensive product development strategy based on the latest transformation in the financial sector (AI, ML, Big Data, Blockchain).</p>
                        </div>
                        <h4 className='officialPartner'>Official Partners</h4>
                        <div className='partnerImage'>
                            <div className='img'>
                                <img src={AwsPartner} alt=""/>
                            </div>
                            <div className='img'>
                                <img src={Salesforce} alt=""/>
                            </div>
                            <div className='img'>
                                <img src={GoldBadge} alt=""/>
                            </div>
                            <div className='img'>
                                <img src={Nvidia} alt=""/>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <div className='WhoWeAreImg'>
                        <img src={WhoWeAre} alt=""/>
                        <div className='whoWeAreButton'>
                            <div className='hexa-default-btn'>
                                <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    </section>
  );
}

export default Index;
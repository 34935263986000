import React,{useEffect} from 'react'
import ContainerWrapper from '../../../utils/custom-tags/ContainerWrapper'
import '../copyright.scss';
import CircleIcon from '@mui/icons-material/Circle';
import Flags from '../../../../assets/Footer Images/flags.png';
const PrivacyPolicy = () => {

    useEffect(() => {
        return () => {
            restoreGoogleTranslator();
        };
    }, []);

    const restoreGoogleTranslator = ()=>{
        var iframe:any = document.getElementsByClassName('goog-te-banner-frame')[0];
            if(!iframe) return;
          
            var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
            var restore_el = innerDoc.getElementsByTagName("button");
          
            for(var i = 0; i < restore_el.length; i++){
              if(restore_el[i].id.indexOf("restore") >= 0) {
                restore_el[i].click();
                var close_el = innerDoc.getElementsByClassName("goog-close-link");
                close_el[0].click();
                return;
              }
            }
    }

    const lstA = ["Inventory data (e.g., names, addresses).",
        "Contact data (e.g., email, telephone numbers).",
        "Content data (e.g., text input, messages, content).",
        "Contract data (e.g., subject matter of the contract, term, customer category).",
        "Payment data (e.g., bank details, payment history).",
        "Usage data (e.g., websites visited, interest in content, access times).",
        "Meta/communication data (e.g., device information, IP addresses)."]

    const lstB = ["Data Protection Officer,",
        "Hexaview Technologies, Inc.",
        "#7, Quaker Dr., East Brunswick,",
        "New Jersey, 08816, USA",
        "hello@hexaviewtech.com",
        "+1 (646) 403-4525"]

    const lstC = ["Customers, interested parties, visitors, and users of the online offer, business partners.", "Visitors and users of the online offer."]

    const lstD = ["Provision of the online offer, its content, and functions.",
        "Provision of contractual services, service, and customer care.",
        "Answering contact requests and communication with users.",
        "Marketing, advertising, and market research.",
        "Security measures."]

    const lstE = [
        "request, up to two times each year, access to categories and specific pieces of personal information about you that we collect, use, disclose, and sell.",
        'request that we delete personal information that we collect from you, subject to applicable legal exceptions.',
        "“opt out” of the “sale” of your “personal information” to “third parties”"
    ]
    return (
        <div className="main-container">
            <div className="main-heading">
                <div className="heading">
                    <h1>Privacy Policies</h1>
                </div>
                <div className="google-translate">
                    {/* <img src={Flags} style={{ width: "120px" }} alt="" /> */}
                    <div id="google_translate_element"></div>
                </div>
            </div>

            <ContainerWrapper className="Section">
                <div className="section">
                    <div className="heading">
                        <h4>Website Privacy Notice – Last updated on June 22, 2021</h4>
                    </div>
                    <div className="text-section">
                        <p> This Privacy Policy informs you about the nature, scope, and purpose of the processing of personal data (hereinafter referred to as “data”) within our online offer and the websites, functions, and content associated with it, as well as external online presences, such as our social media profiles. (hereinafter collectively referred to as “online offer”). With regard to the terms used, such as “processing” or “controller,” we refer to the definitions in Article 4 of the General Data Protection Regulation (GDPR).</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Person Responsible/Controller</h4>
                    </div>
                    <div className="text-section">
                        <ul>
                            {lstB.map((item) => {
                                return (
                                    <li>{item}</li>
                                )
                            })}
                        </ul>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Types of data processed</h4>
                    </div>
                    <div className="text-section">
                        <ul className='lists'>
                            {lstA.map((item) => {
                                return (
                                    <li> <CircleIcon style={{ width: "8px", height: "8px" }} /> {item}</li>
                                )
                            })}
                        </ul>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Processing of special categories of data (Art. 9 para. 1 GDPR)</h4>
                    </div>
                    <div className="text-section">
                        <p>No special categories of data are processed.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Categories of data subjects</h4>
                    </div>
                    <div className="text-section">
                        <p>As defined in Art. 12, 13, 14, and 19 GDPR, the categories are mentioned below: </p>
                        <ul className="lists">{
                            lstC.map((item) => {
                                return (
                                    <li> <CircleIcon style={{ width: "8px", height: "8px" }} /> {item}</li>
                                )
                            })}
                        </ul>
                        <p>In the following, we also refer to the data subjects collectively as “users.”</p>
                    </div>
                </div>
                <div className="section">
                    <div className="heading">
                        <h4>Purpose of processing</h4>
                    </div>
                    <div className="text-section">
                        <ul className="lists">
                            {
                                lstD.map((item) => {
                                    return (
                                        <li> <CircleIcon style={{ width: "8px", height: "8px" }} /> {item}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="heading">
                        <h4>Terminology</h4>
                    </div>
                    <div className="text-section">
                        <p> <span className='colored-spans'>“Personal data”</span> means any information relating to an identified or identifiable natural person (hereinafter “data subject”); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier (e.g., cookie) or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
                        <p> <span className='colored-spans'>“Processing” me</span>ans any operation or set of operations that are performed upon personal data, whether or not by automatic means. The term is broad and includes virtually any handling of data.</p>
                        <p> <span className='colored-spans'>“Controller”</span>shall mean the natural or legal person, public authority, agency, or other body which alone or jointly with others determines the purposes and means of the processing of personal data.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Relevant legal basis</h4>
                    </div>
                    <div className="text-section">
                        <p>
                            In accordance with Article 13 of the GDPR, we inform you of the legal basis for our data processing activities. If the legal basis is not mentioned in this Privacy Policy, the following applies: The legal basis for obtaining consent is Art. 6(1)(a) and Art. 7 GDPR, the legal basis for processing to fulfill our services and carry out contractual measures and respond to inquiries is Art. 6(1)(b) GDPR, the legal basis for processing to fulfill our legal obligations is Art. 6(1)(c) GDPR, and the legal basis for processing to protect our legitimate interests is Art. 6(1)(f) GDPR. In the event that vital interests of the data subject or another natural person require processing of personal data, Art. 6 (1) lit. d GDPR serves as the legal basis.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Changes And Updates To This Privacy Policy</h4>
                    </div>
                    <div className="text-section">
                        <p>
                            We ask you to regularly inform yourself about the content of our data protection policy. We adapt this Privacy Policy as soon as the changes in the data processing carried out by us make this necessary. We will inform you as soon as the changes require an act of cooperation on your part (e.g. consent) or other individual notification. For the European Region we update the privacy policy with privacy notices and consent as per Article 12, 14, 19 of GDPR.</p>
                    </div>
                </div>
                <div className="section">
                    <div className="heading">
                        <h4>Security Measures</h4>
                    </div>
                    <div className="text-section">
                        <p>
                            We take appropriate technical and organizational measures in accordance with Article 32 of the GDPR, taking into account the state of the art, the implementation costs and the nature, scope, circumstances and purposes of the processing, as well as the varying likelihood and severity of the risk to the rights and freedoms of natural persons, in order to ensure a level of protection appropriate to the risk; the measures include, in particular, ensuring the confidentiality, integrity and availability of data by controlling physical access to the data, as well as access concerning them, input, disclosure, ensuring availability and their separation. We regularly perform Data Protection Impact Assessment (DPIA) to identify and manage the privacy risks generating from  various processes, strategies, projects, policies, and business relationships. </p>

                        <p>
                            Furthermore, we have established procedures to ensure the exercise of data subjects’ rights, deletion of data, and response to data compromise. Furthermore, we already take the protection of personal data into account during the development and selection of hardware, software, and processes, in accordance with the principle of data protection through technology design and through data protection-friendly default settings (Art. 25 GDPR). </p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Disclosure And Transmission Of Data</h4>
                    </div>
                    <div className="text-section">
                        <p>
                            If, in the course of our processing, we disclose data to other persons and companies (processors or third parties), transmit it to them or otherwise grant them access to the data, this will only be done on the basis of a legal permission (e.g. if a transmission of the data to third parties, such as to payment service providers, pursuant to Art. 6 para. 1 lit. b GDPR is necessary for the performance of a contract), you have consented, a legal obligation provides for this or on the basis of our legitimate interests (e.g. when using agents, hosting providers, tax, business and legal advisors, customer care, accounting, billing and similar services that allow us to efficiently and effectively fulfill our contractual obligations, administrative tasks and duties).</p>
                        <p>
                            If we entrust third parties with the processing of data on the basis of a so-called “processing agreement”, this is done on the basis of Art. 28 GDPR.</p>
                    </div>
                </div>
                <div className="section">
                    <div className="heading">
                        <h4>Transfers To Third Countries</h4>
                    </div>
                    <div className="text-section">
                        <p>
                            If we process data in a third country (outside the USA, the EU or India) or if this happens in the context of using third-party services or disclosing, or transferring data to third parties, this will only happen if it is done to fulfill our (pre)contractual obligations, on the basis of your consent, due to a legal obligation or on the basis of our legitimate interests.</p>
                        <p>
                            Subject to legal or contractual permissions, we process or allow the processing of data in a third country only if the special requirements of Art. 44 et seq. GDPR are met. I.e. the processing is carried out, for example, on the basis of special guarantees, such as the officially recognized determination of a level of data protection or compliance with officially recognized special contractual obligations (so-called “standard contractual clauses”).</p>
                    </div>
                </div>
                <div className="section">
                    <div className="heading">
                        <h4>Rights of the Data Subjects</h4>
                    </div>
                    <div className="text-section">
                        <p>You have the right to request confirmation as to whether data in question is being processed and to information about this data, as well as further information and a copy of the data in accordance with Art. 15 GDPR. </p>

                        <p>You have according to. Article 16 GDPR the right to request the completion of the data concerning you or the correction of incorrect data concerning you. </p>

                        <p>In accordance with Art. 17 GDPR, you have the right to demand that data concerning you be deleted without delay or, alternatively, in accordance with Art. 18 GDPR, to demand restriction of the processing of the data. </p>

                        <p>You have the right to demand that the data concerning you that you have provided to us be received in accordance with Art. 20 GDPR and to demand that it be transferred to other data controllers. </p>

                        <p>You also have the right to lodge a complaint with the competent supervisory authority in accordance with Art. 77 GDPR. </p>

                        <p>You have the right to revoke given consents according to Art. 7 para. 3 GDPR with effect for the future. </p>

                        <p>You may object to the future processing of data concerning you in accordance with Art. 21 GDPR at any time. The objection can be made in particular against the processing for purposes of direct advertising.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Cookies And Right To Object In The Case Of Direct Advertising</h4>
                    </div>
                    <div className="text-section">
                        <p>
                            “Cookies” are small files that are stored on users’ computers. Various data can be stored within the cookies. The primary purpose of a cookie is to store information about a user (or the device on which the cookie is stored) during or after his or her visit to an online offer. Temporary cookies, or “session cookies” or “transient cookies”, are cookies that are deleted after a user leaves an online offer and closes his browser. Such a cookie may store, for example, the contents of a shopping cart in an online store or a login status. Cookies that remain stored even after the browser is closed are referred to as “permanent” or “persistent”. For example, the login status can be stored if users visit them after several days. Likewise, the interests of users can be stored in such a cookie, which is used for range measurement or marketing purposes. Third-party cookies” are cookies from providers other than the responsible party that operates the online offer (otherwise, if they are only its cookies, they are referred to as “first-party cookies”).</p>

                        <p>We use temporary and permanent cookies and explain this in our <a href='/cookie-policy'> cookie policy. </a></p>

                        <p>If users do not want cookies to be stored on their computer, they are asked to deactivate the corresponding option in the system settings of their browser. Stored cookies can be deleted in the system settings of the browser. The exclusion of cookies can lead to functional restrictions of this online offer.</p>

                        <p>A general objection to the use of cookies used for online marketing purposes can be declared for a large number of the services, especially in the case of tracking, via the U.S. site <a href="http://www.aboutads.info/choices/"> http://www.aboutads.info/choices/ </a> or the EU site <a href="http://www.youronlinechoices.com/"> http://www.youronlinechoices.com/</a>.</p>

                        <p>Furthermore, the storage of cookies can be achieved by disabling them in the browser settings. Please note that in this case not all functions of this online offer can be used.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>California Specific Rights</h4>
                    </div>
                    <div className="text-section">
                        <p>If you are a California resident, you have the following rights:</p>
                        <p>You have the right to:</p>
                        <ul className='lists'>
                            {lstE.map((item) => {
                                return (
                                    <li> <CircleIcon style={{ width: "8px", height: "8px" }} /> {item}</li>
                                )
                            })}
                        </ul>
                        <p>In addition under California’s “Shine the Light” law, California residents who provide personal information (as defined in the statute) to obtain  services are entitled to request and obtain from us, once per calendar year, information about the personal information we shared, if any, with other businesses for marketing uses. If applicable, this information would include the categories of personal information and the names and addresses of those businesses with which we shared such personal information for the immediate prior calendar year (e.g., requests made in the current year will receive information about the prior year). To obtain the information about data we hold about you or to effect the opt out, please contact us.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Deletion Of Data</h4>
                    </div>
                    <div className="text-section">
                        <p>The data processed by us will be deleted or restricted in its processing in accordance with Articles 17 and 18 of the GDPR. Unless expressly stated in this Privacy Policy, the data stored by us will be deleted as soon as it is no longer required for its intended purpose and the deletion does not conflict with any statutory retention obligations. If the data is not deleted because it is required for other and legally permissible purposes, its processing will be restricted. I.e. the data is blocked and not processed for other purposes. This applies, for example, to data that must be retained for reasons of commercial or tax law.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Business Analyses And Market Research</h4>
                    </div>
                    <div className="text-section">
                        <p>In order to operate our business economically and to be able to recognize market trends, customer and user wishes, we analyze the data we have on business transactions, contracts, inquiries, etc.. In doing so, we process inventory data, communication data, contract data, payment data, usage data, metadata on the basis of Art. 6 para. 1 lit. f. GDPR, whereby the data subjects include customers, interested parties, business partners, visitors and users of the online offer. The analyses are carried out for the purpose of business management evaluations, marketing and market research. In doing so, we may take into account the profiles of registered users with details of, for example, their purchase transactions. The analyses serve us for the increase of the user friendliness, the optimization of our offer and the business management. The analyses serve us alone and are not disclosed externally, unless they are anonymous analyses with aggregated values.</p>
                        <p>
                            If these analyses or profiles are personal, they will be deleted or anonymized upon termination of the user, otherwise after two years from the conclusion of the contract. Otherwise, the overall business analyses and general tendency analyses shall be prepared anonymously, if possible.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Credit Information</h4>
                    </div>
                    <div className="text-section">
                        <p>If we provide advance services, we reserve the right to obtain identity and credit information for the purpose of assessing the credit risk on the basis of mathematical-statistical methods from specialized service providers (credit agencies) in order to protect our legitimate interests.</p>
                        <p>
                            We process the information received from the credit agencies about the statistical probability of a payment default within the framework of an appropriate discretionary decision about the establishment, implementation and termination of the contractual relationship. We reserve the right to refuse payment on account or any other advance payment in the event of a negative result of the credit check.
                        </p><p>
                            The decision as to whether we provide advance services shall be made solely on the basis of an automated decision in individual cases, which our software shall make on the basis of the information provided by the credit agency, in accordance with Article 22 of the GDPR.
                        </p>
                        <p>
                            If we obtain explicit consent from you, the legal basis for the transmission of the customer’s data to the credit agencies is consent pursuant to Art. 6 (1) lit. a, 7 GDPR. If no consent is obtained, our legitimate interests in the default security of their payment claim is the legal basis pursuant to Art. 6 para. 1 lit. f. GDPR.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Contacting Us</h4>
                    </div>
                    <div className="text-section">
                        <p>When contacting us (via contact form or email), the user’s details are processed for the purpose of handling the contact request and its processing pursuant to Art. 6 (1) lit. b) GDPR. The user’s details may be stored in our customer relationship management system (“CRM system”) or comparable inquiry organization.</p>
                        <p>We delete the inquiries if they are no longer necessary. We review the necessity every two years; we store inquiries from customers who have a customer account permanently and refer to the information on the customer account for deletion. Furthermore, the legal archiving obligations apply.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Collection Of Access Data And Log Files</h4>
                    </div>
                    <div className="text-section">
                        <p>We collect on the basis of our legitimate interests within the meaning of Art. 6 para. 1 lit. f. GDPR, we collect data about each access to the server on which this service is located (so-called server log files). The access data includes the name of the website accessed, file, date and time of access, amount of data transferred, notification of successful access, browser type and version, the user’s operating system, referrer URL (the previously visited page), IP address and the requesting provider.</p>
                        <p>Log file information is stored for security reasons (e.g. for the clarification of abuse or fraud) for a maximum of seven days and then deleted. Data whose further storage is required for evidentiary purposes is exempt from deletion until final clarification of the respective incident.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Online Presences In Social Media</h4>
                    </div>
                    <div className="text-section">
                        <p>We maintain online presences on the basis of our legitimate interests within the meaning of Art. 6 para. 1 lit. f. GDPR, we maintain online presences within social networks and platforms in order to be able to communicate with the customers, interested parties and users active there and to inform them about our services there. When calling up the respective networks and platforms, the terms and conditions and data processing policies of their respective operators apply.</p>
                        <p>Unless otherwise stated in our data protection policy, we process the data of users if they communicate with us within the social networks and platforms, e.g. write posts on our online presences or send us messages.</p>
                    </div>
                </div>
                <div className="section">
                    <div className="heading">
                        <h4>Google Services</h4>
                    </div>
                    <div className="text-section">
                        <p>We use on the basis of our legitimate interests (i.e. interest in the analysis, optimization and economic operation of our online offer within the meaning of Art. 6 para. 1 lit. f. GDPR) services of Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, (“Google”). We may integrate third-party advertisements based on the Google marketing service “DoubleClick”. DoubleClick uses cookies that enable Google and its partner websites to serve ads based on users’ visits to this website or other websites on the Internet.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>NewsLetter</h4>
                    </div>
                    <div className="text-section">
                        <p>With the following information we inform you about the contents of our newsletter as well as the registration, dispatch and statistical evaluation procedure and your rights of objection. By subscribing to our newsletter, you agree to receive it and to the procedures described.</p>
                        <p>Content of the newsletter: We send newsletters, emails and other electronic notifications with promotional information (hereinafter “newsletter”) only with the consent of the recipients or a legal permission. Insofar as the contents of the Newsletter are specifically described in the context of a registration, they are decisive for the consent of the users. Otherwise, our newsletters contain information about our products, offers, promotions and our company.</p>
                        <p>Double opt-in and logging: The registration for our newsletter takes place in a so-called double opt-in procedure. This means that after registration you will receive an email in which you are asked to confirm your registration. This confirmation is necessary so that no one can register with other email addresses. The registrations for the newsletter are logged in order to be able to prove the registration process according to the legal requirements. This includes the storage of the registration and confirmation time, as well as the IP address. Changes to your data stored by the shipping service provider are also logged.</p>
                        <p>Dispatch service provider: The newsletter is dispatched using “MailPoet”, a newsletter dispatch platform of WooCommerce, Inc. You can view the privacy policy of the shipping service provider here.</p>
                        <p>The newsletters contain a so-called “web beacon”, i.e. a pixel-sized file that is retrieved from our server when the newsletter is opened, or if we use a shipping service provider, from their server. In the course of this retrieval, technical information, such as information about the browser and your system, as well as your IP address and the time of the retrieval are collected. This information is used for the technical improvement of the services based on the technical data or the target groups and their reading behavior based on their retrieval locations (which can be determined with the help of the IP address) or the access times. Statistical surveys also include determining whether newsletters are opened, when they are opened and which links are clicked. For technical reasons, this information can be assigned to individual newsletter recipients. However, it is neither our intention nor, if used, that of the dispatch service provider to observe individual users. The analyses serve us much more to recognize the reading habits of our users and to adapt our content to them or to send different content according to the interests of our users.</p>
                        <p>Newsletter recipients can cancel the receipt of our newsletter at any time, i.e. revoke their consents. You will find a link to cancel the newsletter at the end of each newsletter. At the same time, their consent to the performance measurement will expire. A separate cancellation of the performance measurement is unfortunately not possible, in this case the entire newsletter subscription must be cancelled. With the unsubscription from newsletter, the personal data will be deleted, unless their retention is legally required or justified, in which case their processing will be limited only to these exceptional purposes. In particular, we may store unsubscribed email addresses for up to three years based on our legitimate interests before deleting them for newsletter sending purposes, in order to be able to prove consent formerly given. The processing of this data is limited to the purpose of a possible defense against claims. An individual deletion request is possible at any time, provided that the former existence of consent is confirmed at the same time.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Integration Of Services And Contents Of Third Parties</h4>
                    </div>
                    <div className="text-section">
                        <p>We use within our online offer on the basis of our legitimate interests (i.e. interest in the analysis, optimization and economic operation of our online offer within the meaning of Art. 6 para. 1 lit. f. GDPR), we use content or services offered by third-party providers in order to integrate their content and services, such as videos or fonts (hereinafter uniformly referred to as “content”).</p>

                        <p>This always requires that the third-party providers of this content are aware of the IP address of the user, since without the IP address they could not send the content to their browser. The IP address is thus required for the display of this content. We endeavor to use only such content whose respective providers use the IP address only for the delivery of the content. Third-party providers may also use so-called pixel tags (invisible graphics, also known as “web beacons”) for statistical or marketing purposes. The “pixel tags” can be used to evaluate information such as visitor traffic on the pages of this website. The pseudonymous information may also be stored in cookies on the user’s device and may contain, among other things, technical information about the browser and operating system, referring websites, time of visit and other information about the use of our online offer, as well as be linked to such information from other sources.</p>
                        <p>The following presentation provides an overview of third-party providers and their content, along with links to their data protection policies, which contain further information on the processing of data and, in part already mentioned here.</p>

                        <p>– Our online presence is provided on a so called Content Delivery Network and supported by our In-house serves in the locations already mentioned above or on cloud service providers. Our providers are <a href="https://aws.amazon.com/privacy/?nc1=f_pr"> Amazon Web Services </a> (AWS) 410 Terry Avenue North, Seattle, WA 98109-5210, <a href="https://privacy.microsoft.com/en-gb/privacystatement"> Microsoft Azure </a> 2 Kingdom St, London W2 6BD, UK, <a href="https://cloud.google.com/security/privacy"> Google Cloud Services </a> 1600 Amphitheatre Parkway in Mountain View, California, United States and <a href="https://endurance.clarip.com/privacycenter/?brand=bluehost&_ga=2.133755406.1215088760.1614506860-526950054.1614353248"> Bluehost </a> 10 Corporate Drive Suite #300. Burlington, MA 01803</p>

                        <p>Within our online offer, functions of the service YouTube are integrated. These functions are offered by Google LLC, D/B/A YouTube, 901 Cherry Ave, San Bruno, CA 94066, USA. If you are logged into your YouTube account, you can link the content of our pages to your YouTube profile by clicking on the YouTube button. This allows YouTube to associate the visit to our pages with your user account. We would like to point out that we, as the provider of the pages, have no knowledge of the content of the transmitted data or its use by YouTube.</p>

                        <ul className="lists">
                            <li> <p> <CircleIcon style={{ width: "8px", height: "8px" }} />  We use social plugins of the social network <a href="https://www.linkedin.com/legal/privacy-policy"> LinkedIn </a>, which is operated by LinkedIn Inc, Sunnyvale (HQ), CA, United States, 1000 W Maude Ave (“LinkedIn”). When you call up a page that contains such a plugin, your browser establishes a direct connection to the LinkedIn servers. The plugin transmits log data to the LinkedIn server in the USA. This log data may contain your IP address, the address of the visited websites that also contain LinkedIn functions, type and settings of the browser, date and time of the request, your usage of LinkedIn as well as cookies.</p></li>

                            <li> <p> <CircleIcon style={{ width: "8px", height: "8px" }} />  Within our online offer, functions of the service, respectively the platform <a href="https://twitter.com/en/privacy"> Twitter</a> can be integrated (hereinafter referred to as “Twitter”). Twitter is a service of Twitter Inc, 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. The functions include the display of our posts within Twitter within our online offer, the link to our profile on Twitter as well as the possibility to interact with the posts and the functions of Twitter, as well as to measure whether users reach our online offer via the advertisements placed by us on Twitter (so-called conversion measurement). </p></li>
                        </ul>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Questions About Our Privacy Practices or This Privacy Policy</h4>
                    </div>
                    <div className="text-section">
                        <p>We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained. If you have any questions about our Privacy Practices or this Policy, please contact us.</p>
                    </div>
                </div>
            </ContainerWrapper >
        </div >
    )
}

export default PrivacyPolicy
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SwiperSlides from '../services/data-science/SwiperSlides';
import { Carousel, Avatar } from "antd";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { appState, appActions, store } from '../../../store';
import TrustBy from '../services/cloud/commonSection/trustBy/Index';
import CaseStudy from '../../common-components/caseStudy/Index'
import PressRelease from './pressRelease/Index'
import OurClients from './our_Clients/Index'
import Industries from './industries/Index'
import WhoWeAre from './whoWeAre/Index'
import FinTechSolution from './finTechSolution/Index'
import OurService from './ouService/Index'
import WealthManagement from '../../../assets/coreService/wealthManagement.png';
import Digital from '../../../assets/coreService/digitalLending.png';
import Market from '../../../assets/coreService/capitalMarket.png';
import DigitalBanking from '../../../assets/coreService/blockchain.png';
import InsurTechImg from '../../../assets/coreService/insureTech.png';
import Wealth from '../../../assets/home/wealth.svg';
import Financing from '../../../assets/home/financing.svg';
import StockMarket from '../../../assets/home/stockMarket.svg';
import Banking from '../../../assets/home/banking.svg';
import InsurTech from '../../../assets/home/insurTech.svg';
import Experience from '../../../assets/home/experience.webp'
import Partner from '../../../assets/home/partner.webp'
import Quality from '../../../assets/home/quality.webp'
import Success from '../../../assets/home/success.webp'
import caseStudy1 from '../../../assets/caseStudy/1.jpg'
import caseStudy2 from '../../../assets/caseStudy/2.jpg'
import caseStudy3 from '../../../assets/caseStudy/3.jpg'
import caseStudy4 from '../../../assets/caseStudy/4.jpg'
import caseStudy5 from '../../../assets/caseStudy/5.jpg'
import Client from '../../../assets/home/client.png'
import "./style.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Index = () => {

  const [value, setValue] = React.useState(0);
  const [count, setCount] = useState<any>(1);
  const client = appState((state: any) => state.demo.client);
  // const fetchClients = appActions((actions: any) => actions.demo.loadClient)
  const fetchClients = appActions((actions: any) => actions.demo.setClient);
  let st = store.getState();

  const update = () => {
    fetchClients(5);
    let st = store.getState();
    // setCount(2);

  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };



  const wealthManagement = <div className='menuTab'>
    <p><img src={Wealth} alt="" /><span>Wealth Management<br /> and Investment</span></p>
  </div>
  const digitalLending = <div className='menuTab'>
    <p><img src={Financing} alt="" /><span>Digital Lending &<br />Alternative Financing</span></p>
  </div>
  const capitalMarket = <div className='menuTab'>
    <p><img src={StockMarket} alt="" /><span>Capital Market and<br /> Stock Market</span></p>
  </div>
  const digitalBanking = <div className='menuTab'>
    <p><img src={Banking} alt="" /><span>Digital Banking</span></p>
  </div>
  const insurTech = <div className='menuTab'>
    <p><img src={InsurTech} alt="" /><span>InsurTech</span></p>
  </div>


  const caseStudies = [
    {
      image: caseStudy1,
      heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
      heading1: "Cloud Service",
      text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
    },
    {
      image: caseStudy2,
      heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
      heading1: "Cloud Service",
      text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
    },
    {
      image: caseStudy3,
      heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
      heading1: "Cloud Service",
      text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
    },
    {
      image: caseStudy4,
      heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
      heading1: "Cloud Service",
      text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
    },
    {
      image: caseStudy5,
      heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
      heading1: "Cloud Service",
      text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
    },
  ]

  const clients = [
    {
      image: Client,
      name: "Larry Baker",
      text: "We find Hexaview’s team to be very transparent & responsive. They are open to solving any problem, however intricate and time-bound. With regular check-ins, they ensure that any feedback is acted upon promptly. Hexaview resources have always helped Adobe ship good quality products.",
      designation: "BaySys Technologies LLC"
    },
    {
      image: Client,
      name: "Larry Baker",
      text: "We find Hexaview’s team to be very transparent & responsive. They are open to solving any problem, however intricate and time-bound. With regular check-ins, they ensure that any feedback is acted upon promptly. Hexaview resources have always helped Adobe ship good quality products.",
      designation: "BaySys Technologies LLC"
    },
    {
      image: Client,
      name: "Larry Baker",
      text: "We find Hexaview’s team to be very transparent & responsive. They are open to solving any problem, however intricate and time-bound. With regular check-ins, they ensure that any feedback is acted upon promptly. Hexaview resources have always helped Adobe ship good quality products.",
      designation: "BaySys Technologies LLC"
    }
  ]


  return (
    <Grid container className='home-page' style={{ overflow: 'auto' }}>

      <FinTechSolution />
      <WhoWeAre />


      <section className='home-section-B Section'>
        <div className='container'>
          <Grid container >
            <Grid className="home-section-left" item xs={12} sm={12} md={5} lg={5} xl={5}>
              <div className="heading">
                <h4>Let the Numbers Speak</h4>
                <h2>Experience and Mindset<br /> in Building Success</h2>
              </div>
            </Grid>
            <Grid className="home-section-right" item xs={12} sm={12} md={7} lg={7} xl={7}>
              <div className="text-section">
                <div>
                  <p>Digital Transformation Firm providing high-end products and solutions to clients who include leading players in the worldwide technology industry. We pride ourselves on agile product development and unparalleled IT services.</p>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container className="home-img-section">
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='img-section'>
                <img src={Experience} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='content-section'>
                <h3>Experience</h3>
                <p><span>12+</span> years in operation</p>
                <p><span>300+</span> finished projects</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='img-section'>
                <img src={Partner} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='content-section'>
                <h3>Partners</h3>
                <p><span>90%</span> Client Retention Rate</p>
                <p><span>95%</span> recommend to a friend</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='content-section blueHeading'>
                <h3>Quality</h3>
                <p><span>100%</span> quality assurance</p>
                <p><span>100%</span> client satisfaction</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='img-section'>
                <img src={Quality} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='content-section blueHeading'>
                <h3>Client success</h3>
                <p><span>$20M</span> in acquisitions</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='img-section'>
                <img src={Success} />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      {/* <TrustBy /> */}


      <section className='home-our-service-section'>
        <div className='container'>
          <Grid container className='paddingX'>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="heading">
                <h4>Our Core Services</h4>
                <h2>Embrace the digital revolution by choosing<br /> Hexaview’s FinTech Software development services</h2>
                <p>We provide a range of software development solutions covering all major functions of the financial sector.</p>
              </div>
              <Grid container>
                <Grid xs={12} md={12} lg={4}>
                  <div className='home-our-service-section-inner-left'>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={wealthManagement} className='customTab' {...a11yProps(0)} />
                        <Tab label={digitalLending} className='customTab' {...a11yProps(1)} />
                        <Tab label={capitalMarket} className='customTab' {...a11yProps(2)} />
                        <Tab label={digitalBanking} className='customTab' {...a11yProps(3)} />
                        <Tab label={insurTech} className='customTab' {...a11yProps(4)} />
                      </Tabs>
                    </Box>
                  </div>
                </Grid>
                <Grid xs={12} md={12} lg={8}>
                  <div className='home-our-service-section-inner-right'>
                    <TabPanel value={value} index={0}>
                      <div className='service-img-content'>
                        <div className='service-img'>
                          <img src={WealthManagement} />
                          <div className='service-card'>
                            <h3>Wealth Management and Investment</h3>
                            <p>Are you looking for an integrated wealth management solution for your firm that can solve all the challenges related to communication, reporting, user experience, regulations.</p>
                            <div className='btn-section'>
                              <div className='hexa-default-btn'>
                                <p>Explore More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className='service-img-content'>
                        <div className='service-img'>
                          <img src={Digital} />
                          <div className='service-card'>
                            <h3>Digital Lending & Alternative Financing</h3>
                            <p>Do you want to offer your borrowers a uniform & flawless digital experience? Our team at Hexaview has deep domain expertise in creating feature-rich digital lending solutions.</p>
                            <div className='btn-section'>
                              <div className='hexa-default-btn'>
                                <p>Explore More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <div className='service-img-content'>
                        <div className='service-img'>
                          <img src={Market} />
                          <div className='service-card'>
                            <h3>Capital & Stock Market Software Development</h3>
                            <p>Are you looking to customize your existing capital markets & stock market software, or want to build the software.</p>
                            <div className='btn-section'>
                              <div className='hexa-default-btn'>
                                <p>Explore More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <div className='service-img-content'>
                        <div className='service-img'>
                          <img src={DigitalBanking} />
                          <div className='service-card'>
                            <h3>Digital Banking</h3>
                            <p>Do you want to automate your traditional banking process or customize your existing banking processes? We will help keep your clients engaged at every phase.</p>
                            <div className='btn-section'>
                              <div className='hexa-default-btn'>
                                <p>Explore More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <div className='service-img-content'>
                        <div className='service-img'>
                          <img src={InsurTechImg} />
                          <div className='service-card'>
                            <h3>InsurTech</h3>
                            <p>Tired of manual and tedious processes for insurance? Wish to automate and revolutionize your software systems? Hexaview is here to assist you. We specialize in developing robust.</p>
                            <div className='btn-section'>
                              <div className='hexa-default-btn'>
                                <p>Explore More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>
      <OurService />
      <Industries />
      <OurClients array={clients} />
      <PressRelease />
      <CaseStudy array={caseStudies} />

      {/* <div>Welcome to hexaviewTech website</div>
      <button onClick={update}>update</button>
      {st.demo.client && st.demo.client.map((item: any) => {
        return (
          <div key={item.id}>
            <span>{item.id}</span>
            <span>{item['user_name']}</span>
          </div>
        )
      })} */}
    </Grid>
  );
}

interface props {
  numberArr?: any;
  textArr?: any;
  heading?: String;
}

export const CarouselSection = () => {
  return (
    <section className='carousel-outerDiv'>
      <div className='container'>
        <Carousel
          arrows nextArrow={<ArrowForwardIosIcon />} prevArrow={<ArrowBackIosIcon />}
          autoplay
          // arrows
          dots={false}
        >
          <div className='info-container'>
            <div className='info-text'>
              We find Hexaview’s team to be very transparent & responsive. They are open to solving any problem, however intricate and time-bound. With regular check-ins, they ensure that any feedback is acted upon promptly. Hexaview resources have always helped Adobe ship good quality products.
            </div>
            <div className='avatar-container'>
              <Avatar size={90} src="https://hexaviewtech.com/wp-content/uploads/2020/12/intelliQA.jpg" />
              <div className='description'>
                <div className='name'>Parvez</div>
                <div className='title'>Hexaview</div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </section>
  );
}

export default Index;
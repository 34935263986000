import React from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import CloudGetTouchBox from '../commonSection/cloudGetTouchBox/Index'
import Company from '../commonSection/company/Index'
import Counter from '../../../../common-components/counter-widget/Counter';
import IncreasedSpeedFlexibility from '../../../../../assets/cloud/Increased-speed-and-flexibility.svg'
import "./style.scss";
import Market from '../../../../../assets/cloud/svgexport-22.svg';
import Presentation from '../../../../../assets/cloud/svgexport-23.svg';
import NSP from '../../../../../assets/cloud/svgexport-24.svg';
import Progress from '../../../../../assets/cloud/svgexport-19.svg';
import ContainerWrapper from '../../../../utils/custom-tags/ContainerWrapper';
import TalkToExpert from '../../../../../assets/cloud/talk-to-expert.svg';
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Index = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const cosntSaving = <div className="text-section">
        <div>
            <h3>Cost-saving</h3>
            <p>Pay as per your cloud infrastructure usage and quickly scale up /down according to changing business needs.</p>
        </div>
    </div>
    const increasedEfficiency = <div className="text-section">
        <div>
            <h3>Increased Efficiency</h3>
            <p>In a hurry? No worries! Your cloud ecosystem can be ready for use in no time.</p>
        </div>
    </div>
    const increasedCollaboration = <div className="text-section">
        <div>
            <h3>Increased Collaboration</h3>
            <p>Collaboration becomes simple and enhances workforce and client collaboration, reducing stress and working on it.</p>
        </div>
    </div>
    const highAvailability = <div className="text-section">
        <div>
            <h3>High Availability</h3>
            <p>Most cloud providers are steadfast in offering their services, with most of them maintaining an uptime of 99.9%.</p>
        </div>
    </div>

    return (
        <Grid container className='cloud-migration-solutions paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/cloud-banner.jpg"}
                h1Heading="Cloud Migration Solutions"
                textSectionA={"Hexaview’s cloud migration solutions and services help you migrate your data to the cloud with minimal disruption while modernizing your business."}
                isScheduleCall={true}
            />
            {/*...service text section...*/}

            <ContainerWrapper className='application-development-deployment-section-B Section'>
                <Grid container >
                    <Grid className="application-development-deployment-section-left" item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="heading">
                            <h2>Cloud App Development</h2>
                            <h4>Solutions That Create Long-Term Value</h4>
                        </div>
                        <div className="text-section">
                            <p>Develop and deploy cloud applications at scale with Hexaview’s cloud application development services. Our cloud experts boost the performance of cloud-based applications using the latest and cutting-edge cloud technologies.</p>

                        </div>
                        <div className="text-section">
                            <p>We use external platforms, such as Amazon Web Services, Microsoft Azure, Google Cloud Platform to fulfill our clients’ needs, from cloud computing, API management, cloud-based application development, and more. Also, we perform application modernization services to adapt your current applications to run on the cloud.</p>
                        </div>
                        <div className='img-section'>
                            <div className='img-section-inner'>
                                <img src={"https://hexaviewtech.com/wp-content/uploads/2022/03/aws-Partner-Logo.svg"} />
                            </div>
                            <div className='img-section-inner'>
                                <img src={"https://hexaviewtech.com/wp-content/uploads/2022/03/Microsoft-Partner-Logo.svg"} />
                            </div>
                        </div>
                    </Grid>
                    <Grid className="application-development-deployment-section-right" item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <img src={"https://hexaviewtech.com/wp-content/uploads/2022/03/Cloud-App-Development-Solutions.svg"} style={{ width: "100%", height: "100%" }} />
                    </Grid>
                </Grid>
            </ContainerWrapper>

            <section className='application-development-deployment-section-C Section'>
                <div className='container'>
                    <Grid item xs={12}>
                        <div className="heading">
                            <h2>Why choose our DevOps Consulting Services?</h2>
                        </div>
                        <div className="text-section element-widget-wrap">
                            <p>
                                Hexaview is a trusted partner to support your cloud journey. Our cloud experts help create, release, and deliver your cloud strategy, new products, and services. Combining the best of all the major cloud providers, we provide a seamless experience for our customers, employees, and other stakeholders. Our cloud connoisseurs hold many years of experience at developing and deploying solutions based on SaaS (Software as a Service), IaaS (Infrastructure as a Service), and PaaS (Platform as a Service).
                            </p>
                        </div>
                        <div className='elementory-section'>
                            <div className='elementory-column'>
                                <Counter prefix='' suffix='+' number="11" title="Years in the Market" img={Market} />
                            </div>
                            <div className='elementory-column'>
                                <Counter prefix='' suffix='+' number="400" title="Projects Delivered" img={Presentation} />
                            </div>
                            <div className='elementory-column'>
                                <Counter prefix='' suffix='' number="NSP" title="World-class NPS score" img={NSP} />
                            </div>
                            <div className='elementory-column'>
                                <Counter prefix='' suffix='%' number="100" title="CSAT" img={Progress} />
                            </div>
                        </div>
                    </Grid>
                </div>
            </section>

            <section className='application-development-deployment-section-D Section'>
                <div className='container'>
                    <div className="heading">
                        <h3>Benefits Of Cloud-Based Solutions</h3>
                        <p>Our Cloud-based application development services have enormous benefits to offer</p>
                    </div>
                    <div className="tabpanel-container">
                        <div className="tabpanel-text-section">
                            <div className='application-development-deployment-section-left'>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label={cosntSaving} className='customTab' {...a11yProps(0)} />
                                        <Tab label={increasedEfficiency} className='customTab' {...a11yProps(1)} />
                                        <Tab label={increasedCollaboration} className='customTab' {...a11yProps(2)} />
                                        <Tab label={highAvailability} className='customTab' {...a11yProps(3)} />
                                    </Tabs>
                                </Box>
                            </div>
                        </div>
                        <div className='tabpanel-img-section'>
                            <TabPanel value={value} index={0}>
                                <img src="https://hexaviewtech.com/wp-content/uploads/2022/03/cost-saving.png" />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <img src="https://hexaviewtech.com/wp-content/uploads/2022/03/increased-efficiency.png" />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <img src="https://hexaviewtech.com/wp-content/uploads/2022/03/Collaboration.png" />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <img src="https://hexaviewtech.com/wp-content/uploads/2022/03/avalibilty.png" />
                            </TabPanel>
                        </div>
                    </div>
                    <div className='hexa-primary-btn'>
                        <p>Talk to our Expert <img src={TalkToExpert} alt="" /></p>
                    </div>
                </div>
            </section>

            <Company />
            <CloudGetTouchBox
                h2Heading="Curious whether Hexaview is the right fit for your project?"
                textSection="We understand that every business is different and demands an individual approach. That is why we offer a free consulting session. During the session, you will have a chance to determine whether our Cloud Application Development and Deployment Services are the right fit for your project."
                buttonText="Get in Touch"
            />
        </Grid>
    );
}

export default Index;
import React from 'react';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import '../FinTechCommon.scss';
import banner from '../../../../../assets/FinTech-wealth-management/wealth-management-banner.jpg';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import automation from '../../../../../assets/FinTech-wealth-management/automation.svg'
import CheckIcon from '@mui/icons-material/Check';
import Settings from '../../../../../assets/FinTech-wealth-management/capital-market/settings.svg';
import Chat from '../../../../../assets/FinTech-wealth-management/capital-market/chat.svg';
import FlowerHands from '../../../../../assets/FinTech-wealth-management/flowerHands.svg';
import Progress from '../../../../../assets/FinTech-wealth-management/progress.svg';
import Goals from '../../../../../assets/FinTech-wealth-management/goal.svg'
import ContainerWrapper from '../../../../utils/custom-tags/ContainerWrapper';
import CaseStudy from '../../../../common-components/caseStudy/Index';
import PortfolioManagement from '../../../../../assets/FinTech-wealth-management/portfolio-management.webp';
import TotumUI from '../../../../../assets/FinTech-wealth-management/TOTUM-UI.webp';
import Chatbot from '../../../../../assets/FinTech-wealth-management/chatbot.webp';
import Rebalancer from '../../../../../assets/FinTech-wealth-management/Rebalancer.webp';
import TCredit from '../../../../../assets/FinTech-wealth-management/Transforming-Credit-Advisors.webp';
import GCredit from '../../../../../assets/FinTech-wealth-management/g-Credit-Advisors.webp';
import StressTest from '../../../../../assets/FinTech-wealth-management/Stress-Testing.webp';
import ClientApp from '../../../../../assets/FinTech-wealth-management/Clients-Application.webp';
import WM from '../../../../../assets/FinTech-wealth-management/wealth-management.webp';
import OP from '../../../../../assets/FinTech-wealth-management/online-portal.webp';
import TCD from '../../../../../assets/FinTech-wealth-management/transform-custodial.webp';
import DT from '../../../../../assets/FinTech-wealth-management/distributed-trade.webp';
import Financial from '../../../../../assets/FinTech-wealth-management/financial-social-networking.webp';
import WebApp from '../../../../../assets/FinTech-wealth-management/web-application.webp';
import BillingApp from '../../../../../assets/FinTech-wealth-management/billing-application.webp';
import AE from '../../../../../assets/FinTech-wealth-management/ability-effectively.webp';
const WealthManagement = () => {
    const caseStudies = [
        {
            image: PortfolioManagement,
            heading2: "Empowered Decision-Makers With A Comprehensive",
            text: "Learn how Hexaview assisted its client in helping decision-makers with an end-to-end portfolio management & analysis application."
        },
        {
            image: TotumUI,
            heading2: "Helped a FinTech firm redesign its flagship platform to improve the user",
            text: "Learn how Hexaview helped its client in increasing the client base of our client through a revamped platform empowering user experience."
        },
        {
            image: Chatbot,
            heading2: "How Hexaview helped a FinTech firm upgrade its financial planning",
            text: "Learn how Hexaview helped its client in empowering advisors provide a personalized financial planning journey, quickly and easily to their clients."
        },
        {
            image: Rebalancer,
            heading2: "Portfolio Rebalancing Engine – Trade Order Management",
            text: "Our client, a leading US- based global FinTech firm wanted to develop their own rebalancing engine in their trade and order management system to help their customers."
        },
        {
            image: TCredit,
            heading2: "An online marketplace for revolutionizing investment",
            text: "Hexaview helped its client to upgrade their existing platform to a comprehensive marketplace where users can execute research, compare, discover the best option, and invest in mutual funds, ETFs,"
        },
        {
            image: GCredit,
            heading2: "Transforming eCreditAdvisor’s Tech Environment Through Modern",
            text: "A credit repair company was experiencing various challenges with their existing systems and wanted some upgrades. Learn how Hexaview helped them in achieving maximum productivity and"
        },
        {
            image: StressTest,
            heading2: "Ensuring Scalability Of A Leading Wealth Management",
            text: "The need to was to verify if the component initially developed by Hexaview in Salesforce can handle the large set of unique users load without failing. Our team executed several performance tests and check"
        },
        {
            image: ClientApp,
            heading2: "Optimized The Client’s Application To Handle 1M+ Portfolios and",
            text: "One of the largest America’s Trade and Order Management System waned to optimize their portfolio management system to handle large volumes of database at a time. Learn how Hexaview helped"
        },
        {
            image: OP,
            heading2: "Developed an Online Portal To Manage Assets, Liabilities, Positions",
            text: "The client is a well-established wealth management firm based in the United States. They were using different platforms to see all the information regarding their positions, accounts, liabilities,"
        },
        {
            image: WM,
            heading2: "Customizing Advisor Desktop platform created by a leader in US",
            text: "A global investments company dedicated to helping its clients manage and service their financial assets was finding it difficult to manage the increasing number of advisors in the existing application."
        },
        {
            image: DT,
            heading2: "From Silverlight to HTML5: Upgraded the Client’s Portfolio",
            text: "One of our esteemed US-based clients specialized in Trade & Order management wanted us to enhance their existing Silverlight based application to support rebalancing & trade generation for thousands of"
        },
        {
            image: TCD,
            heading2: "Transforming SSRS Report Generation Process using Advent Portfolio",
            text: "Our US-based client specializes in portfolio management and uses ADVENT’s APX to store client’s financial data. They were looking for an application that can provide custom rules to transform custodial data"
        },
        {
            image: Financial,
            heading2: "A financial social networking platform for investors and",
            text: "Our U.S. based client required an application that was having suitable functions to perform peer group analysis, share profile analysis, compare portfolio & build connections. The application was expected"
        },
        {
            image: WebApp,
            heading2: "Developed an end-to-end web application to manage client",
            text: "Our client is a well-renowned wealth management firm based in the US. They wanted an online platform to manage their client portfolios, track prospects, generate presentations, and select"
        },
        {
            image: BillingApp,
            heading2: "Advisor Billing Application to Cater to Varying Enterprise",
            text: "Customer’s existing Billing application was used by Financial Advisors to generate Bills for their clients, generate Reports regarding client information and billings corresponding to clients."
        },
        {
            image: AE,
            heading2: "Ability to Effectively Calculate & Pay to Financial Reps",
            text: "One of our US-based clients specializing in Fiduciary Investment Management and Wealth Advisory services wanted to automate their existing Excel-based program."
        },
    ]
    return (
        <div className='main-container paddingTopSection'>
            <PageTopSection
                img={banner}
                h1Heading={"Taking Wealth Management to New Heights"}
                textSectionA={"Hexaview delivers intelligent automated solutions to empower financial advisors in providing significant data insights & a more personalized, cost-effective experience to their clients."} />

            <section className="wm-section-A Section">
                <div className="container">
                    <Grid container className="element-inner-section">
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className="heading">
                                <h3>Challenges Related to Wealth Management</h3>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="text-section">
                                <p>For more than 11 years, Hexaview has served the technology needs of the wealth management industry, empowering our clients to keep track of their wealth and take the right investment decisions.</p>
                            </div>
                        </Grid>
                    </Grid>

                    <div className="text-section">
                        <p>The changing environment, demographics, technology, and social behaviors have set the ground for rapid digital transformation in the wealth management industry. To differentiate yourself in the market, you should focus on how you use technology and enhancing your customer experience using advance analytics.  There has never been a better time for the wealth management industry to review and replace its operating technology. Modern wealth management technology solutions empower advisors and relationship managers to focus on their clients rather than the processes.</p>
                    </div>

                    <Grid className="wm-cards-section" container spacing={4}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <CommonCard
                                img={Chat}
                                heading={"Big-Budget Robo Advisory"}
                                text={"Is Robo Advisory bleeding your budget? Robo Advisory may be in wealth management Vogue, but we all know how it eats at your resources. Why not try an alternative?"} /></Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <CommonCard
                                img={FlowerHands}
                                heading={"Data Management"}
                                text={"Are you drowning in vast amounts of data? Recording, maintaining, and accessing data shouldn't be such an ongoing strain for advisors."} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <CommonCard
                                img={Settings}
                                heading={"A Need for Automation"}
                                text={"Are you still confined to the inefficiency of manual methods? Save time by automating where appropriate. Free up your focus for the steps and tasks that truly require your attention."} />
                        </Grid>
                    </Grid>
                </div>
            </section >

            <section className="wn-section-B Section">
                <div className="container">
                    <div className="heading">
                        <h2>Wealth Management Technology Solutions</h2>
                    </div>
                    <div className="text-section">
                        <p>
                            Hexaview brings you the experience of decades in the trenches. Over the years, we've worked closely with a range of wealth management stalwarts to learn the nuts and bolts of the industry. We put this to use by developing the most professional wealth management software solutions designed to unburden advisors. Our integrated wealth management software solutions allow the advisors to collaborate with clients effortlessly on retirement and goals-based planning, create investment proposals, gain insights through advance analytics, deliver financial advice, and much more across multiple clients. No matter how many clients you have, which segment they belong to, or how experienced you advisors are, you can manage them easily.
                        </p>
                    </div>
                    <div className='card-wrapper'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <CommonCard
                                    img={automation}
                                    heading={"Advanced Automation"}
                                    backgroundColor={"#ffffff"}
                                    text={"Our wealth management technology solutions are imbibed with distinctive AI and ML technologies and tools like Big Data, Neo4j Graph Database to simplify and revolutionize your wealth management experience."}
                                    list={[
                                        "We've engineered our software to give you the most precise results, faster!",
                                        "Automation adds HOURS back onto your calendar.",
                                        "Artificial Frameworks give you the agility to test-drive your solutions.",
                                        "Use statistics to estimate the relationships among variables accurately.", "Data mining tools let you navigate large sets of data to identify recurring patterns and establish problem-solving relationships."]}
                                    color="#5038F0" /></Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <CommonCard
                                    img={Goals}
                                    heading={"Seamless Integration"}
                                    backgroundColor={"#ffffff"}
                                    text={"Our solutions establish a unified platform that integrates all your tools for more robust insights and a lengthened reach."}
                                    list={[
                                        "Hexaview solutions prioritize ACCURACY and a streamlined process.",
                                        "Machine perception interprets data in a manner akin to human processing.",
                                        "Cloud storage provides you a secure platform with universal intelligibility.",
                                        "A multi-user platform offers flexibility and shared access.",
                                        "Hexaview adheres to an uncompromising standard of wealth management software practices."]}
                                    color="#FC6681" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <CommonCard
                                    img={Progress}
                                    heading={"Customized Reporting"}
                                    backgroundColor={"#ffffff"}
                                    text={"Hexaview supplies tailored solutions perfect for your individual requirements."}
                                    list={[
                                        "Optimize your analyses and data interpretation.",
                                        "Tabulate more effectively with improved data alignment.",
                                        "View comparative data within a straightforward interface.",
                                        "Interact with data through user-friendly visual aids.",
                                        "Achieve accurate calculations supported by meticulous validation and a graphical tabulation of data."]}
                                    color="#37BE5B" />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            <section className="wm-section-C Section">
                <div className="container">
                    <h2>Trusted by 5000+ advisors as to their go-to financial planning tool.</h2>
                    <div className='talk-to-epert'>
                        <h5 style={{ color: '#43BAFF' }}>Talk to Our Expert</h5>
                        <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                    </div>
                </div>
            </section>
            {/* <CaseStudy array={caseStudies} /> */}
        </div >


    )
}

interface props {
    img?: string,
    heading?: String,
    text?: String,
    list?: any,
    color?: any,
    backgroundColor?: any,
}

export const CommonCard = ({ img, heading, text, list, color, backgroundColor }: props) => {
    const backgroundcolor = backgroundColor ? backgroundColor : "#EEF5FF";
    return (
        <Card className='card' sx={{ maxWidth: 390 }}>
            {img && <div style={{ backgroundColor: `${backgroundcolor}` }} className="image-section">
                <img src={img} alt="" />
            </div>}
            <CardContent style={{ paddingLeft: "1rem", paddingRight: "1rem" }} className='card-content'>
                <Typography className='card-content-heading' gutterBottom component="div">
                    <h4> {heading} </h4>
                </Typography>

                <Typography className="card-text-section" variant="body1">
                    {text}
                </Typography>
                <div style={{ paddingTop: "1rem" }}></div>
                <div className="list-section">
                    <Typography>
                        <ul>
                            {list && list.map((item: any) => {
                                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}> <CheckIcon style={{ paddingRight: "0.3rem", color: `${color}` }} /> <li>  <p> {item} </p> </li></div>
                            })}
                        </ul>
                    </Typography>
                </div>
            </CardContent>
        </Card>
    )
}

export default WealthManagement
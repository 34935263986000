import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Press1 from '../../../../assets/home/1.png'
import Press2 from '../../../../assets/home/2.png'
import Press3 from '../../../../assets/home/3.png'
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import Slider from "react-slick";
import "./style.scss";


const Index = () => {


  function PrevArrowIcon(props:any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "flex"}}
        onClick={onClick}
      >
      <LeftOutlined />
      </div>
    );
  }
  function NextArrowIcon(props:any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "flex"}}
        onClick={onClick}
      >
        <RightOutlined />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrowIcon />,
    prevArrow: <PrevArrowIcon />
};

  return (
    <section className='press-release-section Section'>
              <div className='container'>
                <Grid container>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <div className='pressLeftDiv'>
                        <h3>News & Press<br /> Release</h3>
                        <div className='hexa-secondary-btn'>
                            <p>View All <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <Slider {...settings}>
                          <div className='pressOuterCard'>
                            <div className='pressCard'>
                              <div className='img'>
                                <img src={Press1}/>
                              </div>
                              <div className='content'>
                                <p>Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so.</p>
                                <div className='hexa-default-btn'>
                                  <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='pressOuterCard'>
                            <div className='pressCard'>
                              <div className='img'>
                                <img src={Press2}/>
                              </div>
                              <div className='content'>
                                <p>Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so.</p>
                                <div className='hexa-default-btn'>
                                  <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='pressOuterCard'>
                            <div className='pressCard'>
                              <div className='img'>
                                <img src={Press3}/>
                              </div>
                              <div className='content'>
                                <p>Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so.</p>
                                <div className='hexa-default-btn'>
                                  <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='pressOuterCard'>
                            <div className='pressCard'>
                              <div className='img'>
                                <img src={Press1}/>
                              </div>
                              <div className='content'>
                                <p>Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so.</p>
                                <div className='hexa-default-btn'>
                                  <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='pressOuterCard'>
                            <div className='pressCard'>
                              <div className='img'>
                              <img src={Press2}/></div>
                              <div className='content'>
                                <p>Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so.</p>
                                <div className='hexa-default-btn'>
                                  <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='pressOuterCard'>
                            <div className='pressCard'>
                              <div className='img'>
                              <img src={Press3}/></div>
                              <div className='content'>
                                <p>Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so.</p>
                                <div className='hexa-default-btn'>
                                  <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Slider>
                  </Grid>
                </Grid>
              
              </div>
            </section>
  );
}

export default Index;
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button } from 'antd';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import Enterprises from '../../../../assets/home/enterprises.svg';
import CaptiveCenter from '../../../../assets/home/captive-center.svg';
import MobileDevelopment from '../../../../assets/home/mobile-development.svg';
import CloudSecurity from '../../../../assets/home/cloud-security.svg';
import HybridCloud from '../../../../assets/home/hybrid-cloud.svg';
import Application from '../../../../assets/home/application.svg';
import CloudOptimization from '../../../../assets/home/cloud-optimization.svg';
import CloudMigration from '../../../../assets/home/cloud-migration.svg';
import DevOps from '../../../../assets/home/devOps.svg';
import DataVisualization from '../../../../assets/home/data-visualization.svg';
import DataWarehouse from '../../../../assets/home/data-warehouse.svg';
import Rpa from '../../../../assets/home/rpa.svg';
import MachineLearning from '../../../../assets/home/machine-learning.svg';
import ArtificialIntelegence from '../../../../assets/home/artificial-intelegence.svg';
import SmartChatbot from '../../../../assets/home/smart-chatbot.svg';
import BigDataAnalysis from '../../../../assets/home/big-data-analysis.svg';
import SalesforceManagedServices from '../../../../assets/home/salesforce-managed-services.svg';
import SalesforceArchitecture from '../../../../assets/home/salesforce-architecture.svg';
import ServicesIntegration from '../../../../assets/home/services-integration.svg';
import SalesforceCustomization from '../../../../assets/home/salesforce-customization.svg';
import DataMigration from '../../../../assets/home/data-migration.svg';
import SalesforceDevelopment from '../../../../assets/home/salesforce-development.svg';
import SalesforceConsulting from '../../../../assets/home/salesforce-consulting.svg';
import "./style.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Index = () => {

  const [value, setValue] = React.useState(0);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };



  const nextButton = ()=>{
    setValue(value + 1);
  }
  const prevButton = () => {
        setValue(value - 1);
  }

  
    const salesforce = <h3><span>01</span>SALESFORCE</h3>

    const dataScience = <h3><span>02</span>DATA SCIENCE</h3>

    const cloudService = <h3><span>03</span>CLOUD SERVICE</h3>

    const manageService = <h3><span>04</span>MANAGED SERVICE</h3>

  return (
    <section className='home-our-section-section'>
        <div className='container'>
        <Grid container>
            <Grid className='backgroundImg' item lg={2} xl={2}>
            <div className='imgSection'>
            </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                <div className='home-section-right Section'>                        
                    <Grid container>
                        <Grid xs={12} md={12} lg={5}>
                            <div className='home-section-inner-left'>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <h4>SERVICES THAT CAN<br />TRANSFORM YOUR BUSINESS</h4>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label={salesforce} className='customTab' {...a11yProps(0)} />
                                        <Tab label={dataScience} className='customTab' {...a11yProps(1)} />
                                        <Tab label={cloudService} className='customTab' {...a11yProps(2)} />
                                        <Tab label={manageService} className='customTab' {...a11yProps(3)} />
                                    </Tabs>
                                </Box>
                            </div>
                        </Grid>
                        <Grid xs={12} md={12} lg={7}>
                        <div className='home-section-inner-right'>
                        <TabPanel value={value} index={0}>
                            <div className="text-section">
                                <div>
                                    <p>Whether you seek to bridge the gaps in your current Salesforce implementation – or you’re in the early stages of integration, we are here to assist. We specialize in end-to-end Salesforce implementations & customization, Lightning/ Data migration, and Consulting Services.</p>
                                </div>
                            </div>
                            <div className='main-inner-div'>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={SalesforceConsulting} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Salesforce Consulting</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={SalesforceDevelopment} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Salesforce Development</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={DataMigration} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Data Migration</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={SalesforceCustomization} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Salesforce Customization</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={ServicesIntegration} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Service Integration</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={SalesforceArchitecture} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Salesforce Architecture</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={SalesforceManagedServices} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Salesforce Managed Services</p>
                                    </div>
                                </div>
                                <div className='inner-div'></div>
                                <div className='inner-div'></div>
                            </div>
                            
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                            <div className="text-section">
                                <div>
                                    <p>Are you struggling with making high-impact decisions and lacking a competitive advantage? Our data science services will help you in predicting and forecasting by turning raw data into accurate predictions. We provide expertise across AI, ML, and NLP to empower your business towards digital transformation.</p>
                                </div>
                            </div>
                            <div className='main-inner-div'>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={BigDataAnalysis} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Big Data Analytics</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={SmartChatbot} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Smart Chatbot</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={ArtificialIntelegence} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Artificial Intelligence</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={MachineLearning} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Machine Learning</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={Rpa} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Robotic process automation</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={DataWarehouse} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Data Warehouse</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={DataVisualization} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Data Visualization</p>
                                    </div>
                                </div>
                                <div className='inner-div'></div>
                                <div className='inner-div'></div>
                            </div>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                            <div className="text-section">
                                <div>
                                    <p>Are you looking for the best cloud migration strategy? Our cloud consulting services will help you choose an ideal migration strategy and frame a secure & customized cloud ecosystem for your business. As we are ISO 9001 & ISO 27001 certified, you can be assured of world-class quality and security.</p>
                                </div>
                            </div>
                            <div className='main-inner-div'>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={DevOps} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>DevOps</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={CloudMigration} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Cloud Migration</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={CloudOptimization} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Cloud Optimization</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={Application} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Application Deployment on Cloud</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={HybridCloud} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Public, Private, and Hybrid Cloud</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={CloudSecurity} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Cloud Governance & Security</p>
                                    </div>
                                </div>
                            </div>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                            <div className="text-section">
                                <div>
                                    <p>Are you facing high infrastructure costs, lack of skilled resources, frequent downtime, and application failures? Get them fixed now with Hexaview Managed Services. We provide all kinds of support like Center of Excellence (COE), Management teams, QA teams, and Admins to ensure that the project stays on track in terms of time, cost and quality.</p>
                                </div>
                            </div>
                            <div className='main-inner-div'>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={Enterprises} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Enterprise Product Development</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={MobileDevelopment} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Mobile Development</p>
                                    </div>
                                </div>
                                <div className='inner-div'>
                                    <div className='inner-img-div'>
                                    <img src={CaptiveCenter} alt=""/>
                                    </div>
                                    <div className='inner-content-div'>
                                        <p>Captive Center</p>
                                    </div>
                                </div>
                            </div>
                            </TabPanel>
                            <div className='hexa-default-btn'>
                            <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                            </div>
                        </div>
                        </Grid>
                    </Grid>

                    <Grid className='btnGroup'>
                    <button 
                      onClick={prevButton} 
                      disabled={value == 0 ? true : false} 
                      className={value == 0 ? "disabled" : ""}
                    ><UpOutlined /></button>
                    <button 
                      onClick={nextButton} 
                      disabled={value == 3 ? true : false}
                      className={value == 3 ? "disabled" : ""}
                      ><DownOutlined /></button>
                    </Grid>
                </div>
            </Grid>
        </Grid>
        </div>
    </section>
  );
}

export default Index;
import { Card, Grid } from '@mui/material'
import React from 'react'
import SecondaryTopPageSection from '../../../../common-components/pageTop-section/SecondaryTopPageSection'
import './SocialImpact.scss'

const SocialImpact = () => {
    return (
        <div className="social-impact-container paddingTopSection">
            <div className="si-section-A">
                <SecondaryTopPageSection
                    img={"https://hexaviewtech.com/wp-content/uploads/2021/01/CSR-Banner.png"}
                    h1Heading={"PANKH - CSR @ HEXAVIEW"}
                    textSectionA={"Fostering healthy relationships with the environment and society"}
                />
            </div>

            {/* Section B started */}
            {/* <Grid item xs={12} sm={12} md={8} lg={8} xl={8}> */}
            <section className="si-section-B Section">
                <div className='container'>
                    <div className="heading">
                        <h3>We Understand The Responsibility To Flourish Our Society</h3>
                    </div>
                    <div className="text-section">
                        Our CSR Team, called Pankh, was established in 2016, with a focus to  contribute, participate, and implement social development projects in the fields of Health, and  Environment Care. Our CSR activities add considerably to the core of our company.

                    </div>


                    <div className="si-section-B-row">
                        <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="section-B-column color-abb">
                                <div className="heading">
                                    <h3>OUR VALUES</h3>
                                </div>
                                <div className="text-section">
                                    <p>
                                        At Hexaview, we believe that the more we give back to the community, the more it helps us grow. And that’s why we set goals to support environmental sustainability, diversity and inclusion, and social impact. HVTians work as a team to lead and participate in different initiatives that address critical social and environmental issues.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="section-B-column color-bff">
                                <div className="heading">
                                    <h3>OUR VISION</h3>
                                </div>
                                <div className="text-section">
                                    <p>
                                        Hexaview’s people, its management and affiliated parties are dedicated to working towards creating a healthy environment. We aim to contribute to the development of underprivileged people actively and bettering local communities’ lives by supporting sustainability, cleanliness drives, waste management, and green initiatives.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </div>
                </div>
            </section>


            <section className="si-section-C Section">
                <div className='container'>
                    <div className="main-heading">
                        <h2>Our CSR Activities</h2>
                    </div>

                    <div className="si-elements-card-container">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Card className='card-styling'>
                                    <Cards
                                        image={'https://hexaviewtech.com/wp-content/uploads/2022/02/diverse-people-joining-their-hands.png'}
                                        heading="Our Corporate Volunteering Policy"
                                        text="The purpose of the Volunteering Policy is to outline the opportunities & procedures for HVTians (Hexaview Employees) to engage social and community welfare activities."
                                        button={true} />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Cards
                                    image={'https://hexaviewtech.com/wp-content/uploads/2021/01/Untitled-design-13.png'}
                                    heading="Give Heartful And Take Handful"
                                    text="Clothes and blankets are some of the necessities the homeless people need to help them survive during the winter blues. Our CSR team took the initiative and helped the homeless people with a Clothes & Blanket Donation Drive."
                                    button={true} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Cards
                                    image={'https://hexaviewtech.com/wp-content/uploads/2021/01/CSR.png'}
                                    heading="Encouraging Sports At Sai Kripa"
                                    text="On the occasion of Children’s Day, we took gave the kids of Sai Kripa Orphanage hope for everyday happiness and nourishment to their good mental and physical health by arranging a Badminton court. "
                                    button={true} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Cards
                                    image={'https://hexaviewtech.com/wp-content/uploads/2021/01/CSR-1.png'}
                                    heading="Spreading Smiles Ensuring Safety"
                                    text="With Monsoon season around the corner, the risk of Water-borne diseases increases. To promote awareness and safety, we ran a campaign “Hygiene for Kids” and distributed a kit comprising of essential items."
                                    button={true} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Cards
                                    image={'https://hexaviewtech.com/wp-content/uploads/2021/01/CSR-3.png'}
                                    heading="Safe Drinking Water For All"
                                    text="Contaminated drinking water is a source of various diseases. Therefore, to make clean drinking water available to all, we installed a Reverse Osmosis (RO) based Water Cooler outside Hexaview’s Parking Zone’s exit gate."
                                    button={true} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Cards
                                    image={'https://hexaviewtech.com/wp-content/uploads/2021/01/CSR-2.png'}
                                    heading="Women Sanitation and Hygiene"
                                    text="To promote healthy and safe menstruation, we organized an activity educating the under-privileged girls in the rural areas about the proper usage and disposal of sanitary pads. Also, we provided the semi- standards pads to them enabling them to adopt hygienic practices."
                                    button={true} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            {/* </Grid> */}
        </div>
    )
}

interface props {
    image?: string
    heading?: String
    text?: String
    button?: Boolean
}

export function Cards({ image, heading, text, button }: props) {
    return (
        <div className="section-C-column">
            <div className="heading">
                <h3>{heading}</h3>
            </div>
            <div className="image-section">
                <img src={image} alt="" />
            </div>
            <div className="text-section">
                <p> {text} </p>
            </div>
            {button && <div className="button-read-more">
                <h5>READ MORE</h5>
            </div>}
        </div>
    );
}

export default SocialImpact;
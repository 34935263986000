import React from 'react';

const FluidContainerWrapper = (props: any) => {
    return (
        <section className={props.className ? props.className : ''} style={props.style ? props.style : null}>
            <div className='container-fluid'>
                {props.children}
            </div>
        </section>
    );
}

export default FluidContainerWrapper;
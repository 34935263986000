import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import bgImg from "../../../assets/bgImg.jpg";
import { Button, CardActionArea, CardActions } from '@mui/material';
import "./Cards.scss";

type Props = {
    headerIcon?: String | React.ReactNode,
    headerImgUrl?: string,
    contentHeading?: String,
    isActionablePostHeading?:Boolean,
    actionablePostHeading?:String,
    contentText?: String,
    isActionBtn?: Boolean,
    size?:any,
    mSize?:any,
    id?:string


}
const Cards = ({ headerIcon, headerImgUrl, contentHeading, isActionablePostHeading=false, 
    actionablePostHeading,contentText, isActionBtn,size=4, mSize,id="" }: Props) => {
    return (
        <Grid className='card-container' item xs={12} sm={12} md={mSize ? mSize : size} lg={size} xl={size}>
            <Card className='card' id={id}>
                {/* <CardActionArea> */}
                    {headerIcon && (<CardHeader avatar={headerIcon} />)}
                    {headerImgUrl && (<CardMedia
                        component="img"
                        height="140"
                        image={headerImgUrl}
                        alt="green iguana"
                    />)}
                    <CardContent className='card-content'>
                        <Typography className='card-content-heading' gutterBottom variant="h6" component="div">
                            {contentHeading}
                        </Typography>
                        {isActionablePostHeading &&(<div className='card-content-action-heading'><h4>
                            {actionablePostHeading}
                        </h4></div>)}
                        <Typography variant="body2">
                            {contentText}
                        </Typography>
                    </CardContent>
                {/* </CardActionArea> */}
                {isActionBtn && (
                    <CardActions>
                        <Button size="small" color="primary">
                            Read More
                        </Button>
                    </CardActions>
                )}
            </Card>
        </Grid>
    );
}

export default Cards;
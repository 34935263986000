import { Grid } from '@mui/material'
import React from 'react'
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection'
import Card from '@mui/material/Card';
import './WomenWelfareNetwork.scss';
import building from '../../../../../assets/building.svg';
import Disability from '../../../../../assets/corporate-overview/svgexport-15.svg';
import Quote from '../../../../../assets/corporate-overview/Quote.svg';

function WomenWelfareNetwork() {
    return (
        <div className="wwn-main-container-section paddingTopSection">
            <PageTopSection
                img={'https://hexaviewtech.com/wp-content/uploads/2022/07/women-welfar.jpg'}
                h1Heading="HERoes@Hexaview"
                colorH1Heading={"Women Welfare Network"}
                textSectionA={"HERoes@Hexaview is a networking group of our female employees. We empower our HERoes to connect & contribute, enabling us to grow as individuals and collectively build a more substantial community."}
                isScheduleCall={false}
                isTalkToExpert={false} />

            <section className="wwn-section-A Section">
                <div className='container'>
                    <CommonCard
                        heading={"Mission"}
                        textSection={"To provide our female workers a culture where all women are supported and empowered to reach their fullest potential."}
                        button={true}
                        isIconText={false} />
                </div>
            </section>

            <section className="wwn-section-B Section">
                <div className='container'>
                    <Grid container className='element-inner-section'>
                        <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="image-section">
                                <img style={{ width: "400px" }} src="https://hexaviewtech.com/wp-content/uploads/2022/07/Portal-for-women.jpg" alt="Main Image" />
                            </div>

                        </Grid>
                        <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="heading">
                                <h2>HELP PORTAL FOR <br /><span style={{ color: "#43BFFA" }}> WOMEN EMPLOYEES</span></h2>
                            </div>
                            <div className="text-section">
                                <p>
                                    We continue to focus on the health needs of the women workforce through our digital healthcare, especially for mental health and lifestyle management, to increase employee satisfaction and performance. Continuous feedback is collected from Women@Hexaview for regularly revising the strategies and corporate policies specific to women’s wellness.
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>

            <section className="wwn-section-C Section">
                <div className="container">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="heading">
                                <h2><span style={{ color: "#43BFFA" }}>CARE-GIVE</span><br /> <span> EDUCATION PROGRAMS</span></h2>
                            </div>
                            <div className="text-section">
                                <p>
                                    Group counseling workshops & meetups are conducted to educate people about nutrition & hygiene. The program aims to improve women’s wellness and achieve their target health goals like improving sleep, better diet, weight management, and more.</p>
                            </div>
                            <br />
                            <IconText
                                image={building}
                                heading={"Supported by Corporate Policy"}
                                textSection={"To empower and enable women to care for their physical and emotional health, we ensure their continued participation in the framing of corporate policies. This includes the 'New Parent Benefit Policy' that offers financial benefits for new parents to help meet the needs of their new family and infant."} />
                            <br />
                            <IconText
                                image={Disability}
                                heading={"Align with Business Objectives"}
                                textSection={"Our Women Welfare Network is well aligned with the business objectives. It is heartening to see Hexaview proactively involved in curating unique and inclusive wellness programs to keep its female employees motivated during the pandemic. With our Women's network, we continued to reap abundant rewards in terms of productivity and employee retention."} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className="image-section">
                                <img style={{ height: "500px", width: "330px" }} src="https://hexaviewtech.com/wp-content/uploads/2022/07/Care-give.png" alt="" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>


            <section className="wwn-section-D Section">
                <div className="container">
                    <div className="heading">
                        <h2>MEET OUR TEAM</h2>
                    </div>
                    <div className="team-card-container">
                        <TeamCard
                            image={'https://hexaviewtech.com/wp-content/uploads/2022/07/abhishek-wwn.jpg'}
                            heading={"Abhishek Talwar"}
                            colorHeading={"Cofounder & CEO | Chief Diversity Officer"}
                            textSection={"Abhishek Talwar: As the Chief Diversity Officer Abhishek leads all the internal and external DEI initiatives. This includes fostering an inclusive workplace, and defining actionable efforts that illustrate a strong commitment to serve diverse communities, and evolve programs that drive equity and inclusion at senior levels of leadership for people of color."} />
                        <br />
                        <TeamCard
                            image='https://hexaviewtech.com/wp-content/uploads/2022/02/bhagshree.png'
                            heading={"Bhagyashri Jha"}
                            colorHeading={"Associate Director-Data | Co-Chairperson - Employee Welfare Group"}
                            textSection={"As the Co-Chairperson for the Employee Welfare Groups, Bhagyashree primarily presides over the committee meetings, maintains regular communication with the Committee Chair, ensures a safe & healthy culture in the workplace, and is actively involved in the discussions and decisions of the committee."} />
                        <br />
                        <TeamCard
                            image='https://hexaviewtech.com/wp-content/uploads/2022/07/Monica-Dass-WWN.jpg'
                            heading={"Monica Dass"}
                            colorHeading={"Sr. HR Manager | Co-Chairperson - Employee Welfare Group"}
                            textSection={"Monica adheres to the responsibility to lead our Welfare Groups, manage daily operations, and be the PoC for all the employees regarding their grievances and suggestions. Monica manages the policies created for the HVTians and takes care of the existing regulations for employee welfare."} />

                    </div>
                </div>
            </section>
        </div >
    )
}

interface props {
    image?: any
    heading?: String
    textSection?: String
    button?: Boolean
    colorHeading?: String
    isIconText?: Boolean
}

export const IconText = ({ image, heading, textSection }: props) => {
    return (
        <div className="text-icon-section">
            <div className="icon"> <img src={image} alt="" /> </div>
            <div className="text-heading">
                {heading && <div className="heading">
                    <h4>{heading}</h4>
                </div>}
                <div className="text-section">
                    <p>{textSection}</p>
                </div>
            </div>
        </div>
    );
}

export const TeamCard = ({ image, heading, colorHeading, textSection }: props) => {
    return (
        <Card>
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3} >
                    <div className="image-section">
                        <img style={{ width: "230px", height: "260px" }} src={image} alt="" />
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={9} xl={9} >
                    <div className="heading">
                        <h3>{heading}</h3>
                    </div>
                    <div className="sub-heading-color">
                        <p style={{ color: "#49BAFF", marginTop: "-1rem" }}>{colorHeading}</p>
                    </div>
                    <hr style={{ width: "95%", paddingBottom: "0.5rem" }} />
                    <hr style={{ width: "95%" }} />
                    <div className="text-icon-section">
                        <IconText
                            image={Quote}
                            textSection={textSection} />
                    </div>
                </Grid>

            </Grid>
        </Card>
    );
}

export const CommonCard = ({ image, heading, textSection, button }: props) => {
    return (
        <Card>
            <div className='cards-container'>
                {image && <Grid container item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <div className="image-section">
                        <img style={{ width: "230px", height: "250px" }} src={image} alt="" />
                    </div>
                </Grid>}
                {heading && <Grid container item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <div className="heading">
                        <h3>{heading}</h3>
                    </div>
                </Grid>}
                <Grid container item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <div className="text-section">
                        <h4>{textSection}</h4>
                    </div>
                </Grid>
                <Grid container item xs={12} sm={12} md={2} lg={2} xl={2}>
                    {button && <div className="btn-container">
                        <div className='btn'>
                            <a href="https://hexaviewtech.com/contact/">Get In Touch</a>
                            <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                        </div>
                    </div>}
                </Grid>
            </div>
        </Card>
    );
}

export default WomenWelfareNetwork
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';

import Quote from '../../../../assets/home/quote.svg'
import Slider from "react-slick";
import "./style.scss";

type Props = {
    array?: any
}
const Index = ({ array }: Props) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <section className='our-clients-section Section'>
            <div className='container'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='heading-elements'>
                        <h4>Meet our clients</h4>
                        <h2 className='heading'>What our clients say</h2>
                    </div>
                </Grid>
            </div>
            <div className='container outerDiv'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Slider {...settings}>
                        {
                            array.map((item: any) => {
                                return (
                                    <div className='outerCard'>
                                        <Grid container className='element-inner-section'>
                                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                <div className='img'>
                                                    <img src={item.image} alt="" />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                <div className='content'>
                                                    <img src={Quote} alt="" />
                                                    <p>{item.text}</p>
                                                    <h3>{item.name}</h3>
                                                    <h5>{item.designation}</h5>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                            })

                        }

                        {/* <div className='outerCard'>
                        <Grid container className='element-inner-section'>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <div className='img'>
                                    <img src={Client} alt=""/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                <div className='content'>
                                    <img src={Quote} alt="" />
                                    <p>We find Hexaview’s team to be very transparent & responsive. They are open to solving any problem, however intricate and time-bound. With regular check-ins, they ensure that any feedback is acted upon promptly. Hexaview resources have always helped Adobe ship good quality products.</p>
                                    <h3>Larry Baker</h3>
                                    <h5>BaySys Technologies LLC</h5>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='outerCard'>
                        <Grid container className='element-inner-section'>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <div className='img'>
                                    <img src={Client} alt=""/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                <div className='content'>
                                    <img src={Quote} alt="" />
                                    <p>We find Hexaview’s team to be very transparent & responsive. They are open to solving any problem, however intricate and time-bound. With regular check-ins, they ensure that any feedback is acted upon promptly. Hexaview resources have always helped Adobe ship good quality products.</p>
                                    <h3>Larry Baker</h3>
                                    <h5>BaySys Technologies LLC</h5>
                                </div>
                            </Grid>
                        </Grid>
                    </div> */}
                    </Slider>
                </Grid>
            </div>
        </section>
    );
}

export default Index;
import { createStore, useStoreState, useStoreActions } from 'easy-peasy';
import { action } from 'easy-peasy';
import storeModel from '../model/storeModel';

// const store = createStore(persist(storeModel, { allow: ['prospectsFlow'] }));
const store = createStore({
  ...storeModel,
  reset: action((state, payload) => ({
    ...initialState,
  })),
});

export default store;

let initialState = {};

initialState = store.getState();

export { store };

export const appState = useStoreState;

export const appActions = useStoreActions;

// export default store;

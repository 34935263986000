import React from 'react'
import SalesForceBanner from '../../../../assets/salesforce-banner-1.png';
import SalesForcePartner from '../../../../assets/salesforce-Partner-Logo.png';
import PageTopSection from '../../../common-components/pageTop-section/PageTopSection';
import ContainerWrapper from '../../../utils/custom-tags/ContainerWrapper';
import PledgeOneLogo2 from '../../../../assets/PledgeOneLogo2.webp';
import { Card, Grid } from '@mui/material';
import './Salesforce.scss';
import Cards from '../../../common-components/card/Card';
import Settings from '../../../../assets/FinTech-wealth-management/capital-market/settings.svg';
import FlowerHands from '../../../../assets/FinTech-wealth-management/flowerHands.svg';
import Monitor from '../../../../assets/FinTech-wealth-management/capital-market/monitor.svg';
import Chat from '../../../../assets/FinTech-wealth-management/capital-market/chat.svg';
import Desktop from '../../../../assets/custom-software-services/Desktop.svg';
import Migration from '../../../../assets/svgexport-12.svg';
import Counter from '../../../common-components/counter-widget/Counter';
import { IconText } from '../../corporate-overview/corporate-social-responsibility/women-welfare-network/WomenWelfareNetwork';
import Development from '../../../../assets/salesforce/svgexport-19.svg';
import Consultation from '../../../../assets/salesforce/svgexport-20.svg';
import Platform from '../../../../assets/salesforce/svgexport-21.svg';
import Server from '../../../../assets/salesforce/svgexport-22.svg';
import Customization from '../../../../assets/salesforce/svgexport-23.svg';
import Integration from '../../../../assets/salesforce/svgexport-24.svg';
import Clients from '../../../pages/home/our_Clients/Index';
import Client from '../../../../assets/home/client.png'
import CaseStudy from '../../../common-components/caseStudy/Index'
import caseStudy1 from '../../../../assets/caseStudy/1.jpg'
import caseStudy2 from '../../../../assets/caseStudy/2.jpg'
import caseStudy3 from '../../../../assets/caseStudy/3.jpg'
import caseStudy4 from '../../../../assets/caseStudy/4.jpg'
import caseStudy5 from '../../../../assets/caseStudy/5.jpg'
const Salesforce = () => {

    const clients = [
        {
            image: Client,
            name: "Tyler Gilley",
            text: "Our experience working with Hexaview has extremely been poistive, especially as it pertains to their level of engagement and flexibility in accommodating our ever evolving needs as a company. We are treated with the utmost care, respect and our needs & concerns are always a top priority for the Hexaview team.",
            designation: "Halbert Hargrove"
        },
        {
            image: Client,
            name: "Tyler Gilley",
            text: "Our experience working with Hexaview has extremely been poistive, especially as it pertains to their level of engagement and flexibility in accommodating our ever evolving needs as a company. We are treated with the utmost care, respect and our needs & concerns are always a top priority for the Hexaview team.",
            designation: "Halbert Hargrove"
        },
        {
            image: Client,
            name: "Tyler Gilley",
            text: "Our experience working with Hexaview has extremely been poistive, especially as it pertains to their level of engagement and flexibility in accommodating our ever evolving needs as a company. We are treated with the utmost care, respect and our needs & concerns are always a top priority for the Hexaview team.",
            designation: "Halbert Hargrove"
        }
    ]
    const caseStudies = [
        {
          image: caseStudy1,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy2,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy3,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy4,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy5,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
      ]
    return (
        <div className="sf-main-container paddingTopSection">
            <PageTopSection
                img={SalesForceBanner}
                h1Heading={"PERSONALIZE"}
                colorH1Heading={"YOUR SALESFORCE"}
                textSectionA={"Bring value to your business by adopting AI technology that brings tangible ROI to your organization."} />

            <ContainerWrapper className="sf-section-B Section">
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                        <div className="image-section">
                            <img src={SalesForcePartner} alt="" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                        <Card className="sf-B-card">
                            <div>
                                <div className="heading">
                                    <h2>Salesforce Development Company</h2>
                                </div>
                                <div className="text-section">
                                    <p>Hexaview is a trusted Salesforce partner and one of the fastest-growing Salesforce development company helping ambitious start-ups to Fortune 500 companies get the best out of the world’s #1 CRM platform. Salesforce provides businesses the tools they need to make their processes streamlined within a single, secure platform that is extremely customizable scalable, and easy to set up. We, at Hexaview, ensure your Salesforce implementation meets all your business & customers’ requirements and drives results.</p>
                                </div>
                            </div>

                            <div className="image-section">
                                <img src={PledgeOneLogo2} alt="" />
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </ContainerWrapper>
            <div className="sf-section">
                <div className="sf-heading">
                    <h3>We Bring Companies and Customers Together with Salesforce Cloud Solutions</h3>
                    <h4>Through our Salesforce consulting services, we aim to support your operations, sales, marketing, and customer service needs. </h4>
                </div>
                <div className="text-section">
                    <p>As one of the top Salesforce development companies, we have a vibrant team of 200+ certified experts’ across various domains and industries, and expertise in delivering end-to-end Salesforce development services to our clients across the globe. With Salesforce specialists across the globe, Hexaview brings proven Salesforce development services experience, deep domain insight, and Salesforce know-how. Our teams have delivered some of the most innovative and complex projects. </p>
                    <p>Our Salesforce offerings include business & technology consulting services, custom Salesforce application development services, data migration services, Salesforce managed services, platform optimization, Salesforce maintenance and support, and more.</p>
                </div>
            </div>
            <ContainerWrapper className="sf-section-C Section">
                <div className="main-heading">
                    <h2>Our Salesforce Development Services</h2>
                </div>
                <Grid container spacing={4} className='card-container'>
                    <Cards id='card-c-3' headerIcon={<img src={Chat} />} contentHeading="Salesforce Consulting" contentText="Being a leading Salesforce development company, our Salesforce experts will recommend effective solutions to meet your business objectives. We will tailor our Salesforce development services to your requirements and deliver a custom solution for your specific needs."
                    />
                    <Cards id='card-c-3' headerIcon={<img src={Migration} />} contentHeading="Salesforce Data Migration" contentText="Migrate your data from legacy systems to a central platform like the Salesforce Suite. We will help you navigate through this upgrade seamlessly and with minimal disruption to your business including services like Object, Field, and Relationship Mapping."
                    />
                    <Cards id='card-c-3' headerIcon={<img src={Settings} />} contentHeading="Salesforce Services" contentText="We provide Salesforce managed services for custom reporting, implementing security features, and automating tedious tasks. We additionally offer business automation, administration & support with LMA & LMO."
                    />
                    <Cards id='card-c-4' headerIcon={<img src={Desktop} />} contentHeading="Salesforce Architecture" contentText="Build and boost your Salesforce platform! Our team of expert architects brings exceptional knowledge, familiarity with complex business processes, unique problem-solving capabilities, and intricate subtleties that will take your business to the next level."
                    />
                    <Cards id='card-c-4' headerIcon={<img src={Settings} />} contentHeading="Salesforce Development" contentText="As one of the leading Salesforce development companies, we bring more than a decade of experience building large enterprise products and cloud-based software products in different vertical markets. We leverage our expertise in Classic and Lightning development & migration, paired with custom Salesforce development on the Force.com platform."
                    />
                    <Cards id='card-c-4' headerIcon={<img src={FlowerHands} />} contentHeading="Salesforce Integration" contentText="Seamlessly integrate your Salesforce software with third parties. Our offerings include API Integration, third-party data migration, Computer-Telephony Integration (CTI), web to lead forms, and critical social deployment."
                    />
                </Grid>
                <Grid container spacing={4}>
                    <Cards
                        id='card-c-5'
                        headerIcon={<img src={Monitor} />}
                        contentHeading={"SFDC Customization"}
                        contentText={"We offer end-to-end development of Visualforce pages, Apex, & Lightning components and help you customize your existing Salesforce application to transform your Salesforce ecosystem."}
                        size={6} />
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className='card-c-6'>
                            <div className="heading">
                                <h3>Let’s Discuss your Project</h3>
                            </div>
                            <div className='talk-to-epert'>
                                <p style={{ color: '#43BAFF' }}>Talk to our Expert</p>
                                <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </ContainerWrapper>

            <ContainerWrapper className="sf-section-D Section">
                <Grid container className='counter-container' >
                    <Counter prefix='' suffix='%' number="50" title="YOY Growth" />
                    <Counter prefix='' suffix='+' number="50" title="Implementations" />
                    <Counter id='counter-right' prefix='' suffix='+' number="50,000" title="Hours of Development" />
                </Grid>
            </ContainerWrapper>

            <ContainerWrapper className="sf-section-E Section">
                <div className="main-heading">
                    <h2>Tech Stack</h2>
                    <div className="line"/>
                </div>
                
                <Grid container spacing={2} wrap='wrap' style={{ marginTop: "20px" }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="card-styling">
                            <IconText
                                image={Development}
                                heading={"DEVELOPMENT"}
                                textSection={"Apex Visualforce, HTML5, JavaScript, AJAX, Database.com, Batch Classes & Triggers, Code Coverage, Lightning Components, Apex & Aloha Apps, Managed & Unmanaged packages"} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="card-styling">
                            <IconText
                                image={Consultation}
                                heading={"CONSULTATION / ADMINISTRATION"}
                                textSection={"Salesforce Administration, Change control management, Project management"} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="card-styling">
                            <IconText
                                image={Platform}
                                heading={"PLATFORM"}
                                textSection={"Force.com IDE, Force.com Setup Menu, Force.com Data Loader, MuleSoft, Informatica, Dell Boomi"} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="card-styling">
                            <IconText
                                image={Server}
                                heading={"SERVERS & TOOLS"}
                                textSection={"Salesforce Objects, Custom Objects, Validation Rules Workflow, Approvals Reports & Dashboards Roles, Profiles, Groups, OWD, Sharing Rules, Apex Sharing Communities Sites, Forms Lightning Design System"} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="card-styling">
                            <IconText
                                image={Customization}
                                heading={"CUSTOMIZATION"}
                                textSection={"DE Orgs, Sandboxes, Production, SubVersion Control, Git, ClearCase, Force.com Migration Tool, Deployment Wizard, Batch Processing & Scheduling"} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="card-styling">
                            <IconText
                                image={Integration}
                                heading={"INTEGRATION"}
                                textSection={"Web Services API, Chatter API, Bulk Services API, Metadata API, External Databases, Data Warehousing, Reporting Services, Integration Tools (Jitterbit, MuleSoft, Talend, Dell Boomi, Zapier, etc.) WSO2 RESTful & SOAP API"} />
                        </Card>
                    </Grid>
                </Grid>
            </ContainerWrapper>

            <Clients array={clients}
            />
            <CaseStudy array={caseStudies} />

        </div>
    )
}

export default Salesforce
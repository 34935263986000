import React,{useEffect} from 'react'
import ContainerWrapper from '../../../utils/custom-tags/ContainerWrapper';
import '../copyright.scss';
import Flags from '../../../../assets/Footer Images/flags.png';
const TermsAndConditions = () => {

    useEffect(() => {
        return () => {
            restoreGoogleTranslator();
        };
    }, []);

    const restoreGoogleTranslator = ()=>{
        var iframe:any = document.getElementsByClassName('goog-te-banner-frame')[0];
            if(!iframe) return;
          
            var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
            var restore_el = innerDoc.getElementsByTagName("button");
          
            for(var i = 0; i < restore_el.length; i++){
              if(restore_el[i].id.indexOf("restore") >= 0) {
                restore_el[i].click();
                var close_el = innerDoc.getElementsByClassName("goog-close-link");
                close_el[0].click();
                return;
              }
            }
    }

    return (
        <div className='main-container'>
           <div className="main-heading">
                <div className="heading">
                    <h1>Terms and Conditions</h1>
                </div>
                <div className="google-translate">
                    <div id="google_translate_element"></div>
                </div>
            </div>
            <ContainerWrapper className="Section">
                <div className="section">
                    <div className="text-section">
                        <p>This agreement applies as between you, the User of this Web Site and Hexaview Technologies, Inc., the owner(s) of this Web Site.  Your agreement to comply with and be bound by these terms and conditions is deemed to occur upon your first use of the Web Site. If you do not agree to be bound by these terms and conditions, you should stop using the Web Site immediately.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Definitions and Interpretation</h4>
                    </div>
                    <div className="text-section">
                        <p>In this Agreement the following terms shall have the following meanings:</p>
                        <p> <span className="spans">“Content” </span>means any text, graphics, images, audio, video, software, data compilations and any other form of information capable of being stored in a computer that appears on or forms part of this Web Site;</p>
                        <p> <span className="spans">“Hexaview Technologies, Inc.”</span> means 7, Quaker Dr., East Brunswick, NJ, 08816, USA;</p>
                        <p> <span className="spans">“System”</span> means any online communications infrastructure that Hexaview Technologies, Inc. makes available through the Web Site either now or in the future. This includes, but is not limited to, web-based email, message boards, live chat facilities and email links;</p>
                        <p> <span className="spans">“User” / “Users”</span> means any third party that accesses the Web Site and is not employed by Hexaview Technologies, Inc. and acting in the course of their employment; and </p>
                        <p> <span className="spans">“Web Site”</span> means the website that you are currently using (www.hexaviewtech.com).</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>General</h4>
                    </div>
                    <div className="text-section">
                        <p>Hexaview Technologies, Inc. grants you a non-exclusive, non-transferable, limited permission to access and display the Web pages within this site as a customer or potential customer of Hexaview Technologies, Inc. provided you comply with these Terms of Use, and all copyright, trademark, and other proprietary notices remain intact. You may only use a crawler to crawl this Web site as permitted by this Web site’s robots.txt protocol, and Hexaview Technologies, Inc. may block any crawlers in its sole discretion. The use authorized under this agreement is non-commercial in nature (e.g., you may not sell the content you access on or through this Web site.) All other use of this site is prohibited.</p>
                        <p>Except for the limited permission in the preceding paragraph, Hexaview Technologies, Inc. does not grant you any express or implied rights or licenses under any patents, trademarks, copyrights, or other proprietary or intellectual property rights. You may not mirror any of the content from this site on another Web site or in any other media. Any software and other materials that are made available for downloading, access, or other use from this site with their own license terms will be governed by such terms, conditions, and notices. Your failure to comply with such terms or any of the terms on this site will result in automatic termination of any rights granted to you, without prior notice, and you must immediately destroy all copies of downloaded materials in your possession, custody or control.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading"><h4>Intellectual Property</h4></div>
                    <div className="text-section">
                        <p>All Content included on the Web Site, unless uploaded by Users, including, but not limited to, text, graphics, logos, icons, images, sound clips, video clips, data compilations, page layout, underlying code and software is the property of Hexaview Technologies, Inc., our affiliates or other relevant third parties.  By continuing to use the Web Site you acknowledge that such material is protected by applicable United States and International intellectual property and other relevant laws.</p>
                        <p>You may not reproduce, copy, distribute, store or in any other fashion re-use material from the Web Site unless otherwise indicated on the Web Site or unless given express written permission to do so by Hexaview Technologies, Inc..</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Links to Other Web Sites</h4>
                    </div>
                    <div className="text-section">
                        <p>This Web Site may contain links to other sites.  Unless expressly stated, these sites are not under the control of Hexaview Technologies, Inc. or that of our affiliates.  We assume no responsibility for the content of such web sites and disclaim liability for any and all forms of loss or damage arising out of the use of them.  The inclusion of a link to another site on this Web Site does not imply any endorsement of the sites themselves or of those in control of them.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Links to this Web Site</h4>
                    </div>
                    <div className="text-section">
                        <p>Those wishing to place a link to this Web Site on other sites may do so only to the home page of the site www.hexaviewtech.com without prior permission.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Privacy</h4>
                    </div>
                    <div className="text-section">
                        <p>For the purposes of applicable data protection legislation, Hexaview Technologies, Inc. will process any personal data you have provided to it in accordance Privacy Policy available on the Hexaview Technologies, Inc. website or on request from Hexaview Technologies, Inc..</p>
                        <p>You agree that, if you have provided Hexaview Technologies, Inc. with personal data relating to a third party (1) you have in place all necessary appropriate consents and notices to enable lawful transfer such personal data to Hexaview Technologies, Inc. and (2) that you have brought to the attention of any such third party the Privacy Notice available on the Hexaview Technologies, Inc.’s website or otherwise provided a copy of it to the third party. You agree to indemnify Hexaview Technologies, Inc. in relation to all and any liabilities, penalties, fines, awards or costs arising from your non-compliance with these requirements.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Rules of conduct</h4>
                    </div>

                    <div className="text-section">
                        <p>Your use of www.hexaviewtech.com is subject to all applicable local, state, national and international laws and regulations, and you agree not to violate such laws and regulations. Any attempt by any person to deliberately damage Hexaview Technologies, Inc. Websites is a violation of criminal and civil laws. Hexaview Technologies, Inc. reserves the right to seek damages from any such person to the fullest extent permitted by law.</p>
                        <p>You agree not to post or transmit through Hexaview Technologies, Inc. Websites any material or content that violates or infringes in any way the rights of others or solicits, encourages or promotes the use of illegal substances or activities, which is unlawful, threatening, abusive, harassing, defamatory, libelous, derogatory, invasive of privacy or publicity rights, vulgar, obscene, bigoted or hateful, profane, scandalous, pornographic, indecent or otherwise objectionable, gives rise to civil or criminal liability or otherwise violates any applicable law.</p>
                        <p>You may not impersonate another user, use another user’s Hexaview Technologies, Inc.id, permit someone else to use your Hexaview Technologies, Inc.id, attempt to capture or guess other users’ passwords, forge headers or otherwise manipulate identifiers for the purpose of disguising the origin of any content you transmit, conduct fraudulent business operations or practices, or promote or conceal unlawful conduct.</p>
                        <p>You may not engage in any activity on Hexaview Technologies, Inc. Websites which restrict or inhibit any other user from using or enjoying Hexaview Technologies, Inc. Websites by “hacking,” “cracking,” “spoofing,” or defacing any portions of Hexaview Technologies, Inc. Websites. You may not use Hexaview Technologies, Inc. Websites to stalk or harass another person or entity.</p>
                        <p>You may not post or transmit through Hexaview Technologies, Inc. Websites advertising or commercial solicitations; promotional materials relating to website or online services which are competitive with Hexaview Technologies, Inc. and/or Hexaview Technologies, Inc. Websites; software or other materials that contain viruses, worms, time bombs, Trojan horses, or other harmful or disruptive components, political campaign materials; chain letters; mass mailings, spam mail, any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, “data mine,” or in any way reproduce or circumvent the navigational structure or presentation of Hexaview Technologies, Inc. Websites or contents. You may not harvest or collect information about website visitors without their express written conse.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Confidential information</h4>
                    </div>
                    <div className="text-section">
                        <p>Hexaview Technologies, Inc. does not want to receive confidential or proprietary information from you through our Web site. Please note that any information or material sent to Hexaview Technologies, Inc. will be deemed NOT to be confidential. By sending Hexaview Technologies, Inc. any information or material, you grant Hexaview Technologies, Inc. an unrestricted, irrevocable license to copy, reproduce, publish, upload, post, transmit, distribute, publicly display, perform, modify, create derivative works from, and otherwise freely use, those materials or information. You also agree that Hexaview Technologies, Inc. is free to use any ideas, concepts, know-how, or techniques that you send us for any purpose. However, we will not release your name or otherwise publicize the fact that you submitted materials or other information to us unless: (a) we obtain your permission to use your name; or (b) we first notify you that the materials or other information you submit to a particular part of this site will be published or otherwise used with your name on it; or (c) we are required to do so by law. Personally-identifiable information that you submit to Hexaview Technologies, Inc. for the purpose of receiving products or services will be handled in accordance with our privacy policy.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Global availability</h4>
                    </div>
                    <div className="text-section">
                        <p>Information Hexaview Technologies, Inc. publishes on the Internet may contain references or cross references to Hexaview Technologies, Inc. products, programs and services that are not announced or available in your country. Such references do not imply that Hexaview Technologies, Inc. intends to announce or make available such products, programs, or services in your country. Please consult your local Hexaview Technologies, Inc. business contact for information regarding the products, programs, and services that may be available to you.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Business relationships</h4>
                    </div>
                    <div className="text-section">
                        <p>This Web site may provide links or references to non-Hexaview Technologies, Inc.Web sites and resources. Hexaview Technologies, Inc.makes no representations, warranties, or other commitments or endorsements whatsoever about any non-Hexaview Technologies, Inc.Web sites or third-party resources that may be referenced, accessible from, or linked to any Hexaview Technologies, Inc. site. In addition, Hexaview Technologies, Inc.is not a party to or responsible for any transactions you may enter into with third parties, even if you learn of such parties (or use a link to such parties) from an Hexaview Technologies, Inc.site. When you access a non-Hexaview Technologies, Inc.Web site, even one that may contain the Hexaview Technologies, Inc.-logo, please understand that it is independent from Hexaview Technologies, Inc., and that Hexaview Technologies, Inc.does not control the content on that Web site. It is up to you to take precautions to protect yourself from viruses, worms, Trojan horses, and other potentially destructive programs, and to protect your information.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Disclaimers</h4>
                    </div>
                    <div className="text-section">
                        <p>Hexaview Technologies, Inc. makes no warranty or representation that the Web Site will meet your requirements, that it will be of satisfactory quality, that it will be fit for a particular purpose, that it will not infringe the rights of third parties, that it will be compatible with all systems, that it will be secure and that all information provided will be accurate. We make no guarantee of any specific results from the use of our Service.</p>
                        <p>No part of this Web Site is intended to constitute advice and the Content of this Web Site should not be relied upon when making any decisions or taking any action of any kind.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Availability of the Web Site</h4>
                    </div>

                    <div className="text-section">
                        <p>The Service is provided “as is” and on an “as available” basis. We give no warranty that the Service will be free of defects and / or faults.  To the maximum extent permitted by the law we provide no warranties (express or implied) of fitness for a particular purpose, accuracy of information, compatibility and satisfactory quality.</p>
                        <p>Hexaview Technologies, Inc. accepts no liability for any disruption or non-availability of the Web Site resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, power failure, natural events, acts of war or legal restrictions and censorship.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Limitation of Liability</h4>
                    </div>

                    <div className="text-section">
                        <p>To the maximum extent permitted by law, Hexaview Technologies, Inc. accepts no liability for any direct or indirect loss or damage, foreseeable or otherwise, including any indirect, consequential, special or exemplary damages arising from the use of the Web Site or any information contained therein. Users should be aware that they use the Web Site and its Content at their own risk.</p>

                        <p>Nothing in these terms and conditions excludes or restricts Hexaview Technologies, Inc.’s liability for death or personal injury resulting from any negligence or fraud on the part of Hexaview Technologies, Inc..</p>

                        <p>Every effort has been made to ensure that these terms and conditions adhere strictly with the relevant legal provisions. However, in the event that any of these terms are found to be unlawful, invalid or otherwise unenforceable, that term is to be deemed severed from these terms and conditions and shall not affect the validity and enforceability of the remaining terms and conditions.  This term shall apply only within jurisdictions where a particular term is illegal.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>No Waiver</h4>
                    </div>
                    <div className="text-section">
                        <p>In the event that any party to these Terms and Conditions fails to exercise any right or remedy contained herein, this shall not be construed as a waiver of that right or remedy.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Previous Terms and Conditions</h4>
                    </div>
                    <div className="text-section">
                        <p>In the event of any conflict between these Terms and Conditions and any prior versions thereof, the provisions of these Terms and Conditions shall prevail unless it is expressly stated otherwise.</p>
                    </div>
                </div>
                <div className="section">
                    <div className="heading">
                        <h4>Notices</h4>
                    </div>
                    <div className="text-section">
                        <p>All notices / communications shall be given to us either by post to our Premises (see address above) or by email to hello@hexaviewtech.com. Such notice will be deemed received 3 days after posting if sent by first class post, the day of sending if the email is received in full on a business day and on the next business day if the email is sent on a weekend or public holiday.</p>
                    </div>
                </div>
                <div className="section">
                    <div className="heading">
                        <h4>Law and Jurisdiction</h4>
                    </div>
                    <div className="text-section">
                        <p>These terms and conditions and the relationship between you and Hexaview Technologies, Inc. shall be governed by and construed in accordance with the Law of New Jersey and Hexaview Technologies, Inc. and you agree to submit to the exclusive jurisdiction of the Courts of New Jersey.</p>
                    </div>
                </div>
            </ContainerWrapper>
        </div>
    )
}

export default TermsAndConditions
import React from 'react';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import CloudGetTouchBox from '../commonSection/cloudGetTouchBox/Index'
import CardWithLeftHeader from '../../../../common-components/card/CardWithLeftHeader';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import TrustBy from '../commonSection/trustBy/Index'
import "./style.scss";
import Traceable from '../../../../../assets/cloud/svgexport-15.svg';
import Home from '../../../../../assets/cloud/svgexport-16.svg';
import Optimization from '../../../../../assets/cloud/svgexport-17.svg';
import Sheet from '../../../../../assets/cloud/svgexport-21.svg'
import OwlCarousel from '../../../../common-components/owl-carousel/Index'

const Index = () => {

    return (
        <Grid container className='cloud-migration-solutions paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/cloud-banner.jpg"}
                h1Heading="Cloud Cost"
                colorH1Heading="Optimization Services"
                textSectionA={"Reduce your IT spending up to 50% while bringing a performance that your IT workloads demand."}
                isScheduleCall={true}
            />
            {/*...service text section...*/}
            <section className='cost-optimization-service-section-B Section'>
                <div className='container'>
                    <Grid container >
                        <Grid className="cost-optimization-service-section-left" item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <div className="heading">
                                <h2 >Reduce your cloud spend by<br />optimizing your cloud resources</h2>
                            </div>
                            <div className="text-section">
                                <div>
                                    <p>Cloud provides the organization with limitless scalability but with right cloud optimization strategy you can reduce IT cost by only charging for the resource you use Only pay for the cloud size you need – and not a penny more. Hexaview helps you realize savings with our cost optimization services:</p>
                                </div>
                                <div className="check-marks">
                                    <ul>
                                        <li><CheckIcon className='icon' />Resolve employees' general queries</li>
                                        <li><CheckIcon className='icon' />Guide employees through various company processes and policies</li>
                                        <li><CheckIcon className='icon' />Link to relevant documents</li>
                                        <li><CheckIcon className='icon' />Handle routine HR requests</li>
                                        <li><CheckIcon className='icon' />Can be customized as per the requirements for any industry</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='hexa-default-btn'>
                                <p>Get in Touch <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                            </div>
                        </Grid>
                        <Grid className="cost-optimization-service-section-right" item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <div className='bigBadge'>
                                <p>Rightsize your hybrid cloud spend</p>
                            </div>
                            <div className="text-section">
                                <h4>30%</h4>
                                <p>of firms will increase spending on cloud adoption, and 80% of all enterprise (and mission-critical) workloads will move to the cloud by 2025" - Forbes</p>
                            </div>
                            <div className="text-section">
                                <div>
                                    <h4>Save up to 50% on your cloud bill</h4>
                                    <p>by identifying gaps and redundancies in your cloud infrastructure.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className='cost-optimization-service-section-C Section'>
                <div className='container'>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <div className='optimizationSection'>
                                <Grid container>
                                    <Grid xs={12} md={4} lg={4} className='main-img'>
                                        <div className="img-container">
                                            <img src={"https://hexaviewtech.com/wp-content/uploads/2022/03/leading-US-based-software-testing-company.png"} style={{ width: "100%" }} />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} md={8} lg={8} className='main-content'>
                                        <p>Learn how Hexaview helped a leading US-based software testing company lower their AWS OpEx by 45% annually</p>
                                        <div className='hexa-default-btn'>
                                            <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className='optimizationSection'>
                                <Grid container>
                                    {/* <Grid xs={12} md={4} lg={4}>
                                        <img src={"https://hexaviewtech.com/wp-content/uploads/2022/03/leading-US-based-software-testing-company.png"} style={{ width: "100%" }} />
                                    </Grid> */}
                                    <Grid xs={12} md={8} lg={12} className='main-content'>
                                        <h4>Optimize your cloud costs with Hexaview Capacity Planner</h4>
                                        <div className='hexa-default-btn contact-container'>
                                            <p>Contact Us <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className='cost-optimization-service-section-D Section'>
                <div className='container'>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className='heading-wrapper'>
                                <h2>We will lower your cloud costs<br /><span>Significantly & Guaranteed</span></h2>
                                <h4>Gain visibility into cloud usage for your cloud environment.</h4>
                                <p>Hexaview's cloud cost optimization services and solutions empower you to monitor cloud usage continuously and gain insights to optimize your cloud spend. Our cloud optimization service delivers instant savings while helping you establish a long-term cost savings culture.</p>
                            </div>
                            <div className="service-wrapper-card">
                                <Grid container wrap='wrap' >
                                    <Grid item className="outer-card" xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CardWithLeftHeader
                                            headerImgUrl={Sheet}
                                            contentHeading="Access Guaranteed Savings"
                                            descriptionText={"Reduce cloud service costs by 50% or more"}
                                        />
                                    </Grid>
                                    <Grid item className="outer-card" xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CardWithLeftHeader
                                            headerImgUrl={Traceable}
                                            contentHeading="Accountability & Traceability"
                                            descriptionText={"Drive full transparency throughout the cloud workflow."}
                                        />
                                    </Grid>
                                    <Grid item className="outer-card" xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CardWithLeftHeader
                                            headerImgUrl={Home}
                                            contentHeading="Guided by Expertise"
                                            descriptionText={"Our cloud experts have developed innovative strategies to optimize cloud costs."}
                                        />
                                    </Grid>
                                    <Grid item className="outer-card" xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CardWithLeftHeader
                                            headerImgUrl={Optimization}
                                            contentHeading="Resource Optimization"
                                            descriptionText={"Relieve your high-demand cloud staff from complex cost management tasks."}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className='cost-optimization-service-section-E Section'>
                <div className='container'>
                    <Grid container >
                        <Grid className="cost-optimization-service-section-left" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="heading">
                                <h2>Gain complete control<br /> over <span>multi-cloud costs</span></h2>
                            </div>
                            <div className="text-section">
                                <div>
                                    <p>Streamline cloud cost optimization and management across different platforms and work collaboratively with multiple teams to report, manage, and optimize cloud spending. Leverage our cloud cost optimization services to explore the cloud cost optimization opportunities.</p>
                                </div>
                            </div>
                            <div className='hexa-default-btn'>
                                <p>Get in Touch <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                            </div>
                        </Grid>
                        <Grid className="cost-optimization-service-section-right" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <img src={"https://hexaviewtech.com/wp-content/uploads/2022/03/multi-cloud.svg"} style={{ width: "100%" }} />
                        </Grid>
                    </Grid>
                </div>

            </section>
            <section className='cost-optimization-service-section-F Section'>

                <div className='carousel-container'>
                    < OwlCarousel />
                </div>
            </section>

            <section className='cost-optimization-service-section-G Section'>

                <div className='container'>
                    <CloudGetTouchBox
                    h2Heading="Get in touch with us"
                    textSection="Contact our experts to receive the best AWS and cloud cost optimization solution for your organization."
                    buttonText="Get in Touch"
                    />                
                </div>
            </section>
            
        </Grid>
    );
}

export default Index;
import React from 'react';
import {useNavigate, NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Arrow from '../../../../assets/header/arrow.svg'
import Cloud from '../../../../assets/header/cloud.svg'
import DataScience from '../../../../assets/header/dataScience.svg'
import Fintech from '../../../../assets/header/fintech.svg'
import Gptw from '../../../../assets/header/GPTW.png'
import Plateform from '../../../../assets/header/platform.png'
import Salesforce from '../../../../assets/header/salesforce.svg'
import Software from '../../../../assets/header/software.svg'
import './style.scss';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const Index = ()=> {

    const [value, setValue] = React.useState(0);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
    


    const mainMenu = (img:any, heading:any, subHeading:any) => <div className='menuDiv'>
        <div className='imgSection'>
            <img src={img} alt="" />
        </div>
        <div className='content'>
            <h5>{heading}</h5>
            <p>{subHeading}</p>
        </div>
        <div className='arrow'>
            <img src={Arrow} alt="" />
        </div>
    </div>




    return (
        <div className='submenu'>
        <Grid container>
            <Grid item xs={12} md={12} lg={4}>
                <div className='home-section-inner-left'>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label={mainMenu(Fintech, "About", "Providing Seamless data flow")} className='customTab' {...a11yProps(0)} />
                            <Tab label={mainMenu(Salesforce, "Tech Stack", "Providing Seamless data flow")} className='customTab' {...a11yProps(1)} />
                            <Tab label={mainMenu(Software, "Careers", "Providing Seamless data flow")} className='customTab' {...a11yProps(2)} />
                            <Tab label={mainMenu(Cloud, "Security", "Providing Seamless data flow")} className='customTab' {...a11yProps(3)} />
                            <Tab label={mainMenu(DataScience, "Corporate social Responsibility", "Providing Seamless data flow")} className='customTab' {...a11yProps(4)} />
                        </Tabs>
                    </Box>
                </div>
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
                <div className='home-section-inner-right'>
                    <TabPanel value={value} index={0}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='middlePart'>
                                <ul>
                                    <li><NavLink to="/corporate-overview/About">About</NavLink></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='rightPart'>
                                <h5>FEATURED READS</h5>
                                <img src={Plateform} alt="" />
                                <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
                                <div className='hexa-default-btn'>
                                    <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='middlePart'>
                                <ul>
                                    <li><NavLink to="/corporate-overview/Tech_Stack">Tech Stack</NavLink></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='rightPart'>
                                <h5>FEATURED READS</h5>
                                <img src={Plateform} alt="" />
                                <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
                                <div className='hexa-default-btn'>
                                    <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='middlePart'>
                                <ul>
                                    <li><NavLink to="/corporate-overview/Careers">Careers</NavLink></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='rightPart'>
                                <h5>FEATURED READS</h5>
                                <img src={Plateform} alt="" />
                                <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
                                <div className='hexa-default-btn'>
                                    <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='middlePart'>
                                <ul>
                                    <li><NavLink to="/corporate-overview/Security">Security</NavLink></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='rightPart'>
                                <h5>FEATURED READS</h5>
                                <img src={Plateform} alt="" />
                                <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
                                <div className='hexa-default-btn'>
                                    <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='middlePart'>
                                <ul>
                                    <li><NavLink to="/corporate-overview/csr">Corporate social Responsibility</NavLink></li>
                                    <li><NavLink to="/corporate-overview/csr/di">Diversity and Inclusion</NavLink></li>
                                    <li><NavLink to="/corporate-overview/csr/wwn">Women Welfare Network</NavLink></li>
                                    <li><NavLink to="/corporate-overview/csr/si">Social Impact</NavLink></li>
                                    <li><NavLink to="/corporate-overview/csr/sustainability">Sustainability</NavLink></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='rightPart'>
                                <h5>FEATURED READS</h5>
                                <img src={Plateform} alt="" />
                                <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
                                <div className='hexa-default-btn'>
                                    <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                    </TabPanel>
                </div>
            </Grid>
        </Grid>
        </div>
        // <div className='submenu'>
        // <Grid container>
        //     <Grid item xs={12} md={12} lg={4}>
        //         <div className='home-section-inner-left'>
        //             <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        //                 <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        //                     <Tab label={mainMenu(Fintech, "About", "Providing Seamless data flow")} className='customTab' {...a11yProps(0)} />
        //                     <Tab label={mainMenu(Salesforce, "Tech Stack", "Providing Seamless data flow")} className='customTab' {...a11yProps(1)} />
        //                     <Tab label={mainMenu(Software, "Careers", "Providing Seamless data flow")} className='customTab' {...a11yProps(2)} />
        //                     <Tab label={mainMenu(Cloud, "Security", "Providing Seamless data flow")} className='customTab' {...a11yProps(3)} />
        //                     <Tab label={mainMenu(DataScience, "Corporate social Responsibility", "Providing Seamless data flow")} className='customTab' {...a11yProps(4)} />
        //                 </Tabs>
        //             </Box>
        //         </div>
        //     </Grid>
        //     <Grid item xs={12} md={12} lg={8}>
        //         <div className='home-section-inner-right'>
        //             <TabPanel value={value} index={0}>
        //             <Grid container>
        //                 <Grid item xs={12} md={6} lg={6}>
        //                     <div className='middlePart'>
        //                         <ul>
        //                             <li><NavLink to="">About</NavLink></li>
        //                         </ul>
        //                     </div>
        //                 </Grid>
        //                 <Grid item xs={12} md={6} lg={6}>
        //                     <div className='rightPart'>
        //                         <h5>FEATURED READS</h5>
        //                         <img src={Plateform} alt="" />
        //                         <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
        //                         <div className='hexa-default-btn'>
        //                             <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
        //                         </div>
        //                     </div>
        //                 </Grid>
        //                 </Grid>
        //             </TabPanel>
        //             <TabPanel value={value} index={1}>
        //             <Grid container>
        //                 <Grid item xs={12} md={6} lg={6}>
        //                     <div className='middlePart'>
        //                         <ul>
        //                             <li><NavLink to="">Tech Stack</NavLink></li>
        //                         </ul>
        //                     </div>
        //                 </Grid>
        //                 <Grid item xs={12} md={6} lg={6}>
        //                     <div className='rightPart'>
        //                         <h5>FEATURED READS</h5>
        //                         <img src={Plateform} alt="" />
        //                         <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
        //                         <div className='hexa-default-btn'>
        //                             <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
        //                         </div>
        //                     </div>
        //                 </Grid>
        //                 </Grid>
        //             </TabPanel>
        //             <TabPanel value={value} index={2}>
        //             <Grid container>
        //                 <Grid item xs={12} md={6} lg={6}>
        //                     <div className='middlePart'>
        //                         <ul>
        //                             <li><NavLink to="">Careers</NavLink></li>
        //                         </ul>
        //                     </div>
        //                 </Grid>
        //                 <Grid item xs={12} md={6} lg={6}>
        //                     <div className='rightPart'>
        //                         <h5>FEATURED READS</h5>
        //                         <img src={Plateform} alt="" />
        //                         <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
        //                         <div className='hexa-default-btn'>
        //                             <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
        //                         </div>
        //                     </div>
        //                 </Grid>
        //                 </Grid>
        //             </TabPanel>
        //             <TabPanel value={value} index={3}>
        //             <Grid container>
        //                 <Grid item xs={12} md={6} lg={6}>
        //                     <div className='middlePart'>
        //                         <ul>
        //                             <li><NavLink to="">Security</NavLink></li>
        //                         </ul>
        //                     </div>
        //                 </Grid>
        //                 <Grid item xs={12} md={6} lg={6}>
        //                     <div className='rightPart'>
        //                         <h5>FEATURED READS</h5>
        //                         <img src={Plateform} alt="" />
        //                         <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
        //                         <div className='hexa-default-btn'>
        //                             <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
        //                         </div>
        //                     </div>
        //                 </Grid>
        //                 </Grid>
        //             </TabPanel>
        //             <TabPanel value={value} index={4}>
        //                 <Grid container>
        //                 <Grid item xs={12} md={6} lg={6}>
        //                     <div className='middlePart'>
        //                         <ul>
        //                             <li><NavLink to="">Corporate social Responsibility</NavLink></li>
        //                             <li><NavLink to="">Diversity and Inclusion</NavLink></li>
        //                             <li><NavLink to="">Women Welfare Network</NavLink></li>
        //                             <li><NavLink to="">Social Impact</NavLink></li>
        //                             <li><NavLink to="">Sustainability</NavLink></li>
        //                         </ul>
        //                     </div>
        //                 </Grid>
        //                 <Grid item xs={12} md={6} lg={6}>
        //                     <div className='rightPart'>
        //                         <h5>FEATURED READS</h5>
        //                         <img src={Plateform} alt="" />
        //                         <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
        //                         <div className='hexa-default-btn'>
        //                             <p>Read More <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
        //                         </div>
        //                     </div>
        //                 </Grid>
        //                 </Grid>
        //             </TabPanel>
        //         </div>
        //     </Grid>
        // </Grid>
        // </div>
    );
}

export default Index;
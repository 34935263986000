import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import SocialImpact from '../pages/corporate-overview/corporate-social-responsibility/social-impact/SocialImpact';
import Sustain from '../pages/corporate-overview/corporate-social-responsibility/Sustainability/Sustain';
import WomenWelfareNetwork from '../pages/corporate-overview/corporate-social-responsibility/women-welfare-network/WomenWelfareNetwork';
import Home from '../pages/home/Index';
import Cloud from '../pages/services/cloud/Cloud';
import AiMachineLearningServices from '../pages/services/data-science/ai-machine-learning-services/AiMachineLearningServices';
import CloudMigrationSolution from '../pages/services/cloud/cloudMigrationSolutions/Index';
import CloudCostOptimizationServices from '../pages/services/cloud/cloudCostOptimizationServices/Index'
import CloudGovernanceSecurityServices from '../pages/services/cloud/cloudGovernanceSecurityServices/Index'
import ApplicationDevelopmentDeploymentServices from '../pages/services/cloud/ApplicationDevelopmentDeploymentServices/Index'
import HybridCloudComputingSolutions from '../pages/services/cloud/HybridCloudComputingSolutions/Index'
import DevopsConsultingServices from '../pages/services/cloud/DevopsConsultingServices/Index'
import DataScience from '../pages/services/data-science/DataScience';
import BusinessIntelligenceConsultingServices from '../pages/services/data-science/business-ic-services/BusinessIntelligenceConsultingServices';
import BigDataServices from '../pages/services/data-science/big-data-services/BigDataServices';
import DataVisualizationServices from '../pages/services/data-science/data-visualization-services/DataVisualizationServices';
import DataEMServices from '../pages/services/data-science/data-em-services/DataEMServices';
import RPAServices from '../pages/services/data-science/rpa-consulting-services/RPAServices';
import DiversityInclusion from '../pages/corporate-overview/corporate-social-responsibility/diversity-and-inclusion/DiversityInclusion';
import TechStack from '../pages/corporate-overview/TechStack/TechStack';
import Security from '../pages/corporate-overview/Security/Security';
import Careers from '../pages/corporate-overview/Careers/Careers';
import PdfScrapper from '../pages/products/pdf-scrapper/PdfScrapper';
import Contact from '../common-components/contact/Contact';
import WealthManagement from '../pages/services/FinTech/wealth-management/WealthManagement';
import VideoStatements from '../pages/products/video-statements/VideoStatements';
import CapitalMarket from '../pages/services/FinTech/capital-market/CapitalMarket';
import Lending from '../pages/services/FinTech/Lending/Lending';
import DemandSolution from '../pages/services/FinTech/OnDemandSolution/DemandSolution';
import GlobalTechSeries from '../pages/resources/globalTechSeries/Index';
import Alhexa from '../pages/products/alhexa-chatbot/Alhexa';
import PressRelease from '../pages/resources/press-release/PressRelease';
import PrivacyPolicy from '../pages/copyrights/privacy-policy/PrivacyPolicy';
import CookiePolicy from '../pages/copyrights/cookie-policy/CookiePolicy';
import TermsAndConditions from '../pages/copyrights/terms-conditions/TermsAndConditions';
import LegalNotice from '../pages/copyrights/legal-notice/LegalNotice';
import CustomSoftwareServices from '../pages/services/custom-software-services/CustomSoftwareServices';
import Salesforce from '../pages/services/salesforce/Salesforce';

const RouterFlow = () => {
    const base_dataScience_url = '/services/data-science';
    return (
        <Routes>
            <Route path="/home" element={<Home />} />
            {/* Contact */}
            <Route path="/contact" element={<Contact />} />
            {/* cloud  */}
            <Route path="/services/cloud" element={<Cloud />} />
            <Route path="/services/cloud/cloud-migration-solutions" element={<CloudMigrationSolution />} />
            <Route path="/services/cloud/cloud-cost-optimization-services" element={<CloudCostOptimizationServices />} />
            <Route path="/services/cloud/cloud-governance-security-services" element={<CloudGovernanceSecurityServices />} />
            <Route path="/services/cloud/cloud-application-development-and-deployment-services" element={<ApplicationDevelopmentDeploymentServices />} />
            <Route path="/services/cloud/hybrid-cloud-computing-solutions" element={<HybridCloudComputingSolutions />} />
            <Route path="/services/cloud/devops-consulting-services" element={<DevopsConsultingServices />} />
            {/* Data Science */}
            <Route path="/services/data-science" element={<DataScience />} />
            <Route path={`${base_dataScience_url}/ai-machine-learning-development-services`} element={<AiMachineLearningServices />} />
            <Route path={`${base_dataScience_url}/business-intelligence-consulting-services`} element={<BusinessIntelligenceConsultingServices />} />
            <Route path={`${base_dataScience_url}/big-data-services`} element={<BigDataServices />} />
            <Route path={`${base_dataScience_url}/data-visualization-and-analytics-services`} element={<DataVisualizationServices />} />
            <Route path={`${base_dataScience_url}/data-engineering-data-mining-services`} element={<DataEMServices />} />
            <Route path={`${base_dataScience_url}/rpa-consulting-development-services`} element={<RPAServices />} />

            {/* FinTech */}
            <Route path='/services/fintech/wealth-management' element={<WealthManagement />} />
            <Route path="/services/fintech/capital-market" element={<CapitalMarket />} />
            <Route path="/services/fintech/lending" element={<Lending />} />
            <Route path="/services/fintech/on-demand-solutions" element={<DemandSolution />} />
            <Route path='/services/custom-software-services' element={<CustomSoftwareServices />} />
            <Route path="/services/Salesforce" element={<Salesforce />} />

            {/* coperate overview  */}
            <Route path="/corporate-overview/csr/sustainability" element={<Sustain />} />
            <Route path="/corporate-overview/csr/wwn" element={<WomenWelfareNetwork />} />
            <Route path="/corporate-overview/csr/si" element={<SocialImpact />} />
            <Route path="/corporate-overview/csr/di" element={<DiversityInclusion />} />
            <Route path="/corporate-overview/Tech_Stack" element={<TechStack />} />
            <Route path="/corporate-overview/Security" element={<Security />} />
            <Route path="/corporate-overview/Careers" element={<Careers />} />

            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="*" element={<TempPage />} />

            {/* Products */}
            <Route path="/products/pdf-scrapper" element={<PdfScrapper />} />
            <Route path="/products/video-statements" element={<VideoStatements />} />
            <Route path="/products/alhexa-hr-chatbot" element={<Alhexa />} />

            {/* Resources */}
            <Route path="/resources/global-tech-series" element={<GlobalTechSeries />} />
            <Route path="/Resources/Press_Release" element={<PressRelease />} />

            {/* Footer Pages (Privacy Policy) */}
            <Route path="/privacy-policies" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/legal-notice" element={<LegalNotice />} />

        </Routes>
    );
}
export const TempPage = () => {
    return (
        <h2 style={{ color: '#02174E', padding: '5rem', textAlign: 'center' }}>Comming Soon...</h2>
    )
}
export default RouterFlow;

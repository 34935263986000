import React from 'react';
import Grid from '@mui/material/Grid';
import cloudGetIn from '../../../../../../assets/cloud/cloud-get-in.svg'
import "./style.scss";
type Props = {
    h2Heading?:String,
    textSection?:String,
    buttonText?:String
}
const Index = ({
    h2Heading,
    textSection,
    buttonText
    }: Props) => {
    return (
        <section className='section-last Section'>
          <div className='container'>
             <Grid container className='inn-Container'>
                <Grid md={4}>
                    <div className='getTouchBox'>
                        <div className='element-image-text-wrapper'>
                            <h2 className='heading-text'>{h2Heading}</h2>
                            <div className='text-section'>{textSection}</div>
                            <div className='hexa-default-btn'>
                            <p>{buttonText} <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                        </div>
                        </div>
                    </div>
                </Grid>
                <Grid md={8}>
                    <div className='CloudgetImg'>
                        <img src={`${cloudGetIn}`} alt=""/>
                    </div>
                </Grid>
             </Grid>
          </div>
        </section>
    );
}

export default Index;
import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Button } from "antd";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import "./PdfScrapper.scss";
import Cards from '../../../common-components/card/Card';
import CardChip from '../../../common-components/card/CardChip';
import Better_Customer from "../../../../assets/Better-Customer.svg"
const PdfScrapper = () => {

    const gotoSection = (e:any) => {
        e.preventDefault();
        const element = document.getElementById('details');
        element && element.scrollIntoView({
            block: 'start',
            behavior: 'smooth' // smooth scroll
        })
  };
    return (
        <Grid container className='pdf-scrapper-main-container paddingTopSection' style={{ overflow: 'auto' }}>
            <section className='section-A'
                style={{
                    backgroundImage: `url(https://hexaviewtech.com/wp-content/uploads/2020/12/1.svg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}
            >
                <div className='container'>
                <div className='image-elements-section'>
                    <div className='element-image-text-wrapper'>
                        <h3 className='heading-text'>Accurate and Efficient Data Extraction</h3>
                        <div className='text-section-b'>A comprehensive package to enhance your data activities' precision and save on valuable working hours.</div>
                        <div className='learn-more'>
                            <a href='' onClick={gotoSection}>
                            <Button type='primary' className='learn-more-btn'>Learn More</Button>
                            </a>
                            {/* <h5 style={{ color: '#43BAFF' }}>Learn More</h5> */}
                        </div>
                    </div>
                    <div className='empty-divider' style={{ width: '100px' }}></div>
                    <div className='image-element-section'>
                        <img width='100%' src='https://hexaviewtech.com/wp-content/uploads/2020/12/PDF-Extractor.png' />
                    </div>
                </div>
                </div>
            </section>
            <section className='pdf-scrapper-section-B Section'>
                <div className='container'>
                    <Grid container className='element-inner-section'>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className="heading-wrapper"><h2>Challenges Related to PDF Scrapper</h2></div>
                        </Grid>
                        <Grid container wrap='wrap' item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Cards contentHeading={"Are you encountering errors in data extraction?"} id="first-ch" />
                            <Cards contentHeading={"Struggling to assess the crucial assets of your enterprise?"} />
                            <Cards contentHeading={"Questioning your resource management when you allot precious working hours to data extraction?"} id="last-ch" />
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className='pdf-scrapper-section-C Section' id="details">
                <div className='img-overlay'></div>
                <div className='container'>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="heading-wrapper">
                                <h2>Key Features of PDF Scraping Tools</h2>
                                <div className='border-wrapper'>
                                    <div className='border'></div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="text-elements-wrapper">
                                <div className="text-setion">
                                    <CheckCircleOutlineRoundedIcon fontSize='small' className='icon' />
                                    <span><h4>Time-Saving</h4>
                                        <p>Save those vital hours wasted on data entry!</p>
                                    </span>
                                </div>
                                <div className="text-setion">
                                    <CheckCircleOutlineRoundedIcon fontSize='small' className='icon' />
                                    <span>
                                        <h4>Better Accuracy</h4>
                                        <p>Higher automation + lower manual intervention = greater accuracy!</p>
                                    </span>
                                </div>
                                <div className="text-setion">
                                    <CheckCircleOutlineRoundedIcon fontSize='small' className='icon' />
                                    <span>
                                        <h4>Enriched Customization in Data Extraction</h4>
                                        <p>Drawing out custom data saves time and gives you a well-organized data bank.</p>
                                    </span>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className='pdf-scrapper-section-D Section'>
                {/* <div className='img-overlay'></div> */}
                <div className='container'>
                    <div className="heading-wrapper">
                        <h2>Automated PDF Extraction Software Solutions</h2>
                    </div>
                    <Grid container wrap='wrap' justifyContent={'space-around'}>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Card className='element-container'>
                                <CardContent className='card-content'>
                                    <Typography className='card-content-heading' gutterBottom variant="h5" component="div">
                                        Imbibing Automation & Enhancing Experience
                                    </Typography>
                                    <Typography className='card-content-description'>
                                        <p>PDF extractor comes with an automation function and other add-ons to give you a better platform and enhance your data entry experience.</p>
                                    </Typography>
                                    <Typography variant="body2">
                                        <ul>
                                            <li><CheckRoundedIcon className='icon' fontSize='small' />Eliminate error-prone manual data entry</li>
                                            <li><CheckRoundedIcon className='icon' fontSize='small' />Save up to 60% of your time</li>
                                            <li><CheckRoundedIcon className='icon' fontSize='small' />Reduce your overall cost</li>
                                            <li><CheckRoundedIcon className='icon' fontSize='small' />ptimize your usable working hours</li>
                                            <li><CheckRoundedIcon className='icon' fontSize='small' />Utilize an improved UI & UX experience for interactive working</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Card className='element-container'>
                                <CardContent className='card-content'>
                                    <Typography className='card-content-heading' gutterBottom variant="h5" component="div">
                                        Adding More Flexibility & Features
                                    </Typography>
                                    <Typography className='card-content-description'>
                                        <p>PDF data extraction software amplifies your data entry experience to help your business attain those long-sought milestones.</p>
                                    </Typography>
                                    <Typography variant="body2">
                                        <ul>
                                            <li><CheckRoundedIcon className='icon' fontSize='small' />Extract data from an editable PDF form</li>
                                            <li><CheckRoundedIcon className='icon' fontSize='small' />Extract specific data fields and dispatch the parsed data in real-time</li>
                                            <li><CheckRoundedIcon className='icon' fontSize='small' />Define specific locations inside the document with a point-and-click system</li>
                                            <li><CheckRoundedIcon className='icon' fontSize='small' />Retrieve PDFs from an email message, an FTP site, or a folder</li>
                                            <li><CheckRoundedIcon className='icon' fontSize='small' />Save the defined location as a template for future use</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className='pdf-scrapper-section-E Section'>
            <div className='img-overlay'></div>
                <div className='container'>
                    <div className="heading-wrapper">
                        <h2>Designed with standout tools</h2>
                        <p>We have formulated a PDF data extractor with leading technologies that give you nothing less than the best.</p>
                    </div>
                    <div className="cards-wrapper">
                        <CardChip img={Better_Customer} descriptionText={"Optical Character Recognition (OCR)"} />
                        <CardChip img={Better_Customer} descriptionText={"Machine Learning"} />
                        <CardChip img={Better_Customer} descriptionText={"Python Libraries"} />
                        <CardChip img={Better_Customer} descriptionText={"Amazon Text Extractor"} />
                        <CardChip img={Better_Customer} descriptionText={"Cloud Storage"} />
                    </div>
                </div>
            </section>
            <section className="btn-container Section">
                <Button className='btn' type='primary'>REQUEST A DEMO</Button>
            </section>
        </Grid>
    );
}

export default PdfScrapper;
import React from 'react';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
type Props = {
    headerIcon?: String | React.ReactNode,
    headerImgUrl?: string,
    contentHeading?: String,
    descriptionText?: String,
    isDescriptionInListView?: Boolean,
    liItems?: any,
    id?:string
}
const CardWithLeftHeader = ({ headerIcon, headerImgUrl, contentHeading,
    descriptionText, isDescriptionInListView, liItems,id }: Props) => {
    return (
        <div className="card-withLeft-header-wrapper-element card-container" id={id}>
            <Grid container className='card'>
                <div className='icon-wrapper'>
                {headerIcon &&(<CardHeader avatar={headerIcon} />)}
                {headerImgUrl &&(<div className='img-container'>
                    <img width="100%" src={headerImgUrl}/>
                </div>)}
                </div> 
                <Grid className='content-wrapper' item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <div className='card-content'>
                        <div className='card-content-heading'>
                            <h4>{contentHeading}</h4>
                        </div>
                        {descriptionText && (<div>
                            <p>
                                {descriptionText}
                            </p>
                        </div>)}
                        {isDescriptionInListView && (
                            <ul>
                                {liItems.map((item: any) => {
                                    return (
                                        <li>{item}</li>
                                    )
                                })}
                            </ul>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default CardWithLeftHeader;
import React from 'react';
import Grid from '@mui/material/Grid';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import RecentResources from '../common/RecentResources';
import "./BusinessIntelligenceConsultingServices.scss";
import { minHeight } from '@mui/system';
import CardChip from '../../../../common-components/card/CardChip';
import cloudAWS from '../../../../../assets/cloud/cloud-AWS.webp'
import cloudGcp from '../../../../../assets/cloud/cloud-gcp.webp'
import cloudMicrosoftAzure from '../../../../../assets/cloud/cloud-microsoft_azure.webp'
import IconBox from '../common/IconBox';
const BusinessIntelligenceConsultingServices = () => {
    const techArr = [
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/hortonworks.png'
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/informatica.png'
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/jaspersoft.png'
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/teradata.png'
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/powerbi.png'
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/kafka.png'
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/storm.png'
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/pentaho.png'
        },
    ]
    return (
        <Grid container className='bic-main-container paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/Business-Intelligence-banner.jpg"}
                h1Heading={"Business Intelligence Consulting Services"}
                textSectionA={"Empower your company’s growth by investing in business intelligence services with Hexaview, one of the top business Intelligence solution providers."}
                isScheduleCall={true}
            />
            <section className="bic-section-B Section">
                <div className='container'>
                    <Grid container wrap='wrap'>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="text-wrapper">
                                <h2>Business Intelligence Consulting</h2>
                                <div className="text-sextion">
                                <p>
                                    Business Intelligence services and systems are the foundation of every organization helping them to make informed decisions, educated estimates, and develop strategies – critical choices made by the people running your company.
                                </p>
                            </div>
                            <div className="text-sextion">
                                <p>
                                    Business Intelligence services and systems are the foundation of every organization helping them to make informed decisions, educated estimates, and develop strategies – critical choices made by the people running your company.
                                </p>
                            </div>
                            </div>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} style={{ height: '100%' }}>
                            <div className="image-wrapper">
                                <div>
                                    <img width={'100%'} height={'100%'} src='https://hexaviewtech.com/wp-content/uploads/2022/04/Business-Intelligence-Consulting.svg' alt='' />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="bic-section-C Section">
                <div className='container'>
                    <div className="heading element-widget-wrap">
                        <h2>Business Intelligence Technologies</h2>
                    </div>
                    <div className="tech-wrapper">
                        {
                            techArr.map(item => {
                                return (
                                    <CardChip
                                        img={item.img}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section className="bic-section-D Section">
                <div className='container'>
                    <div className="heading element-widget-wrap">
                        <h2>Business Intelligence Technologies <br /><span>Analytics Platforms We Work With</span></h2>
                        <p>We, as a business intelligence consulting firm works with the following platforms</p>
                    </div>
                    <div className="service-partner">
                        <Grid container item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <div className='trustImg'>
                                    <img src={cloudGcp} alt="" />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <div className='trustImg'>
                                    <img src={cloudMicrosoftAzure} alt="" />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <div className='trustImg'>
                                    <img src={cloudAWS} alt="" />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            <section className="bic-section-E Section">
               <div className='container'>
                    <Grid container className='element-inner-section'>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className="heading element-widget-wrap">
                                <h3>The challenges We Help You Resolve Through Our Business<span>Intelligence Consulting Services</span></h3>
                            </div>
                            <div className='hexa-primary-btn'>
                                <p>Talk to our Expert <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="element-icon-wrapper">
                            <div className='boxes'>
                            <IconBox description={"Maximize customer satisfaction"}/>
                            <IconBox description={"Inaccurate and static reports slow you down."}/>
                            <IconBox description={"Create valuable data visualization solutions"}/>
                            </div>
                            <div className='boxes'>
                            <IconBox description={"Supports your IT team."}/>
                            <IconBox description={"Streamline mismatched data from different sources into a single unified view."}/>
                            </div>
                            </div>
                        </Grid>
                    </Grid>
               </div>
            </section>
            <section className="bic-section-F Section">
               <div className='container'>
                    <Grid container className='element-inner-section'>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className="heading element-widget-wrap">
                            <h2>Want to know more how BI as a Service can help your organization?</h2>
                            <h4>Reach out to our expert data analytics consultants to discuss how Hexaview can make your data work harder for you.</h4>
                        </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div className='hexa-primary-btn'>
                                <p>Book your free consultation here <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                            </div>
                        </Grid>
                    </Grid>
               </div>
            </section>
            <RecentResources />
        </Grid>
    );
}

export default BusinessIntelligenceConsultingServices;
import React from 'react';
import Grid from '@mui/material/Grid';
import { Button, Checkbox, Form, Input } from 'antd';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PageTopSection from '../pageTop-section/PageTopSection';
import SecondaryTopPageSection from '../pageTop-section/SecondaryTopPageSection';
import "./Contact.scss";
import ContactCards from './ContactCards';
import ContainerWrapper from '../../utils/custom-tags/ContainerWrapper';
const Contact = () => {

    const { TextArea } = Input;

    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const cardArr = [
        {
            headerImgUrl: 'https://hexaviewtech.com/wp-content/uploads/2021/01/Manhattan-Skyline.png',
            contentHeading: "Headquarters (USA)",
            location: <span className='details'><LocationOnIcon style={{ marginRight: '0.3em' }} /> #7, Quaker Dr., East Brunswick, NJ, 08816</span>,
            contact: <span className='details'><LocalPhoneIcon style={{ marginRight: '0.3em' }} /> +1 (646) 403-4525</span>,
        },
        {
            headerImgUrl: 'https://hexaviewtech.com/wp-content/uploads/2021/01/travel-4813658_640.jpg',
            contentHeading: "Development Office: Noida (INDIA)",
            location: <span className='details'><LocationOnIcon style={{ marginRight: '0.3em' }} /> B-21, Sector 58,  Noida (Near Delhi), UP-201301</span>,
            contact: <span className='details'><LocalPhoneIcon style={{ marginRight: '0.3em' }} /> +91 9818693788</span>,
        },
        {
            headerImgUrl: 'https://hexaviewtech.com/wp-content/uploads/2021/07/pune.png',
            contentHeading: "Development Office: Pune (INDIA)",
            location: <span className='details'><LocationOnIcon style={{ marginRight: '0.3em' }} /> Level-5 Tech Park One Vatika Business Centre, Loop Rd, Shastrinagar, Yerawada, Pune, Maharashtra 411006</span>,
            contact: <span className='details'><LocalPhoneIcon style={{ marginRight: '0.3em' }} /> +91 9818693788</span>,
        },
        {
            headerImgUrl: 'https://hexaviewtech.com/wp-content/uploads/2021/01/golden-gate-bridge-388917_640.jpg',
            contentHeading: "Regional Office (USA)",
            location: <span className='details'><LocationOnIcon style={{ marginRight: '0.3em' }} /> 340 S Lemon Ave #8561, Walnut, CA, 91789</span>,
            contact: <span className='details'><LocalPhoneIcon style={{ marginRight: '0.3em' }} /> +1 (646) 403-4525</span>,
        },
        {
            headerImgUrl: 'https://hexaviewtech.com/wp-content/uploads/2021/01/eiffel-tower-3349075_640.jpg',
            contentHeading: "Regional Office (Europe)",
            location: <span className='details'><LocationOnIcon style={{ marginRight: '0.3em' }} /> 24 Boulevard Flandrin, Paris, France - 75116</span>,
            contact: <span className='details'><LocalPhoneIcon style={{ marginRight: '0.3em' }} /> +1 (646) 403-4525</span>,
        },
        {
            headerImgUrl: 'https://hexaviewtech.com/wp-content/uploads/2021/01/Bangalore.png',
            contentHeading: "Regional Office (INDIA)",
            location: <span className='details'><LocationOnIcon style={{ marginRight: '0.3em' }} /> Evoma , 1st Floor, "THE ADDRESS" Building, Survey No. 17/1, Kadubeesanahalli, Marathalli, Bangalore - 560103</span>,
            contact: <span className='details'><LocalPhoneIcon style={{ marginRight: '0.3em' }} /> +91 9818693788</span>,
        },
    ]
    return (
        <Grid container className='contact-main-container' style={{ overflow: 'auto' }}>
            <SecondaryTopPageSection
                img={"https://hexaviewtech.com/wp-content/uploads/2020/12/Contact-.png"}
                h1Heading={"Contact Us"}
                textSectionA={"Have any questions?"}
                textSectionA2={"Drop your details and we will get in touch with you in no time!"}
            />
            <ContainerWrapper className="form-section-main-container Section">
                <div>
                    <div className="form-wrapper">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="form-elements-wrapper">
                                <h2>Let's Talk</h2>
                                <Form
                                    name="basic"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 24 }}
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        name="username"
                                        rules={[{ required: false, message: 'Please input your full name!' }]}
                                    >
                                        <Input placeholder='Full Name' />
                                    </Form.Item>

                                    <Form.Item
                                        name="Email"
                                        rules={[{ required: true, message: 'Please input your Email!' }]}
                                    >
                                        <Input placeholder='Business Email*' />
                                    </Form.Item>
                                    <Form.Item
                                        name="Company Name"
                                        rules={[{ required: false }]}
                                    >
                                        <Input placeholder='Company Name' />
                                    </Form.Item>
                                    <Form.Item
                                        name="requirements"
                                        rules={[{ required: false }]}
                                    >
                                        <TextArea className='text-area' placeholder='Your message(optional)' />
                                    </Form.Item>
                                    <Form.Item >
                                        <Checkbox >Send me a copy of NDA</Checkbox>
                                    </Form.Item>
                                    <Form.Item >
                                        <Button className='submit-btn' type="primary" htmlType="submit" >
                                            SEND MESSAGE
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="form-elements-wrapper">
                                <h2>Contact details</h2>
                                <div className="contact-details-wrapper">
                                    <div className="contact-details">
                                        <h4>Consult</h4>
                                        <div><span className='details'><LocalPhoneIcon style={{ marginRight: '0.3em', fontSize: '1.2em' }} /> +1 (646) 403-4525</span></div>
                                        <div><span className='details'><MailIcon style={{ marginRight: '0.3em', fontSize: '1.2em' }} /> hello@hexaviewtech.com</span></div>
                                    </div>
                                    <div className="contact-details">
                                        <h4>Careers</h4>
                                        <div><span className='details'><LocalPhoneIcon style={{ marginRight: '0.3em', fontSize: '1.2em' }} /> +91 (120) 454-5948</span></div>
                                        <div><span className='details'><MailIcon style={{ marginRight: '0.3em', fontSize: '1.2em' }} /> careers@hexaviewtech.com</span></div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </div>
                </div>
            </ContainerWrapper>
            <ContainerWrapper style={{ backgroundColor: '#fff' }}>
                <div className="contact-C Section">
                    {
                        cardArr.map(item => (
                            <ContactCards
                                headerImgUrl={item.headerImgUrl}
                                contentHeading={item.contentHeading}
                                location={item.location}
                                contact={item.contact}
                            />
                        ))
                    }

                </div>
            </ContainerWrapper>
        </Grid>
    );
}

export default Contact;
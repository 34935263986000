import React from 'react'
import PageTopSection from '../../../common-components/pageTop-section/PageTopSection'
import ContainerWrapper from '../../../utils/custom-tags/ContainerWrapper'
import banner from '../../../../assets/Resources/press-release/press-banner-bg.svg';
import { Card, Grid } from '@mui/material';
import './press-release.scss';
import Earth from '../../../../assets/Resources/press-release/earth-day.webp';
import Forbes from '../../../../assets/Resources/press-release/forbes-ankit-sir.webp';
import PressShape1 from '../../../../assets/Resources/press-release/press-shapebg1.svg';
import PressShape2 from '../../../../assets/Resources/press-release/press-shape-2.png'
import GreatPlace from '../../../../assets/Resources/press-release/great-place-to-work.gif';
import CR from '../../../../assets/Resources/press-release/covid-relief.webp';
import IntCorp from '../../../../assets/Resources/press-release/Int-Corp-Meet.webp';
import Christmas from '../../../../assets/Resources/press-release/christmas-2020.webp';
import Holi from '../../../../assets/Resources/press-release/holi.webp';
import Sports from '../../../../assets/Resources/press-release/sports.webp';
import Help from '../../../../assets/Resources/press-release/help.webp';
import Hygiene from '../../../../assets/Resources/press-release/hygiene.webp';
import Campus from '../../../../assets/Resources/press-release/campus.webp';
import Smile from '../../../../assets/Resources/press-release/smile.webp';
import Cricket from '../../../../assets/Resources/press-release/cricket-match.webp';
import FoundersDay from '../../../../assets/Resources/press-release/9th-founders-day.webp';
import Holi2019 from '../../../../assets/Resources/press-release/Holi2019.webp';
import Remote from '../../../../assets/Resources/press-release/working-remotely.png';
import Webinar from '../../../../assets/Resources/press-release/webinar.webp';
import CSR from '../../../../assets/Resources/press-release/CSR-3.webp';
import TechLeaders from '../../../../assets/Resources/press-release/tech-leaders.webp';
import VirtualCampus from '../../../../assets/Resources/press-release/virtual-campus-recrufi.png';
import Image from '../../../../assets/Resources/press-release/Hexaview-WealthTech100-PR.webp';

const PressRelease = () => {
    const dictA = [
        {
            image: Earth,
            heading: "Plantation Drive on World Earth Day at Hexaview",
            text: "On the Occasion of World Earth Day (on April 22, 2022), the CSR team – Pankh at Hexaview, along with 15 volunteers from different departments, planned to celebrate the day by planting around 30 saplings near the office area."
        },
        {
            image: Forbes,
            heading: "Hexaview's CTO Joins Forbes Business Council",
            text: "We are pleased to announce that the company’s co-founder and CTO, Ankit Agarwal, has joined the Forbes Business Council, an invitation-only growth and networking organization for successful business owners and leaders worldwide."
        }]

    const dictB = [{
        image: GreatPlace,
        heading: "Great Place to Work Certified®",
        text: "We are super proud and thrilled to share that Hexaview is now certified™ as a Great Place to Work®."
    },
    {
        image: CR,
        heading: "Hexaview & Employees Demonstrate Support for India’s Covid Relief Efforts",
        text: "With hopes to reduce the impact of the ongoing crisis and help facilitate the fight against it, Hexaview has taken three great initiatives in joining the support. Click here to read more. "
    }]
    const dictC = [{
        image: IntCorp,
        heading: `"Don't be average, be the best in your field" said Abhishek at Int. Corporate Meet`,
        text: "Abhishek, CEO of Hexaview was invited as a guest speaker in the event to enlighten the students about entrepreneurship and share his unique insights on the journey of an entrepreneur, and the kind of relationship between academia and the business world should have to produce more successful entrepreneurs."
    },
    {
        image: Christmas,
        heading: "Holiday Vibes! 😀 Virtual Christmas Celebrations - 2020!",
        text: "Keeping up with the traditions of Hexaview of celebrating Christmas with full enthusiasm, this year, we organized a virtual party on Zoom and had a memorable event."
    }]
    const dictD = [{
        image: Holi,
        heading: "Yes, it's Holi 2k20 - the festival of colors!",
        text: "HVTians welcomed Holi 2k20 with high spirits. The event was full of fun events & games. Everyone danced and enjoyed the event"
    },
    {
        image: Sports,
        heading: "Encouraging Sports at Sai Kripa",
        text: `On Children’s day, our CSR team ‘Pankh’ (Wings) arranged a Badminton Court for children of the orphanage Sai Kripa to ensure their good mental as well as physical health.`
    }]
    const dictE = [{
        image: Help,
        heading: "Give Heartful and Take Handful",
        text: "Poverty isn’t hard to beat if one chooses to help. Our CSR team, ‘PANKH,’ decided to aid those who couldn’t support themselves by a Cloth & Blanket Donation Drive."
    },
    {
        image: Hygiene,
        heading: "Women Sanitation and Hygiene",
        text: "Our CSR team took measures to remove the entrenched stigma and taboos around menstruation by educating women about waste disposal & providing them sanitary pads."
    }]
    const dictF = [{
        image: Campus,
        heading: "Hexaview Campus Placement at Pilani",
        text: "Our team went to Pilani for a Campus placement drive in which 140+ students participated. We were hiring for the position of Software developer and Quality test engineer."
    },
    {
        image: Smile,
        heading: "Spreading Smile: Time to look beyond the scale",
        text: "We ran a campaign to shed light on Mosquito-Borne disease in the nearby underprivileged areas and distributed the essentials like Soap, Mosquito oil, etc., to inculcate hygiene habits."
    }]
    const dictG = [{
        image: Cricket,
        heading: "Intra-Hexaview Cricket Match",
        text: "Hexaview cricket enthusiasts organized an Intra-Hexaview cricket match. It was an excellent game with loads of action, humor, a little bit of sledging, and some magnificent shots."
    },
    {
        image: FoundersDay,
        heading: "Nine glorious years of Hexaview!",
        text: "HVTians celebrated the nine stupendous years of success, achievements, and excellence of Hexaview Technologies with loads of fun, food, and energy."
    }]
    const dictH = [{
        image: Holi2019,
        heading: "It's Holi 2019: Fun-Filled Festival",
        text: "We celebrated the color of festivals with fun-filled activities, beautiful Rangoli, traditional dress code, creating entire new vibes using dhol beats and mouth-watering Holi delicacies."
    },
    {
        image: Remote,
        heading: "Working Remotely Relentlessly",
        text: "We prioritized the employee’s wellbeing and have been operating remotely since the first lockdown in March 2020. We are thrilled with the support we got from HVTians."
    }]
    const dictI = [{
        image: VirtualCampus,
        heading: "Virtual Campus Recruitment Begins with 'Recru-Fi'- AMU",
        text: "We participated in the inauguration of ‘Recru-Fi,’ the online job fair organized by Aligarh Muslim University. Many candidates got selected for various positions and got lucrative packages."
    },
    {
        image: Webinar,
        heading: "Hexaview Hosted A Webinar for Employees on Financial Planning",
        text: "To answer all concerns of HVTians regarding financial decisions, Hexaview organized a webinar on August 11, 2020, in association with Financial Advisor, Kapil Khurana."
    }]
    const dictJ = [{
        image: TechLeaders,
        heading: "Virtual Hiring During COVID-19 Crisis!",
        text: "Hexaview restructured the strategies and conducted the virtual campus recruitment for the batch of 2021. Thanks to the HR team for making the process easy and smooth for everyone."
    },
    {
        image: CSR,
        heading: "Safe Drinking Water for All",
        text: "To make clean drinking water available to all, Hexaview has installed a Reverse Osmosis (RO) based Water Cooler outside Hexaview’s Parking Zone’s exit gate."
    }]
    const dictK = [
        {
            image: Image,
            heading: "Hexaview named in the world’s top 100 most innovative FinTech companies",
            text: "Hexaview Technologies, a global provider of technology solutions, is pleased to announce its listing as one of the most innovative tech solution providers in 2022 by specialist research firm FinTech Global."
        }
    ]

    return (
        <div className="main-container paddingTopSection">
            <PageTopSection
                img={banner}
                h1Heading={"PRESS RELEASE"}
                textSectionA={"Stay in the loop, not out!"}
                textSectionB={"Hexaview’s most up-to-date company news, press releases, interviews, and articles featuring our connoisseurs."}
                isScheduleCall={false} />
            <ContainerWrapper className="pr-section Section">
                <Card className='pr-card'>
                    <div className='content'>
                        <h2>{dictK[0].heading}</h2>
                    </div>
                    <div className="press-shape-1">
                        <img src={PressShape1} alt="" />
                    </div>
                    <Card className='image'>
                        <img style={{width: "1000px", height: "fit-content"}} src={dictK[0].image} alt="" />
                    </Card>
                    <div className="press-shape-2">
                        <img src={PressShape2} alt="" />
                    </div>
                    <div className='content'>
                        <p>{dictK[0].text}</p>
                    </div>
                    <div className='talk-to-epert'>
                        <h5> Read More</h5>
                        <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                    </div>
                </Card>
            </ContainerWrapper>
            <CommonSection
                dict={dictA} />
            <CommonSection
                dict={dictB} />
            <CommonSection
                dict={dictC} />
            <CommonSection
                dict={dictD} />
            <CommonSection
                dict={dictE} />
            <CommonSection
                dict={dictF} />
            <CommonSection
                dict={dictG} />
            <CommonSection
                dict={dictH} />
            <CommonSection
                dict={dictI} />
            <CommonSection
                dict={dictJ} />
        </div>
    )
}
type Props = {
    dict?: any;
}
export const CommonSection = ({ dict }: Props) => {
    return (
        <ContainerWrapper className="pr-section Section">
            <Grid container spacing={4}>
                {dict.map((item: any) => {
                    return (
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Card className='pr-card'>
                                <div className='content'>
                                    <h2>{item.heading}</h2>
                                </div>
                                <div className="press-shape-1">
                                    <img src={PressShape1} alt="" />
                                </div>
                                <Card className='image'>
                                    <img src={item.image} alt="" />
                                </Card>
                                <div className="press-shape-2">
                                    <img src={PressShape2} alt="" />
                                </div>
                                <div className='content'>
                                    <p>{item.text}</p>
                                </div>
                                <div className='talk-to-epert'>
                                    <h5> Read More</h5>
                                    <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                                </div>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </ContainerWrapper>
    )
}

export default PressRelease
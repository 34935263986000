import React from 'react'
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import '../FinTechCommon.scss';
import banner from '../../../../../assets/FinTech-wealth-management/capital-market/slider2.svg';
import ContainerWrapper from '../../../../utils/custom-tags/ContainerWrapper';
import { CommonCard } from '../wealth-management/WealthManagement';
import automation from '../../../../../assets/FinTech-wealth-management/automation.svg'
import { Grid } from '@mui/material';
import Cards from '../../../../common-components/card/Card';
import Hands from '../../../../../assets/FinTech-wealth-management/capital-market/hands.svg';
import Devices from '../../../../../assets/FinTech-wealth-management/capital-market/devices.svg';
import Cycle from '../../../../../assets/FinTech-wealth-management/capital-market/cycle.svg';
import Monitor from '../../../../../assets/FinTech-wealth-management/capital-market/monitor.svg';
import Settings from '../../../../../assets/FinTech-wealth-management/capital-market/settings.svg';
import Chat from '../../../../../assets/FinTech-wealth-management/capital-market/chat.svg';
import CaseStudy from '../../../../common-components/caseStudy/Index';
import SoftwareInvestment from '../../../../../assets/FinTech-wealth-management/capital-market/software-investment.webp';
import Implementation from '../../../../../assets/FinTech-wealth-management/capital-market/implementation.webp';
import Report from '../../../../../assets/FinTech-wealth-management/capital-market/report-generation.webp';
import CSD from '../../../../../assets/FinTech-wealth-management/capital-market/custom-software-development.webp';
const CapitalMarket = () => {

    const caseStudies = [
        {
            image: SoftwareInvestment,
            heading2: "Developed an online platform for revolutionizing trading and investment management experience",
            text: "Hexaview partnered with the client and helped in development of software that offers comprehensive coverage of the entire investment process under a single platform and support users managing their whole financial life in one place."
        },
        {
            image: Implementation,
            heading2: "Established a User-Friendly Payment Management Portal on AWS Cloud",
            text: "The client wanted a payment management portal that would ease the process of entering payments and executing them which was earlier processed in excel files manually. We suggested to them the concept of Treasury Portal which helped the client to manage payments, review them, and facilitate various"
        },
        {
            image: Report,
            heading2: "Online Portal to Digitize the Monthly Report Generation Process",
            text: "One of our US-based clients wanted an online portal for report generation of funds and its email management system which was earlier done manually through excel. Hexaview successfully helped the client to digitize the monthly report generation process of funds and automated the email management"
        },
        {
            image: CSD,
            heading2: "Custom Software Development to integrate Financial Systems Bloomberg & AXYS Portfolio Management System",
            text: "A well renowned US-based mutual fund company, required custom software to integrate Bloomberg Trade Converter output with AXYS Portfolio management system but with custom defined rules."
        },
    ]
    return (
        <div className="main-container paddingTopSection">
            <PageTopSection
                img={banner}
                h1Heading={"Transforming Capital Markets with Comprehensive Tech"}
                textSectionB={"We understand the changing tech landscape’s demands and help you address your business’s most critical KPIs, thus empowering you to keep up with the competition without worrying about the costs."} />

            <ContainerWrapper className="cm-section-B Section">
                <div className="heading">
                    <h2>Challenges of Capital Markets</h2>
                </div>
                <div className="text-section">
                    <p>The capital market industry is witnessing significant challenges like the regulatory challenge, changes in global financial market dynamics, increasing volatility, customer engagement, and many others. To solve these problems, capital market firms should focus on adopting the latest disruptive technologies including Artificial Intelligence, Machine Learning, Automation, Blockchain, RPA, and others that will ensure cost-effective and effective compliance. Capital Market firms must utilize capital market software solutions to streamline and optimize the current operational processes. These capital market software products help the firms in improving transparency, manage risk, and enable greater operational efficiency.</p>
                </div>
                <Grid className="wm-cards-section" container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <CommonCard
                            img={Hands}
                            heading={"Slow Programs"}
                            backgroundColor={"#FFF3D4"}
                            text={"Is your software weighing down your operations? This becomes a real turn-off to clients who may miss out on significant market opportunities."} /></Grid>
                    <Grid style={{ marginTop: "100px" }} item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <CommonCard
                            img={Devices}
                            backgroundColor={"#FDE8F5"}
                            heading={"Poor Data Management"}
                            text={"Unable to efficiently administrate your virtual assets? Data supervision can improve your overall growth and drive up success!"} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <CommonCard
                            img={Cycle}
                            backgroundColor={"#D8E3FD"}
                            heading={"Strict Regulations"}
                            text={"Regulations causing you to fall behind? Continually updating software to meet market regulations digs a deep hole in your pocket."} />
                    </Grid>
                </Grid>
            </ContainerWrapper>

            <ContainerWrapper className="cm-section-C Section">
                <div className="heading">
                    <h2>Capital Markets Software Solutions</h2>
                </div>

                <div className="text-section">
                    <p>Our capital markets software solutions allow your enterprise to explore the capital market spectrum while staying abreast of all regulatory practices and data challenges. We develop highly secured and robust capital market software solutions as per your custom requirements that will help your firm in solving real-world problems. Leveraging more than 10 years of experience in the capital market and wealth management industry, we help you to drive your business growth, thus providing a competitive edge over your competitor. Our integrated capital market solutions address your key businesses, including regulatory compliance, client engagement, risk management, and trading across all asset classes. We have a team of highly skilled and experienced professionals that can provide tailored capital market software solutions and products that will enhance the customer experience and engagement.</p>
                </div>
                <div className='card-wrapper'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <CommonCard
                                img={Monitor}
                                heading={"Trade Order Management System"}
                                text={"Our Trading and Order Management solution enhance trading efficiency by simplifying the functions of capturing, routing, bulking, and trading orders."}
                                list={[
                                    "Improve workflow and communication between different levels of the organization.",
                                    "Prevent regulatory violations.",
                                    "Enhance the speed and accuracy of trade execution.",
                                    "Track real-time changes in trade lifecycles.",
                                    "Reduce your overall costs."]}
                                color={"#43BAFF"} /></Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <CommonCard
                                img={Chat}
                                heading={"Data Management & Analytics"}
                                text={"As data is the new oil, it powers everything that we do. We provide high-value data management solutions through a broad range of BI and ML tools."}
                                list={[
                                    "AI and ML tools provide smart solutions to enhance your data management experience.",
                                    "Cloud storage support fortifies your digital assets while making them universally accessible.",
                                    "Added safety measures ensure the highest level of security for your virtual resources.",
                                    "Improved UI and UX capabilities elevate your data analytics and interpretation."]}
                                color={"#43BAFF"} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <CommonCard
                                img={Settings}
                                heading={"Regulatory Compliance"}
                                text={"We help you thrive amid all regulatory, legal, and compliance demands. Stay ahead of any new and emerging challenges."}
                                list={[
                                    "Meet all annual and quarterly reporting obligations.",
                                    "Use Machine Learning for traditional compliance control testing.",
                                    "Deploy Artificial Intelligence to uncover fraudulent activities.",
                                    "Risk analysis tools to prevent uncertainties in the workflow.",
                                    "Govern all data with top-of-the-line solutions that elevate your digital setup."]}
                                color={"#43BAFF"} />
                        </Grid>
                    </Grid>
                </div>
            </ContainerWrapper>
            <ContainerWrapper className="cm-section-D Section">
                <div className='talk-to-epert'>
                    <h5 style={{ color: '#43BAFF' }}>Talk to Our Expert</h5>
                    <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                </div>
            </ContainerWrapper>

            {/* <CaseStudy array={caseStudies} /> */}
        </div>
    )
}

export default CapitalMarket
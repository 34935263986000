import React from 'react';
import {useNavigate, NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Button} from 'antd';
import Service from './service/Index'
import CorporateOverview from './corporateOverview/Index'
import Product from './product/Index'
import Resource from './resource/Index'
import hexalogo from "../../../assets/header/logo.png";
import Gptw from '../../../assets/header/GPTW.png'
import './Header.scss';

const Home = ()=> {
    const navigate = useNavigate();
    
    const handleContactUs = ()=>{
        navigate('/contact');
    }
    const handleLogoClick =()=>{
        navigate('/home');
    }

    return (
         <header className='main-header-container'>
            <div className='container-fluid'>
                <Grid container>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div className='logo-container'>
                            <img  src={hexalogo} onClick={handleLogoClick} style={{cursor:'pointer'}}/>
                        </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className='heder-left-panel'>
                        <div className='menuButtonGroup'>
                            <ul className='menu'>
                                <li><NavLink to="">Services <KeyboardArrowDownIcon /></NavLink>
                                   <Service />
                                </li>
                                <li><NavLink to="">Products <KeyboardArrowDownIcon /></NavLink>
                                   <Product />
                                </li>
                                <li><NavLink to="">Resources <KeyboardArrowDownIcon /></NavLink>
                                    <Resource />
                                </li>
                                <li><NavLink to="">Corporate overview <KeyboardArrowDownIcon /></NavLink>
                                    <CorporateOverview />
                                </li>
                            </ul>
                            <Button  className='contact-us' onClick={handleContactUs}>CONTACT US</Button>
                        </div>
                        <div className='certificate'>
                            <img src={Gptw} alt="" />
                        </div>
                    </Grid>
                </Grid>
            </div>
     </header>
    );
}

export default Home;
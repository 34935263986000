import React from "react";
import PageTopSection from "../../../../common-components/pageTop-section/PageTopSection";
import "./DiversityInclusion.scss";
import { Grid } from "@mui/material";
import CardWithLeftHeader from "../../../../common-components/card/CardWithLeftHeader";
import { Card } from "@mui/material";
import Gender from '../../../../../assets/corporate-overview/svgexport-13.svg';
import LGBTQ from '../../../../../assets/corporate-overview/svgexport-14.svg';
import Disability from '../../../../../assets/corporate-overview/svgexport-15.svg';
import Race from '../../../../../assets/corporate-overview/svgexport-16.svg';
import Generation from '../../../../../assets/corporate-overview/svgexport-18.svg';
import Nationalities from '../../../../../assets/corporate-overview/svgexport-19.svg';
import Workforce from '../../../../../assets/corporate-overview/svgexport-20.svg';
import Believe from '../../../../../assets/corporate-overview/WHAT-WE-BELIEVE.jpg';
import Act from '../../../../../assets/corporate-overview/HOW-WE-ACT-UPON.jpg'
import Ambition from '../../../../../assets/corporate-overview/ambition.svg';

const DiversityInclusion = () => {
    return (
        <div className="di-main-container paddingTopSection">
            <PageTopSection
                img={
                    "https://hexaviewtech.com/wp-content/uploads/2022/07/Diversity-and-Inclusion.jpg"
                }
                h1Heading={"DIVERSITY AND INCLUSION"}
                textSectionA={
                    "DO YOU KNOW? OVER 50% OF HEXAVIEW'S LEADERSHIP IS CLASSIFIED AS DIVERSE"
                }
                textSectionB={
                    "OUR DIVERSITY MAKE US STRONGER AND BETTER EVERY DAY! Including and celebrating everyone and their values are rooted in our growth story. We have a profound commitment to advancing diversity, equity, and inclusion in our industry, business, and society."
                }
                isScheduleCall={false}
                isTalkToExpert={false}
            />

            <section className="di-section-B Section">
                <div className="container">
                    <Grid container className="element-inner-section">
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className="image-section">
                                <img
                                    src={Believe}
                                    style={{ width: "300px" }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="heading">
                                <h2>WHAT WE BELIEVE</h2>
                            </div>
                            <div className="text-section element-widget-wrap">
                                <div>
                                    <p>
                                        Hexaview believes that everyone has the power to make a
                                        difference in our team, client’s ecosystem, and society’s
                                        future. We use Diversity and Inclusion (D&I) as an instrument
                                        for growth, and we value and celebrate each individual’s
                                        uniqueness by establishing an inclusive and empowering
                                        atmosphere.</p>

                                    <p>
                                        We live our values by cultivating our inclusive & equitable
                                        culture, where everyone is seen and heard, and their
                                        contributions are respected and valued; where everyone feels a
                                        sense of belonging, confident in being their entire authentic
                                        self, empowered to realize their full potential, and is inspired
                                        to lead.
                                    </p>
                                </div>
                            </div>
                            <div className="btn-container">
                                <div className="btn">
                                    <a href="https://hexaviewtech.com/contact/">Join Network</a>
                                    <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>

            <section className="di-section-C Section">
                <div className="container">
                    <Grid container spacing={-18} wrap='wrap'>
                        <Grid className="vertical-line" item xs={1}>
                        </Grid>
                        <Grid item xs={11}>
                            <div className="heading">
                                <h3>A WORD FROM OUR CHIEF DIVERSITY OFFICER</h3>
                            </div>
                            <div className="text-section">
                                <p>
                                    As the CDO of Hexaview, I want to ensure the entire community of
                                    Hexaview is aware that we are committed to the principles of
                                    diversity, inclusion, and equality. Throughout the decades of my
                                    experience, I had the privilege to work with diverse teams from
                                    different countries and ethnicities and had a great time together.
                                    I genuinely believe, Diverse groups bring a treasure of
                                    perspective that is critical to long-term success. For us @
                                    Hexaview, our people always come first.
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="di-section-D Section">
                <div className="container">
                    <Grid container className="element-inner-section">
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <div className="image-section">
                                <img
                                    style={{ height: "520px" }}
                                    src={Act}
                                    alt=""
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Card className="card-section">

                                <div className="heading">
                                    <h2>
                                        HOW WE ACT UPON <br />
                                        <span style={{ color: "#49BAFF" }}> OUR GOALS</span>
                                    </h2>
                                </div>
                                <div className="text-section">
                                    <p>
                                        To help our people thrive, Hexaview offers robust programs,
                                        including specialized training, networking support, flexible
                                        work arrangements, mentoring, mental health resources, equal
                                        benefits to everyone. </p>
                                    <p>
                                        Complete Transparency: We believe in trust and transparency,
                                        and for that, we set targets, collect our workforce data and
                                        make implementations to enhance our equality & build a better
                                        culture.
                                    </p>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </section>

            <section className="di-section-E Section">
                <div className="container">
                    <div className="heading">
                        <h2>OUR WORKFORCE</h2>
                    </div>
                    <div className="text-section">
                        In 2018, we committed to having at least 20% of women managers by
                        2020. By the end of 2020, 25% of managers were women.
                    </div>
                    <br /> <br /> <br />
                    <div className="counters">
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <BlueCard
                                    heading={"Where are we Today"}
                                    numberArr={{
                                        "25%": "of Women in Leadership and Management roles",
                                        "2": "Nationalities on our board of Management",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <BlueCard
                                    heading={"Diversity by age"}
                                    numberArr={{
                                        "40%  ": "Millenials",
                                        "48%  ": "Gen Z",
                                        "10%": "Gen X",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>

            <section className="di-section-F Section">
                <div className="container">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="heading">
                                <h3>
                                    INCLUSION, DIVERSITY, EQUITY, AND <br />{" "}
                                    <span style={{ color: "#49BAFF" }}> ACCESSIBILITY MATTERS</span>
                                </h3>
                            </div>
                            <div className="text-section">
                                <p>
                                    We build our programs on the foundation of Inclusion, Diversity,
                                    Equity, and Accessibility to create a culture where every
                                    individual supports equity, where every policy and exercise is an
                                    enabler, and we continue to be the employer of choice for a
                                    diverse workforce.
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="upper-card">
                                <Card className="card-styling">
                                    <div className="heading">
                                        <a href="/corporate-overview/csr/wwn">
                                            <h4>HERoes of Hexaview</h4>
                                        </a>
                                    </div>
                                </Card>
                            </div>
                            <br />
                            <Card className="card-styling">
                                <div className="heading">
                                    <h4>Equal Pay for Equal Work</h4>
                                </div>
                                <div className="text-section">
                                    We are committed to pay equity and always ensure our people
                                    receive fair and consistent compensation when considering the
                                    similarity of work, location, and tenure at the career level.
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </section>

            <section className="di-section-G Section">
                <div className="container">
                    <div className="heading">
                        <h2>
                            GPTW BADGE <br />
                            DIVERSE AREAS DIVISIONS
                        </h2>
                    </div>
                    <Grid
                        container
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <CardWithLeftHeader
                                headerIcon={<img src={Gender} />}
                                contentHeading="Gender Inclusion"
                                descriptionText={
                                    "creates an ecosystem of equity across genders while strengthening them through an inclusive approach."
                                }
                            />
                            <CardWithLeftHeader
                                headerIcon={
                                    <img src={LGBTQ} />
                                }
                                contentHeading="People with Disability and Neurodiversity Inclusion"
                                descriptionText={
                                    "aims to remove the culture of stigma around disability through an inclusive environment of access and knowledge."
                                }
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <CardWithLeftHeader
                                headerIcon={
                                    <img src={Disability} />
                                }
                                contentHeading="LGBTQ+ Inclusion"
                                descriptionText={
                                    "aims to create an environment where all LGBTQ+ people are free to be themselves in their workplaces and can live their lives to the full. "
                                }
                            />

                            <CardWithLeftHeader
                                headerIcon={
                                    <img src={Race} />
                                }
                                contentHeading="Race and Ethnic Diversity"
                                descriptionText={
                                    "aims to provide all persons, irrespective of race, nationality, ethnic origin, caste, and religion, have equal opportunities of representation, engagement, knowledge, development, and career progression, without discrimination."
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div className="btn-container">
                                <div className="btn">
                                    <a href="https://hexaviewtech.com/contact/">Join Network</a>
                                    <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="di-section-H Section">
                <div className="container">
                    <Grid container spacing={8} className="element-inner-section">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="heading">
                                <h3>
                                    OUR AMBITIONS – <br />{" "}
                                    <span style={{ color: "#49BAFF" }}>
                                        WHAT WE WANT TO ACHIEVE BY 2025
                                    </span>
                                </h3>
                            </div>
                            <br />
                            <br />
                            <Card
                                style={{ height: "90px", width: "500px", borderRadius: "12px" }}
                            >
                                <CardWithLeftHeader
                                    headerIcon={
                                        <img src={Nationalities} />
                                    }
                                    contentHeading="Nationalities"
                                    descriptionText={
                                        "At least three different nationalities are represented on our Boards of Management."
                                    }
                                />
                            </Card>
                            <br />
                            <Card
                                style={{ height: "90px", width: "560px", borderRadius: "12px" }}
                            >
                                <CardWithLeftHeader
                                    headerIcon={
                                        <img src={Workforce} />
                                    }
                                    contentHeading="Workforce"
                                    descriptionText={
                                        "Reach 500 employees through workforce empowerment program"
                                    }
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <div className="image-section">
                                <img
                                    src={Ambition}
                                    style={{ width: "450px" }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>

            {/* <div className="di-section-I Section">
                <div className="heading">
                    <h2>Employee Testimonials</h2>
                </div>
                <Carousel
                    arrows nextArrow={< ArrowForwardIcon />} prevArrow={<ArrowBackIcon />}
                    autoplay
                    // arrows
                    dots={false}
                >
                    <Testimonials
                        image='https://hexaviewtech.com/wp-content/uploads/2021/09/nalin.png'
                        name={"Nalin Singh Tyagi"}
                        text={"What makes Hexaview special is the inclusive and caring culture reflected across all levels within the company and echoed by clients. I'm greatful to be a part of such a great group."}
                        icon={building}
                        desig={"Business Head-Salesforce"}
                        heading={"Inclusive and Caring Culture"} />

                    <Testimonials
                        image='https://hexaviewtech.com/wp-content/uploads/2021/09/nalin.png'
                        name={"Nalin Singh Tyagi"}
                        text={"What makes Hexaview special is the inclusive and caring culture reflected across all levels within the company and echoed by clients. I'm greatful to be a part of such a great group."}
                        icon={building}
                        desig={"Business Head-Salesforce"}
                        heading={"Inclusive and Caring Culture"} />

                    <Testimonials
                        image='https://hexaviewtech.com/wp-content/uploads/2021/09/nalin.png'
                        name={"Nalin Singh Tyagi"}
                        text={"What makes Hexaview special is the inclusive and caring culture reflected across all levels within the company and echoed by clients. I'm greatful to be a part of such a great group."}
                        icon={building}
                        desig={"Business Head-Salesforce"}
                        heading={"Inclusive and Caring Culture"} />

                    <Testimonials
                        image='https://hexaviewtech.com/wp-content/uploads/2021/09/nalin.png'
                        name={"Nalin Singh Tyagi"}
                        text={"What makes Hexaview special is the inclusive and caring culture reflected across all levels within the company and echoed by clients. I'm greatful to be a part of such a great group."}
                        icon={building}
                        desig={"Business Head-Salesforce"}
                        heading={"Inclusive and Caring Culture"} />
                </Carousel>
            </div> */}
        </div>
    );
};

interface props {
    numberArr?: any;
    textArr?: any;
    heading?: String;
}

export const BlueCard = ({ numberArr, textArr, heading }: props) => {
    return (
        <Card className="counter-card-styling">
            <div className="heading">
                <h3>{heading}</h3>
            </div>
            <div className="number-array">
                {Object.keys(numberArr).map((number, index) => {
                    return (
                        <div className="inner-array">
                            <div className="heading">
                                <h1>{number}</h1>
                            </div>
                            {/* <hr/> */}
                            <div className="text-section">{numberArr[number]}</div>
                        </div>
                    );
                })}
            </div>
            {/* <div className="text-array">
        {textArr.map((text: String) => {
          return <div className="text-section">{text}</div>;
        })}
      </div> */}
        </Card>
    );
};

export default DiversityInclusion;

import React from 'react';
import Grid from '@mui/material/Grid';
import Automatic_Text_Summarization_in_Machine_Learning from '../../../../../assets/Automatic-Text-Summarization-in-Machine-Learning.png';
import Data_Science_in_Business from '../../../../../assets/Data-Science-in-Business.png';
import customer_onboarding from '../../../../../assets/customer-onboarding.png';
import Amazon_Sagemaker from '../../../../../assets/Amazon-Sagemaker.png';
import "../DataScience.scss";
import Cards from '../../../../common-components/card/Card';

const RecentResources = () => {
    return (
        <section className='recent-resources-section Section'>
            <div className='container'>
                <div className="heading-wrapper">
                    <h2>Recent Resources</h2>
                </div>
                <div className='cards-wrapper'>
                    <Cards
                        headerImgUrl={Automatic_Text_Summarization_in_Machine_Learning}
                        contentHeading={"BLOGS"}
                        isActionablePostHeading={true}
                        actionablePostHeading={"Automatic Text Summarization in Machine Learning"}
                        contentText="There has been an explosion in text data from various sources in recent years. Due to plenty of data on information servers,"
                        isActionBtn={false}
                        size={3}
                    />
                    <Cards
                        headerImgUrl={Amazon_Sagemaker}
                        contentHeading={"BLOGS"}
                        isActionablePostHeading={true}
                        actionablePostHeading={"What is Amazon SageMaker All About?"}
                        contentText="If you are into Machine Learning, you already know what Amazon SageMaker all is about. It provides an ideal environment for you"
                        isActionBtn={false}
                        size={3}
                        id="second-child"
                    />
                    <Cards
                        headerImgUrl={Data_Science_in_Business}
                        contentHeading={"BLOGS"}
                        isActionablePostHeading={true}
                        actionablePostHeading={"Data Science in Businesses: How Can it Help"}
                        contentText="The world is now a very competitive place. The marketplaces we had 10 years ago are no match with what we have"
                        isActionBtn={false}
                        size={3}
                    />
                    <Cards
                        headerImgUrl={customer_onboarding}
                        contentHeading={"CASE STUDIES"}
                        isActionablePostHeading={true}
                        actionablePostHeading={"Smart Attendance System: The new generation attendance tracking mechanism using facial recognition"}
                        contentText="Post COVID-19, managing employee attendance systems became a hurdle for our client, and they wanted a touchless solution. Learn how Hexaview developed a robust solution to mark attendance in the most hygienic and efficient way possible."
                        isActionBtn={false}
                        size={3}
                        id="last-child"
                    />
                </div>
            </div>
        </section>
    );
}

export default RecentResources;
import React from 'react';
import { Carousel, Avatar } from "antd";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const SwiperSlides = () => {
  return (
    <div>
      <Carousel
        arrows nextArrow={<ArrowForwardIosIcon />} prevArrow={<ArrowBackIosIcon />}
        autoplay
        // arrows
        dots={false}
      >
        <div className='info-container'>
          <div className='info-text'>
            We find Hexaview’s team to be very transparent & responsive. They are open to solving any problem, however intricate and time-bound. With regular check-ins, they ensure that any feedback is acted upon promptly. Hexaview resources have always helped Adobe ship good quality products.
          </div>
          <div className='avatar-container'>
            <Avatar size={90} src="https://hexaviewtech.com/wp-content/uploads/2020/12/intelliQA.jpg" />
            <div className='description'>
              <div className='name'>Parvez</div>
              <div className='title'>Hexaview</div>
            </div>
          </div>
        </div>

        <div className='info-container'>
          <div className='info-text'>
            Hexaview rightly prides itself on technical delivery, with excellent engagement and reporting throughout; however, it is the willingness and warmth that they extend that makes them truly exceptional. It is a pleasure to have a long-standing partnership with them.
          </div>
          <div className='avatar-container'>
            <Avatar size={90} src="https://hexaviewtech.com/wp-content/uploads/2020/12/intelliQA.jpg" />
            <div className='description'>
              <div className='name'>Paul Kaye</div>
              <div className='title'>IntelliQA</div>
            </div>
          </div>
        </div>

        <div className='info-container'>
          <div className='info-text'>
            We find Hexaview’s team to be very transparent & responsive. They are open to solving any problem, however intricate and time-bound. With regular check-ins, they ensure that any feedback is acted upon promptly. Hexaview resources have always helped Adobe ship good quality products.
          </div>
          <div className='avatar-container'>
            <Avatar size={90} src="https://hexaviewtech.com/wp-content/uploads/2020/12/intelliQA.jpg" />
            <div className='description'>
              <div className='name'>Mark Carey</div>
              <div className='title'>CICG</div>
            </div>
          </div>
        </div>

        <div className='info-container'>
          <div className='info-text'>
            Over the past 5 years, CICG has been working with Hexaview on many SSRS reports projects.  Hexaview offers high-quality services and we have always been extremely satisfied with their work. Their attention to detail is amazing. I would highly recommend Hexaview for all your SSRS reporting needs.
          </div>
          <div className='avatar-container'>
            <Avatar size={90} src="https://hexaviewtech.com/wp-content/uploads/2020/12/intelliQA.jpg" />
            <div className='description'>
              <div className='name'>Larry Baker</div>
              <div className='title'>BaySys Technologies LLC</div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

export default SwiperSlides;
import React from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PageTopSection from '../../../common-components/pageTop-section/PageTopSection';
import "./style.scss";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  
const Index = () => {
 

    return (
        <Grid container className='global-tech-series paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/cloud-banner.jpg"}
                h1Heading="GLOBAL TECH SERIES"
                textSectionA={"Gain insights for your growing business"}
                textSectionB={"The Global Tech Series is recorded at our different global office locations and takes audiences through topics ranging from company culture to common business challenges."}
                isScheduleCall={false}
            />
            {/*...service text section...*/}

            <section className='global-tech-series-B Section'>
               <div className='container'>
                <Grid container className='card'>
                        <Grid className="global-tech-inner-series-B" item xs={12} sm={12}>
                            <div className="heading">
                                <h2 >Global Tech Series</h2>
                                <h4>Select a topic of interest and enjoy the video from the comfort of your home.</h4>
                            </div>
                            <div className='hexa-default-btn'>
                                <p>Watch Now <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                            </div>
                        </Grid>
                </Grid>
               </div>
            </section>

            <section className='global-tech-series-C Section'>
               <div className='container'>
                <Grid container spacing={5}>
                        <Grid className="global-tech-inner-series-C" item xs={12} sm={12} md={12} lg={6}>
                            <div className='card'>
                                <div className='content'>
                                    <h2>Digital Transformation</h2>
                                    <h3>5 Most Common Challenges</h3>
                                    <p>In this episode, George Christodoulou, Sr. Account Executive, Hexaview Technologies Inc., gives a first-account overview of the challenges that organizations face as they set out on their digital transformation journey. George discusses topics ranging from the impact on employees & customers to the lack of experience driving such initiatives.</p>
                                </div>
                                <div className='image' style={{marginTop:"2em"}}>
                                    <img src={"https://hexaviewtech.com/wp-content/uploads/2021/09/digital-transformation-thumble1.png"} alt=""/>
                                </div>
                            </div>
                        </Grid>
                        <Grid className="global-tech-inner-series-C" item xs={12} sm={12} md={12} lg={6}>
                        <div className='card'>
                                <div className='image' style={{marginBottom:"1em"}}>
                                    <img src={"https://hexaviewtech.com/wp-content/uploads/2021/09/protecting.png"} alt=""/>
                                </div>
                                <div className='content'>
                                    <h2>Digital Transformation</h2>
                                    <h3>5 Most Common Challenges</h3>
                                    <p>In this episode, George Christodoulou, Sr. Account Executive, Hexaview Technologies Inc., gives a first-account overview of the challenges that organizations face as they set out on their digital transformation journey. George discusses topics ranging from the impact on employees & customers to the lack of experience driving such initiatives.</p>
                                </div>
                            </div>
                        </Grid>
                </Grid>
               </div>
            </section>
        </Grid>
    );
}

export default Index;
import React from 'react';
import Grid from '@mui/material/Grid';
import "./style.scss";

type Props = {
    heading?: String,
    description?: String,
}

const Index = ({ 
    heading,
    description
    }: Props) => {
    return (
        <section className='devops-consulting-service Section'>
            <div className='container'>
                <Grid className="section-left" item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className="heading">
                        <h3>{heading}</h3>
                    </div>
                    <div className="text-section">
                        <p>{description}</p>
                    </div>
                    <div className='hexa-primary-btn'>
                        <p>Talk to our Expert<img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                    </div>
                </Grid>
            </div>
        </section>
    );
}

export default Index;

import React from 'react';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import RecentResources from '../common/RecentResources';
import "./DataVisualizationServices.scss";
import IconBox from '../common/IconBox';
import CardWithLeftHeader from '../../../../common-components/card/CardWithLeftHeader';
import Monolith_to_Microservices from "../../../../../assets/Monolith_to_Microservices.svg";
import Cards from '../../../../common-components/card/Card';
const DataVisualizationServices = () => {
    const boxArr = [
        {
            description: "Maximize customer satisfaction"
        },
        {
            description: "To help you discover new and rewarding opportunities"
        },
        {
            description: "Create valuable data visualization solutions"
        },
        {
            description: "Identify and bridge important gaps"
        },
        {
            description: "Optimize business performance"
        },
        {
            description: "Planning effective business strategies"
        },
        {
            description: "Optimize and Manage Resources"
        },
        {
            description: "Enhance your company’s productivity and ROI"
        },
    ]
    return (
        <Grid container className='dv-main-container paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/Data-Visualization-banner.jpg"}
                h1Heading={"Data Visualization and Analytics Services"}
                textSectionA={"Transform your dispersed data into informed decisions with Hexaview’s powerful data analytics services."}
                isScheduleCall={true}
            />
            <section className="dv-section-B Section">
                <div className='container'>
                    <div className='heading-wrapper element-widget-wrap'>
                        <h2>Every Picture Tells a Story. <span>What Does Your Data Tell?</span></h2>
                        <p>Revamp your raw data into informative visualizations and discover powerful stories! Interact with data flexibly and seamlessly with our data visualization services.</p>
                    </div>
                </div>
            </section>
            <section className="dv-section-C Section">
                <div className='container'>
                    <Grid container className='element-inner-section'>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <div className='text-section element-widget-wrap'>
                                <h2>What’s Your<br /><span>Approach to Data Analytics?</span></h2>
                                <p style={{fontWeight:'bold'}}>Are your data dashboards helping the senior-level executives answer critical business questions? Do they prompt users to take action? If not, it might be time to reconsider your approach.</p>
                                <p>Data Visualization is the graphical representation of information and data. By using visual elements like charts, graphs, and maps, data visualization tools provide a simple way to see and understand trends, outliers, and patterns in data.</p>
                                <div className='border-div'>Advantages of Data Visualization Services for your business</div>
                                <div className="list-item-wrapper">
                                    <ul>
                                        <li><CheckCircleIcon fontSize='small' />Helps in instant absorption of large and complex data</li>
                                        <li><CheckCircleIcon fontSize='small' />Empowers strategic decision making</li>
                                        <li><CheckCircleIcon fontSize='small' />Reveals hidden patterns and deeper insights</li>
                                        <li><CheckCircleIcon fontSize='small' />Helps organization identify key business trends</li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <div className='image-section element-widget-wrap'>
                                <img width="100%" src='https://hexaviewtech.com/wp-content/uploads/2022/04/Data-Analytics-approach.svg' />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="dv-section-D Section">
                <div className='container'>
                    <div className='text-section element-widget-wrap'>
                        <h2>With Our Data Analytics Consulting Services,<span>We Can</span></h2>
                        <p>Running a company requires good decision-making. This requires organizing and presenting data in a way that clearly answers the questions that will drive you to correct well-informed decisions. Our data visualization & analytics services can help you identify trends, comparison, and performance towards goals and objectives and spot any deviation.</p>
                    </div>
                    <div className="cards-chip-wrapper">
                        {boxArr.map(item => (
                            <IconBox description={item.description} />
                        ))}
                    </div>
                    <div className='hexa-primary-btn'>
                        <p>Talk to our Expert <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                    </div>
                </div>
            </section>
            {/* <div className="dv-section-E Section">
                Section E
            </div> */}
            <section className="dv-section-F Section">
                <div className='container'>
                    <Grid container className='element-inner-section'>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <div className='text-section element-widget-wrap'>
                                <h2>Our Data Analytics and Visualization <span>Service Offerings</span></h2>
                            </div>
                            <div className="card-wrapper">
                                <CardWithLeftHeader
                                    headerImgUrl={Monolith_to_Microservices}
                                    contentHeading="Data Analytics"
                                    descriptionText={"Hexaview offers end-to-end data analytics services to transform your real-time or past data, into significant insights."}
                                />
                                <CardWithLeftHeader
                                    headerImgUrl={Monolith_to_Microservices}
                                    contentHeading="Data Analytics"
                                    descriptionText={"Hexaview offers end-to-end data analytics services to transform your real-time or past data, into significant insights."}
                                />
                            </div>
                            <div className="card-wrapper-2">
                                <Cards contentHeading={"Consulting"} contentText="This step helps us in understanding your business environment and mapping business strategy, thereby providing a customized solution." />
                                <Cards contentHeading={"Consulting"} contentText="This step helps us in understanding your business environment and mapping business strategy, thereby providing a customized solution." />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <div className='image-section element-widget-wrap'>
                                <img width="100%" src='https://hexaviewtech.com/wp-content/uploads/2022/04/Data-Analytics-approach.svg' />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/Ready-to-discuss-your-Data-Analytics.jpg"}
                h1Heading={"Ready to discuss your Data Analytics and Visualization Services’ needs?"}
                textSectionA={"Reach out to our expert data analytics consultants to discuss how Hexaview can make your data work harder for you."}
                isScheduleCall={false}
                btnText={"Book your free consultation here"}
            />
            <RecentResources />
        </Grid>
    );
}

export default DataVisualizationServices;

import React from 'react';
import Grid from '@mui/material/Grid';
import cloudAWS from '../../../../../../assets/cloud/cloud-AWS.webp'
import cloudGcp from '../../../../../../assets/cloud/cloud-gcp.webp'
import cloudMicrosoftAzure from '../../../../../../assets/cloud/cloud-microsoft_azure.webp'
import "./style.scss";

const Index = () => {
    return (
        <section className='servicePartner Section'>
            <div className='container'>
                <Grid item xs={12}>
                    <div className="heading">
                        <h2>Our Cloud Service Partners</h2>
                        <p>Hexaview maintains strong relationships with major cloud providers in the industry.</p>
                    </div>
                    <div className='servicePartner-inner'>
                        <Grid item xs={12} sm={4}>
                        <div className='trustImg'>
                                <img src={cloudAWS} alt=""/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className='trustImg'>
                                <img src={cloudMicrosoftAzure} alt=""/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className='trustImg'>
                                <img src={cloudGcp} alt=""/>
                            </div>
                        </Grid>
                    </div>
                </Grid>
            </div>
        </section>
    );
}

export default Index;
import React from 'react';
import Grid from '@mui/material/Grid';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import GroupsSharpIcon from '@mui/icons-material/GroupsSharp';
import CodeIcon from '@mui/icons-material/Code';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import StorageIcon from '@mui/icons-material/Storage';
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp';
import PageTopSection from '../../../common-components/pageTop-section/PageTopSection';
import ContainerWrapper from '../../../utils/custom-tags/ContainerWrapper';
import "./Alhexa.scss";
import CardWithLeftHeader from '../../../common-components/card/CardWithLeftHeader';
import CardChip from '../../../common-components/card/CardChip';
import FluidContainerWrapper from '../../../utils/custom-tags/FluidContainerWrapper';
import language from "../../../../assets/tecch-stack-icons/language.svg";
import handShake from "../../../../assets/tecch-stack-icons/handShake.svg";
import platform from "../../../../assets/tecch-stack-icons/platform.svg";
import Algo from "../../../../assets/tecch-stack-icons/Algo.svg";
import library from "../../../../assets/tecch-stack-icons/library.svg";
import applicatio_approach from "../../../../assets/tecch-stack-icons/applicatio-approach.svg";
const Alhexa = () => {

    const featureLitsArr = [
        {
            icon: <StorageIcon />,
            title: "Data Protection",
            description: 'Secures all confidential information through the API platform'
        },
        {
            icon: <ThumbUpIcon />,
            title: "Versatile Applications",
            description: 'Can be deployed to a range of industries for multiple purposes as per requirements'
        },
        {
            icon: <CodeIcon />,
            title: "Development Technology",
            description: 'Harnesses AI & ML technologies that can be deployed seamlessly using MLflow and NVIDIA Triton'
        },
        {
            icon: <PeopleAltIcon />,
            title: "User’s Intent Identification",
            description: 'f the users are not specific about their questions, AlHexa will suggest similar questions related to their queries'
        },
        {
            icon: <FullscreenIcon />,
            title: "Scalable",
            description: 'The most user-friendly chatbot that can be implemented for your use cases.'
        },
        {
            icon: <EmojiObjectsIcon />,
            title: "Speech Recognition",
            description: 'Allows users to give input by speaking.'
        },
        {
            icon: <ChatTwoToneIcon />,
            title: "Speech Synthesis",
            description: 'Converts chatbot responses into voice.'
        },
    ]
    return (
        <Grid container className='alhexa-main-container paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2021/08/chat-bot-alexa.svg"}
                h1Heading={"Empower your HR Department with ALHEXA"}
                colorH1Heading={"AI-Powered HR Chatbot"}
                textSectionA={"Bring value to your business by adopting AI technology that brings tangible ROI to your organization."}
                isScheduleCall={true}
            />
            <ContainerWrapper className='alhexa-section-B Section'>
                <Grid container wrap='wrap'>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="text-wrapper element-widget-wrap">
                            <h2>Challenges faced by HR Professionals</h2>
                            <p>
                                HR challenges are often driven by factors that apply pressure to meet the demands of a fast-paced, digitally-driven environment. Identifying and overcoming these challenges is something that is worthy of spending time, money and effort.
                            </p>
                            <div className='hexa-default-btn'>
                                <p>Get in Touch <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="cards-container">
                            <div className="cards-wrapper flex-center">
                                <CardChip icon={<ThumbUpIcon />}
                                    id="first-el"
                                    descriptionText={"Inefficient HR processes"}
                                />
                                <CardChip icon={<ThumbUpIcon />}
                                    descriptionText={"Inefficient HR processes"}
                                />
                            </div>
                            <div className="cards-wrapper flex-end">
                                <CardChip icon={<ThumbUpIcon />}
                                    descriptionText={"Inefficient HR processes"}
                                />
                                <CardChip icon={<ThumbUpIcon />}
                                    descriptionText={"Inefficient HR processes"}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </ContainerWrapper>
            <ContainerWrapper className='element-row Section'>
                <Grid container wrap='wrap'>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div>
                            <img width="100%" src='https://hexaviewtech.com/wp-content/uploads/2021/08/chat-screen-alhexa.svg' />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div className="text-wrapper element-widget-wrap" style={{ marginLeft: '3em' }}>
                            <h2>Resolve Your HR Department problems with an AI-powered HR chatbot</h2>
                            <div className="lists-item">
                                <ul>
                                    <li><CheckCircleOutlinedIcon className='icon' /><span>Resolve employees' general queries</span></li>
                                    <li><CheckCircleOutlinedIcon className='icon' /><span>Guide employees through various company processes and policies</span></li>
                                    <li><CheckCircleOutlinedIcon className='icon' /><span>Link to relevant documents</span></li>
                                    <li><CheckCircleOutlinedIcon className='icon' /><span>Handle routine HR requests</span></li>
                                    <li><CheckCircleOutlinedIcon className='icon' /><span>Can be customized as per the requirements for any industry</span></li>
                                </ul>
                            </div>
                            <div className='hexa-default-btn'>
                                <p>Talk to our Expert <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </ContainerWrapper>
            <ContainerWrapper className='alhexa-section-D Section'>
                <Grid container wrap='wrap'>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="text-wrapper element-widget-wrap">
                            <h2>Resolve Your HR Department problems with an AI-powered HR chatbot</h2>
                            <p >A paradigm shift towards digitization and the adoption of advanced technologies is essential for any business looking to grow and stay ahead of the curve. ALHexa, the HR Virtual Assistant, frees up the human resource individual from performing repetitive tasks helping them to focus on other value-added activities that boost performance and productivity.</p>
                            <p>ALHexa gives first-class service to the employees by resolving their general queries with accurate details on time. Our HR chatbot can rejuvenate the HR services.</p>
                            <div className='hexa-default-btn'>
                                <p>Talk to our Expert <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="cards-container">
                            <div className="cards-wrapper first-element">
                                <CardChip icon={<SearchOutlinedIcon />}
                                    descriptionText={"Easier search and information access with accurate"}
                                />
                            </div>
                            <div className="cards-wrapper second-element">
                                <CardChip icon={<ThumbUpIcon />}
                                    descriptionText={"Facilitating lower costs through automation"}
                                />
                                <CardChip icon={<ThumbUpIcon />}
                                    descriptionText={"Conversational & involving employees by providing a wide"}
                                />
                            </div>
                            <div className="cards-wrapper">
                                <CardChip icon={<SupportAgentSharpIcon />}
                                    descriptionText={"Expanding HR availability to 24*7"}
                                />
                                <CardChip icon={<GroupsSharpIcon />}
                                    descriptionText={"Improving employee retention"}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </ContainerWrapper>
            <ContainerWrapper className='element-row Section'>
                <Grid container wrap='wrap'>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className="text-wrapper element-widget-wrap">
                            <h2>How ALHexa works?</h2>
                            <div className="lists-item">
                                <ul>
                                    <li><CheckCircleOutlinedIcon className='icon' /><span>Resolve employees' general queries</span></li>
                                    <li><CheckCircleOutlinedIcon className='icon' /><span>Guide employees through various company processes and policies</span></li>
                                    <li><CheckCircleOutlinedIcon className='icon' /><span>Link to relevant documents</span></li>
                                </ul>
                            </div>
                            <p>With ALHexa, employees can find all the information related to company norms and policies, obtain their personal information like leave balance, appraisal cycle, etc., and stay updated with the latest company news and events.</p>
                            <p>Our conversational HR chatbot leverages machine learning algorithms to enable the users to similarly speak to the machine as they do to a human. If the employees are not specific about their questions, ALHexa suggests similar questions related to their queries.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div>
                            <img width="100%" src='https://hexaviewtech.com/wp-content/uploads/2021/08/alhexa-video-banner.svg' />
                        </div>
                    </Grid>
                </Grid>
            </ContainerWrapper>
            <ContainerWrapper className='tech-stack-element-row Section'>
                <div className="heading-wrapper">
                    <h2>Tech Stack</h2>
                    <div className='element-devider'><span className='divider' /></div>
                </div>
                <Grid container wrap='wrap'>
                    <CardWithLeftHeader
                        headerImgUrl={language}
                        contentHeading="Languages"
                        descriptionText={"Python & React.JS"}
                        id='w-25'
                    />
                    <CardWithLeftHeader
                        headerImgUrl={handShake}
                        contentHeading="Toolkit"
                        descriptionText={"NVIDIA Nemo (based on PyTorch), NeMo ASR (Automatic Speech Recognition), NeMo TTS(Text to Speech), NeMo NLP (Natural language processing)"}
                        id='w-50'
                    />
                    <CardWithLeftHeader
                        headerImgUrl={platform}
                        contentHeading="Platform"
                        descriptionText={"Mlflow"}
                        id='w-25'
                    />

                    <CardWithLeftHeader
                        headerImgUrl={Algo}
                        contentHeading="Algorithms"
                        descriptionText={"Neural Networks, BERT"}
                        id='w-25'
                    />
                    <CardWithLeftHeader
                        headerImgUrl={library}
                        contentHeading="Libraries"
                        descriptionText={"Spell-checker, Google vocab, Gensim library - for word2vec model, etc., NLTK library - for TF - IDF and text pre-processing, etc., Flask, sklearn, Joblib, pathlib, OS, pickel, JSON."}
                        id='w-50'
                    />
                    <CardWithLeftHeader
                        headerImgUrl={applicatio_approach}
                        contentHeading="Additonal Approach"
                        descriptionText={"Word2Vector + TF_IDF + Global Corpus"}
                        id='w-25'
                    />
                </Grid>
            </ContainerWrapper>
            <FluidContainerWrapper className='features-element-row Section'>
                <div className='background-overlay'

                />
                <div className="heading-wrapper element-widget-wrap">
                    <h2>Features</h2>
                    <p>ALHexa gives first-class service to the employees by resolving their general queries</p>
                </div>
                <Grid container wrap='wrap' className='element-widget-wrap'>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="text-wrapper">
                            <div className="lists-item">
                                {
                                    featureLitsArr.map(item => (
                                        <CardWithLeftHeader
                                            headerIcon={item.icon}
                                            contentHeading={item.title}
                                            descriptionText={item.description}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div>
                            <img width="100%" src='https://hexaviewtech.com/wp-content/uploads/2021/08/features-img-alhexa.svg' />
                        </div>
                    </Grid>
                </Grid>
            </FluidContainerWrapper>

        </Grid>
    );
}

export default Alhexa;
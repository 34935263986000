import React from 'react';
import "./PageTopSection.scss";
type Props = {
    img?: String,
    colorH1Heading?: String,
    h1Heading?: String,
    textSectionA?: String,
    textSectionA2?: String,
    textSectionB?: String,
}
const SecondaryTopPageSection = ({
    img,
    colorH1Heading,
    h1Heading, textSectionA,
    textSectionA2,
    textSectionB,
}: Props) => {
    return (
        <div className='contact-us-section'
            style={{
                backgroundImage: `url(${img})`,
                backgroundRepeat: 'no-repeat',
                width: '100%',
                backgroundSize: 'cover'
            }}
        >
            <div className='img-background-overlay'></div>
            <div className='text-section'>
                <div className='element-image-text-wrapper'>
                    <h1 className='heading-text'>{h1Heading}</h1>
                    <h1 className='color-heading'>{colorH1Heading}</h1>
                    <div className='text-section-a'>{textSectionA}</div>
                    <div className='text-section-a'>{textSectionA2}</div>
                    <div className='text-section-b'>{textSectionB}</div>
                </div>
            </div>
        </div>
    );
}

export default SecondaryTopPageSection;
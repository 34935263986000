import React from 'react'
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection'
import ContainerWrapper from '../../../../utils/custom-tags/ContainerWrapper'
import DsBanner from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/On-Demand-Solutions-banner.jpg';
import CardWithLeftHeader from '../../../../common-components/card/CardWithLeftHeader';
import { Card, Grid } from '@mui/material';
import { IconText } from '../../../corporate-overview/corporate-social-responsibility/women-welfare-network/WomenWelfareNetwork';
import Unity from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/unity.svg';
import Security from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/security.svg';
import Grad from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/grad.svg';
import People from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/people.svg';
import House from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/house.svg';
import BlockChain from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/blockchain.svg';
import Payment from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/payment.svg';
import Cycle from '../../../../../assets/FinTech-wealth-management/capital-market/cycle.svg';
import Settings from '../../../../../assets/FinTech-wealth-management/capital-market/settings.svg';
import Hands from '../../../../../assets/FinTech-wealth-management/capital-market/hands.svg';
import CaseStudy from '../../../../common-components/caseStudy/Index';
import DBOpt from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/database-optimization.webp';
import Implementation from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/implementation.webp';
import WebApp from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/web-application.webp';
import CustomSSRS from '../../../../../assets/FinTech-wealth-management/OnDemandSolution/custom-SSRS.webp';
import '../FinTechCommon.scss';
const DemandSolution = () => {

    const casestudies = [
        {
            image: DBOpt,
            heading2: "Performed Database Optimization",
            text: "A leading digital product agency offering services necessary to build and manage robust digital products. They were facing database operational capabilities were not competent."
        },
        {
            image: Implementation,
            heading2: "Established a User-Friendly Payment Management",
            text: "The client wanted a payment management portal that would ease the process of entering payments and executing them..."
        },
        {
            image: WebApp,
            heading2: "Developed a unified web application to access all the",
            text: "A well-established fund administrating firm based in the United States was following a tedious manual process..."
        }, {
            image: CustomSSRS,
            heading2: "Creating custom SSRS reports using Advent Portfolio",
            text: "Our client specializes in client advisory, portfolio management & reporting, which required the creation of custom SSRS."
        },

    ]
    return (
        <div className="main-container paddingTopSection">
            <PageTopSection
                img={DsBanner}
                colorH1Heading={"REVOLUTIONIZING FINTECH"}
                textSectionA={"By leveraging AI-driven technologies with human expertise, we provide advanced, tailored solutions to accomplish goals and reduce risk—all at a fraction of the usual time and expense."} />
            <ContainerWrapper className="ds-section-A Section">
                <div className="heading">
                    <h3>Some of Features</h3>
                </div>
                <div className="text-section">
                    <p>The features of our On-Demand solution services ensure that you get the best-customized solution according to your need.</p>
                </div>
                <Grid container spacing={2} rowSpacing={2}>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                        <Card className="card-left">
                            <CardWithLeftHeader
                                headerImgUrl={Unity}
                                contentHeading={"Powered with AI and ML"}
                                descriptionText={"We serve you solutions imbibed with deft AI and ML tools to maximize efficiency."} /></Card>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                        <Card className="card-right">
                            <CardWithLeftHeader
                                headerImgUrl={Security}
                                contentHeading={"Personalized Features"}
                                descriptionText={"Customized tools and add-ons give you a personalized approach to your enterprise."} /></Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2} rowSpacing={2}>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                        <Card className="card-left">
                            <CardWithLeftHeader
                                headerImgUrl={Grad}
                                contentHeading={"Data Handling"}
                                descriptionText={"Robust solutions manage massive amounts of data, keeping it organized and accessible for multiple users."} /></Card>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                        <Card className="card-right">
                            <CardWithLeftHeader
                                headerImgUrl={People}
                                contentHeading={"Resolute Customer Support"}
                                descriptionText={"Hexaview ensures superior customer support and takes full account of your feedback for optimum ongoing service."} /></Card>
                    </Grid>
                </Grid>

            </ContainerWrapper>

            <ContainerWrapper className="ds-section-B Section">

                <div className="heading">
                    <h2 style={{ fontSize: "2.7rem" }}>Industries We Serve</h2>
                </div>

                <div className="text-section">
                    <p>Hexaview delivers enterprise solutions to a spectrum of FinTech clients. We utilize cutting-edge technology with AI and ML support to enhance user experience and operations. Our 10+ years as industry pioneers have sensitized us to the needs and hurdles encountered by rapidly expanding organizations. We’ve cultivated our awareness to develop an innovative approach focused on reliable solutions that overcome these challenges.</p>
                </div>
                <Grid container spacing={4} wrap='wrap' style={{ marginTop: "20px" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="card-styling">
                            <IconText
                                image={Payment}
                                heading={"Payments & Billings"}
                                textSection={"Hexaview understands the adaptive approach required to transform your billing experience, hence provide you with tailored solutions to eradicate substantial risks in strategy, compliance, operations, and cybersecurity."} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="card-styling">
                            <IconText
                                image={Hands}
                                heading={"Insurance"}
                                textSection={"Our team creates a rich UI and UX atmosphere leveraging the most advanced technologies to advance your business's digital presence and customer experience."} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="card-styling">
                            <IconText
                                image={Cycle}
                                heading={"Personal Finance/ Money Transfer"}
                                textSection={"Our solutions are backed with smart technologies to tackle hurdles like remittance settlement, multiple payment gateways, and security threats resulting in a complete digitally transformed customer experience."} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="card-styling">
                            <IconText
                                image={Settings}
                                heading={"RegTech"}
                                textSection={"Hexaview's RegTech solutions provide an agile platform for your enterprise to overcome the challenges associated with regulatory compliance. You'll no longer worry about rising compliance costs or access to Software-as-a-Service (SaaS) as we style our solutions to minimize the time and enhance your operational efficiency."} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="card-styling">
                            <IconText
                                image={BlockChain}
                                heading={"Blockchain"}
                                textSection={"Ever since Bitcoin entered the market, blockchain technology has become one of the most prominent trends of the last decade. We contribute to this technology by creating solutions that overcome its challenges, especially related to the network's technical scalability."} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="card-styling">
                            <IconText
                                image={House}
                                heading={"Real Estate/Mortgage"}
                                textSection={"Hexaview provides practical solutions to conquer the complex data and analytics hurdles in the real estate and mortgage industry. Our tools identify, collate, and analyze relevant data to enable informed decision-making and accelerate the end-user experience. "} />
                        </Card>
                    </Grid>
                </Grid>
            </ContainerWrapper>

            {/* <CaseStudy array={casestudies} /> */}

        </div>
    )
}

export default DemandSolution
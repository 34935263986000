import React from 'react';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import '../FinTechCommon.scss';
import lendingBanner from '../../../../../assets/FinTech-wealth-management/lending/lending-solution-banner-2.jpg';
import ContainerWrapper from '../../../../utils/custom-tags/ContainerWrapper';
import { Grid } from '@mui/material';
import img from '../../../../../assets/image.svg';
import lendingCard from '../../../../../assets/FinTech-wealth-management/lending/left-card-image.png';
import { CommonCard } from '../wealth-management/WealthManagement';
import automation from '../../../../../assets/FinTech-wealth-management/automation.svg'
import SecuritySymbol from '../../../../../assets/FinTech-wealth-management/lending/security.svg';
import Cycle from '../../../../../assets/FinTech-wealth-management/capital-market/cycle.svg';
import Devices from '../../../../../assets/FinTech-wealth-management/capital-market/devices.svg';
import CaseStudy from '../../../../common-components/caseStudy/Index';
import DigitalTransaction from '../../../../../assets/FinTech-wealth-management/lending/digital-transaction.webp';
import CS from '../../../../../assets/FinTech-wealth-management/lending/case-study.png';
const Lending = () => {

    const caseStudies = [
        {
            image: DigitalTransaction,
            heading2: "Integrating third-party digital transaction management system in the existing application",
            text: "The client is a leading advisory firm that helps its clients with low credit scores by providing credit repair solutions. They wanted an automated system of generating documents, signed digitally so that the documents could be managed in a more organized and centralized way."
        },
        {
            image: CS,
            heading2: "America’s leading direct leader expands their offering with our offshore captive center",
            text: "We migrated the client’s conventional data management system to Salesforce CRM to have better insights & save costs. We have used the Cloud Azure Computing platform & Power BI that helped in better management of massive data."
        },
    ]
    return (
        <div className='main-container paddingTopSection'>
            <PageTopSection
                img={lendingBanner}
                h1Heading={"A SMOOTHER AND FASTER"}
                colorH1Heading={"LENDING SOLUTION"}
                textSectionA={"Our certified team of experts brings you comprehensive fraud-proof automation lending software, empowering you to originate more loans and initiate smarter decisions in a cost-effective method."} />

            <ContainerWrapper className="lending-section-A Section">
                <Grid container wrap='wrap'>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="heading">
                            <h2>Challenges of Lending Software Solutions</h2>
                        </div>
                        <div className="text-section">
                            <p>The lending landscape is continuously evolving. Modern customers are tech-savvy and looking for a fully digital & easy to use experience. Also, customer satisfaction has become an integral aspect of every industry. As a leading service provider, it is never easy to rely on outdated mechanisms. To keep up with the growing competition, firms need to focus on custom lending software solutions that enhance customer satisfaction and helps your business to grow exponentially. Also, the firms should be continuously investing in technological integrations and innovations, that could ease the customer problems and challenges.</p>
                        </div>
                        <div className="hexa-primary-btn">
                            <p>Apply for A job <img src={img} alt="Image Button" /></p>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="image-section">
                            <img style={{ width: "550px", height: "500px" }} src={lendingCard} alt="Main Image" />
                        </div>
                    </Grid>
                </Grid>
            </ContainerWrapper>

            <ContainerWrapper className="lending-section-B Section">
                <div className="heading">
                    <h2>Custom Lending Software Solutions</h2>
                </div>

                <div className="text-section">
                    <p>We develop custom lending software solutions to streamline your processes and put you at the forefront of the competition. We offer lending software development services and deliver robust & scalable solutions to automate the cumbersome process of lending and loan management. We have been servicing the lending industry for over a decade and are continuously providing lending software development services to our clients all across the globe. We have gained immense expertise in providing integrated lending software development services like cloud lending software, Peer to Peer (P2P) lending software, loan management software, loan origination software, loan monitoring software, and many more. </p>
                </div>
                <div className='card-wrapper'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <CommonCard
                                img={Cycle}
                                backgroundColor={"#ffffff"}
                                heading={"Big Data Analytics"}
                                text={"Boost your efficiency and deliverability with our data analytics solutions and anticipate the market demands facilitating decision-making."}
                                list={[
                                    "Compile customer data from disparate sources into a single unified view.",
                                    "Handle massively different data formats with absolute ease.",
                                    "Data mining systems expose logic patterns to inform your decision-making.",
                                    "Regularly reconcile data to avoid costly discrepancies.",
                                    "Integrate data quickly and effortlessly to accommodate online transactions."]}
                                color="#3A3CC8" /></Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <CommonCard
                                img={SecuritySymbol}
                                backgroundColor={"#ffffff"}
                                heading={"Security"}
                                text={"Our secure software solutions will safeguard vulnerable client information while cutting costs on the backend."}
                                list={[
                                    "Secure data storage in encrypted form.",
                                    "Use SSL to protect server-client communication and improve your customers' trust.",
                                    "Software penetration testing readily detects threats.",
                                    "Multi-factor authentication adds additional layers of protection."]}
                                color="#28BB8D" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <CommonCard
                                img={Devices}
                                backgroundColor={"#ffffff"}
                                heading={"Custom Workflow Design"}
                                text={"A custom lending management solution sustains your business as it grows."}
                                list={[
                                    "Boutique workflows facilitate all lending options.",
                                    "Workflows smoothly adjust and reframe for peak user experience.",
                                    "Artificial Intelligence and Machine Learning algorithms provide secure identity verification.",
                                    "Forecast your income by evaluating a borrower's history and collateral.",
                                    "Simplify steps through an intuitive and customizable dashboard."]}
                                color="#D91B1B" />
                        </Grid>
                    </Grid>
                </div>
            </ContainerWrapper>

            {/* <CaseStudy array={caseStudies} slidesToShow={2} /> */}
        </div>
    )
}

export default Lending
import React,{useEffect} from 'react'
import ContainerWrapper from '../../../utils/custom-tags/ContainerWrapper';
import '../copyright.scss';
import Flags from '../../../../assets/Footer Images/flags.png';
const LegalNotice = () => {

    useEffect(() => {
        return () => {
            restoreGoogleTranslator();
        };
    }, []);

    const restoreGoogleTranslator = ()=>{
        var iframe:any = document.getElementsByClassName('goog-te-banner-frame')[0];
            if(!iframe) return;
          
            var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
            var restore_el = innerDoc.getElementsByTagName("button");
          
            for(var i = 0; i < restore_el.length; i++){
              if(restore_el[i].id.indexOf("restore") >= 0) {
                restore_el[i].click();
                var close_el = innerDoc.getElementsByClassName("goog-close-link");
                close_el[0].click();
                return;
              }
            }
    }
    const lstA = [
        "Hexaview Technologies, Inc.",
        "#7, Quaker Dr., East Brunswick,",
        "New Jersey, 08816, USA",
        "hello@hexaviewtech.com",
        "+1 (646) 403-4525"]
    return (
        <div className='main-container'>
            <div className="main-heading">
                <div className="heading">
                    <h1>Legal Notice</h1>
                </div>
                <div className="google-translate">
                    <div id="google_translate_element"></div>
                </div>
            </div>
            <ContainerWrapper className="Section">
                <div className="section">
                    <div className="heading"><h4>Owner</h4></div>
                    <div className="text-section">
                        <ul>
                            {lstA.map((item) => {
                                return (
                                    <li> {item}</li>
                                )
                            })}
                        </ul>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Contents of the online offer</h4>
                    </div>
                    <div className="text-section">
                        <p>The contents of this website are created with the greatest possible care. The author accepts no responsibility for the topicality, correctness, completeness or quality of the information provided. Liability claims against the author, which refer to material or non-material damages, which have been caused by the use or non-use of the information provided or by the use of incorrect and incomplete information, are fundamentally excluded, provided that there is no demonstrable intentional or grossly negligent fault on the part of the author. The author expressly reserves the right to change, supplement or delete parts of the pages or the entire offer without separate announcement or to discontinue the publication temporarily or permanently.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>References and links</h4>
                    </div>
                    <div className="text-section">
                        <p>In the case of direct or indirect references to external Internet pages (“links”), which are outside the author’s area of responsibility, a liability obligation would only come into force in the event that the author is aware of the contents and it would be technically possible and reasonable for him to prevent use in the event of illegal contents. The author hereby expressly declares that at the time the links were created, no illegal content was discernible on the linked pages. The author has no influence on the current and future design, content or authorship of the linked pages. Therefore, he hereby expressly distances himself from all contents of all linked pages that were changed after the link was set. This statement applies to all links and references set within the author’s own Internet offer, as well as to external entries in discussion forums and mailing lists set up by the author. For illegal, incorrect or incomplete contents and in particular for damages resulting from the use or non-use of such information, the provider of the page to which reference is made shall be solely liable, not the person who merely refers to the respective publication via links.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Data protection</h4>
                    </div>
                    <div className="text-section">
                        <p>If the opportunity for the input of personal or business data (email addresses, name, addresses) is given, the input of these data takes place voluntarily. Further information is available in our privacy and cookie policy.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Validity of this disclaimer</h4>
                    </div>
                    <div className="text-section">
                        <p>This disclaimer is to be regarded as part of the internet publication which you were referred from. If sections or individual terms of this statement are not legal or correct, the content or validity of the other parts remain uninfluenced by this fact.</p>
                    </div>
                </div>

                <div className="section">
                    <div className="heading">
                        <h4>Copyright</h4>
                    </div>
                    <div className="text-section">
                        <p>For the website design, the text, all graphics and photos, each selection or layout thereof: Copyright © 2021 Hexaview Technologies, Inc. Copying or reproduction of the entire website or parts of this website are not permitted. Any other use of the materials or information available on this website – including reproduction, further distribution, modification, and publication for any purpose is prohibited, unless Hexaview Technologies, Inc. has given its prior written consent. The word ReportWa® is the trademark of Hexaview Technologies Inc.</p>
                    </div>
                </div>
            </ContainerWrapper>
        </div>
    )
}

export default LegalNotice
import React from 'react'
import './TechStack.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Card, Grid } from '@mui/material';
import SecondaryTopPageSection from '../../../common-components/pageTop-section/SecondaryTopPageSection';
import AIML from '../../../../assets/corporate-overview/techStack/aiml.webp';
import Architecture from '../../../../assets/corporate-overview/techStack/arch.webp';
import Database from '../../../../assets/corporate-overview/techStack/dbs.webp';
import DevOps from '../../../../assets/corporate-overview/techStack/devops.webp';
import MobileDev from '../../../../assets/corporate-overview/techStack/MobileDev.webp';
import Salesforce from '../../../../assets/corporate-overview/techStack/salesforce.webp';
import Testing from '../../../../assets/corporate-overview/techStack/testing.webp';
import Tools from '../../../../assets/corporate-overview/techStack/tools.webp';
import WebDev from '../../../../assets/corporate-overview/techStack/webDev.webp';

const TechStack = () => {
    return (
        <div className="tech-stack-main-container paddingTopSection">
            <SecondaryTopPageSection
                img={"https://hexaviewtech.com/wp-content/uploads/2020/12/Tech-Stack-1.png"}
                h1Heading="Tech Stack"
                textSectionA2={"Transforming Business with Technology"}
            />

            <section className="tech-section-B Section">
                <div className="container">
                    <div className="image-section">
                        <img src={WebDev} alt="" />
                    </div>
                    <div className="heading">
                        <h2>Web Development</h2>
                    </div>
                    <Grid container spacing={4} wrap='wrap'>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Card className='card-styling-B'>
                                <div className="heading">
                                    <h3>Frontend</h3>
                                </div>
                                <Grid className='inner-card-padding' container wrap='wrap'>
                                    <CommonCard
                                        subHeadingA={"Languages"}
                                        techArrA={["Java", "HTML5", "CSS", "JavaScript"]} />
                                    <CommonCard
                                        subHeadingA={"Framework&Library"}
                                        techArrA={["AngularJS", "ReactJS", "React Native", "jQuery", "Ionic", "SASS"]} />
                                </Grid>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Card className='card-styling-B'>
                                <div className="heading">
                                    <h3>Backend</h3>
                                </div>
                                <Grid className='inner-card-padding' container wrap='wrap'>
                                    <div className="card-inner-content">
                                        <CommonCard
                                            subHeadingA={"Languages"}
                                            techArrA={["PHP", "Ruby", " C#", "C++", "Python", "JavaScript", "Node.js", ".NET"]} />
                                    </div>
                                    <div className="inner-card-content">
                                        <CommonCard
                                            subHeadingA={"Framework&Library"}
                                            techArrA={["Django", "Ruby on Rails", "Springboot", "Flask", "Laravel", "ExpressJS", "ASP.NET", "NET MVC", "Visual Basic", "Entity Framework"]} />
                                    </div>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </section>

            <section className="tech-section-C Section">
                <div className="container">
                    <div className="image-section">
                        <img src={MobileDev} alt="" />
                    </div>

                    <div className="heading">
                        <h2>Mobile Development</h2>
                    </div>

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Card className="card-styling-B">
                                <CommonCard
                                    heading={"iOS"}
                                    subHeadingA={"Languages"}
                                    techArrA={["Swift", "Objective C", "Swift UI"]}
                                    subHeadingB={"Framework & Library"}
                                    techArrB={["Cocoa Pods", "Firebase", "CluodKit", "MapKit"]}
                                    subHeadingC={"Software"}
                                    techArrC={["Xcode"]} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Card className="card-styling-B">
                                <CommonCard
                                    heading={"Android"}
                                    subHeadingA={"Languages"}
                                    techArrA={["Kotlin", "Java"]}
                                    subHeadingB={"Framework & Library"}
                                    techArrB={["Spring Rest Templates", "Sencha Touch", "Appcelerator Titanium"]}
                                    subHeadingC={"Software"}
                                    techArrC={["Android Studio", "Greenrobot Eventbus", "Sweet Alert Dialog"]} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Card className="card-styling-B">
                                <CommonCard
                                    heading={"Cross Platform"}
                                    subHeadingA={"Languages"}
                                    techArrA={["C#", "Dart", "JS / TS"]}
                                    subHeadingB={"Framework & Library"}
                                    techArrB={["Flutter", "Ionic", "React Native", "Xamarin"]}
                                    subHeadingC={"Software"}
                                    techArrC={["Visual Studio"]} />
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </section>

            <section className="tech-section-D Section">
                <div className="container">
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Card className="card-styling-B">
                                <CommonCard
                                    icon={AIML}
                                    heading={"AI & ML"}
                                    techArrA={["NLP", "Chatbots", "Image Processing", "Amazon Textract", "ASR", "OCR", "KNN Algorithm"]} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Card className="card-styling-B">
                                <CommonCard
                                    icon={Database}
                                    heading={"Database"}
                                    techArrA={["Microsoft SQL Server", "MongoDB", "MySQL", "Oracle", "PostgreSQL", "DynamoDB"]} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Card className="card-styling-B">
                                <CommonCard
                                    icon={Salesforce}
                                    heading={"CRM - Salesforce"}
                                    techArrA={["Salesforce1", "Apex", "Lightning", "Tableau", "Mobile SDK", "MuleSoft", "Salesforce DX & CLI", "Einstein AI"]} />
                            </Card>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                            <Card className="card-styling-B">
                                <CommonCard
                                    icon={Architecture}
                                    heading={"Architecture"}
                                    techArrA={["SOA", "DDD", "Microservices Architecture"]} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                            <Card className="card-styling-B">
                                <CommonCard
                                    icon={Testing}
                                    heading={"Testing"}
                                    subHeadingA={"Automation"}
                                    techArrA={["Appium", "Selenium", "Mocha"]}
                                    subHeadingB={"Manual"}
                                    techArrB={["Black box testing", "White box testing"]} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                            <Card className="card-styling-B">
                                <CommonCard
                                    icon={Tools}
                                    heading={"Tools"}
                                    techArrA={["Tesseract", "PowerBI", "QlikView", "MS Excel", "BIRT", "Hibernate/ NHibernate", "Github"]} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                            <Card className="card-styling-B">
                                <CommonCard
                                    icon={DevOps}
                                    heading={"DevOps"}
                                    techArrA={["Docker", "Azure", "AWS", "Jenkins", "Kubernetes/Docker Swarm", "Google Services"]} />
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </section>
        </div>


    )
}

interface props {
    icon?: string,
    heading?: String,
    subHeadingA?: String,
    subHeadingB?: String,
    subHeadingC?: String,
    techArrA?: any,
    techArrB?: any,
    techArrC?: any
}
export const CommonCard = ({ icon, heading, subHeadingA, subHeadingB, subHeadingC, techArrA, techArrB, techArrC }: props) => {
    return (
        <div className="main-container">
            {icon && <div className="image-section">
                <img style={{ height: "150px", width: "150px" }} src={icon} alt="" />
            </div>}
            {heading && <div className="heading">
                <h3>{heading}</h3>
            </div>}
            {subHeadingA && <div className="subHeading-A">
                <h4>{subHeadingA}</h4>
            </div>}
            <ul>
                {techArrA && techArrA.map((item: any) => {
                    return <div style={{ display: "flex", flexDirection: "row" }}> <CheckCircleOutlineIcon style={{ width: "27px", height: "27px", paddingRight: "0.7rem" }} /> <li key={item}> <p> {item} </p> </li></div>
                })}
            </ul>
            <br />
            {subHeadingB && <div className="subHeading-B">
                <h4>{subHeadingB}</h4>
            </div>}
            <ul>
                {techArrB && techArrB.map((item: any) => {
                    return <div style={{ display: "flex", flexDirection: "row" }}> <CheckCircleOutlineIcon style={{ width: "27px", height: "27px", paddingRight: "0.7rem" }} /> <li key={item}> <p> {item} </p> </li></div>
                })}
            </ul>
            <br />
            {subHeadingC && <div className="subHeading-C">
                <h4>{subHeadingC}</h4>
            </div>}
            <ul>
                {techArrC && techArrC.map((item: any) => {
                    return <div style={{ display: "flex", flexDirection: "row" }}> <CheckCircleOutlineIcon style={{ width: "27px", height: "27px", paddingRight: "0.7rem" }} /> <li key={item}> <p> {item} </p> </li></div>
                })}
            </ul>
        </div>
    )
}

export default TechStack
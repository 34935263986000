import React from 'react';
import Grid from '@mui/material/Grid';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import RecentResources from '../common/RecentResources';
import "./BigDataServices.scss";
import CardChip from '../../../../common-components/card/CardChip';
import CardWithLeftHeader from '../../../../common-components/card/CardWithLeftHeader';
import { height } from '@mui/system';
const BigDataServices = () => {
    const bdServiceArr = [
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/Data-Growth.svg',
            description: 'Data Growth Issues'
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/Data-validation.svg',
            description: 'Data Validation'
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/Real-time-insights.svg',
            description: 'Real-time Insights'
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/security-security.svg',
            description: 'Data Security'
        }
    ];

    const cardsArr = [
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/Data-Growth.svg',
            contentHeading: "Data Growth Issues",
            descriptionText: "As these data sets grow with time, it gets hard to manage. Most of the data is unstructured and comes from documents, videos, audio, text files, etc."
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/Data-validation.svg',
            contentHeading: "DevOps Automation & Management",
            descriptionText: "Data validation on a Big Data scale is very complex. An organization can get similar sets of data from multiple sources but the data from these sources may not always be the same."
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/Real-time-insights.svg',
            contentHeading: "Real-time Insights",
            descriptionText: "Data sets don’t have any value if no real-time insights are drawn from them. Now, some may define real-time as instantaneous while others may consider it as the time taken between data extraction and analysis."
        },
        {
            img: 'https://hexaviewtech.com/wp-content/uploads/2022/04/security-security.svg',
            contentHeading: "Data Security",
            descriptionText: "With increasing Data size, Data Security is becoming the need of the hour. When it comes to it, most companies think that they have the right security protocols in which are sufficient for their data sets."
        }
    ]

    return (
        <Grid container className='bd-services-main-container paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/big-data-banner.jpg"}
                h1Heading={"Big Data Services"}
                textSectionA={"Hexaview as a Big Data Consulting Company helps you in unleashing the power of data with our consulting, support, and implementation services."}
                isScheduleCall={true}
            />
            <section className="bd-services-B Section">
                <div className='container'>
                    <Grid container wrap='wrap' className='element-inner-section'>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <div className="image-wrapper">
                                <div>
                                    <img width={'100%'} height={'100%'} src='https://hexaviewtech.com/wp-content/uploads/2022/04/big-data-consulting.png' alt='' />
                                </div>
                            </div>
                            <div className='hexa-primary-btn'>
                                <p>Talk to our Expert <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                            </div>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <div className="text-wrapper element-widget-wrap">
                                <h2><span>Big Data</span><br /> Consulting Services</h2>
                                <div className="text-sextion">
                                    <p>
                                        Hexaview’s Big Data Implementation services frame strategy and roadmap to improve your business. We offer our services across various industries to help you make the most of your data.
                                    </p>
                                </div>
                                <div className="text-sextion" style={{ fontWeight: 'bold' }}>
                                    <p>
                                        At Hexaview our Big Data Consulting Services portfolio aims to solve various business challenges including:
                                    </p>
                                </div>
                                <div className="big-data-service-wrapper">
                                    {
                                        bdServiceArr.map(item => (
                                            <CardChip key={item.description} img={item.img}
                                                descriptionText={item.description}
                                            />
                                        ))
                                    }
                                </div>
                                <div className="text-sextion">
                                    <p>Being one of the fastest-growing Big Data Consulting companies, Hexaview is the perfect partner in the transformation journey of your business. Our Big Data Consulting Services (BDaaS) ensures that data is used at its best.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="bd-services-C Section">
                <div className='container'>
                    <div className="heading element-widget-wrap">
                        <h2><span>Challenges our big data</span> consultants can resolve</h2>
                    </div>
                    <div className='cards-wrapper'>
                        {cardsArr.map(item => (
                            <CardWithLeftHeader
                                headerIcon={<img src={item.img} />}
                                contentHeading={item.contentHeading}
                                descriptionText={item.descriptionText}
                            />
                        ))}
                    </div>
                    <div className='hexa-primary-btn'>
                        <p>Talk to our Expert <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                    </div>
                </div>
            </section>
            <section className='inner-section'>
                <PageTopSection img={"	https://hexaviewtech.com/wp-content/uploads/2022/04/Why-choose-Hexaview-for-Big-Data-Development.jpg"}
                    h1Heading={"Why choose Hexaview for Big Data Development Services?"}
                    textSectionA={"We have a large pool of talented resources who can help you in optimizing your data. With our Big Data Managed Services, you can create big data processes right from the scratch starting from collection to integration and visualization."}
                    textSectionB={"Wish To Avail Our Big Data Application Development Services? "}
                    isScheduleCall={false}
                />
            </section>
            <section className="bd-services-D Section">
                <div className="heading-wrapper element-widget-wrap">
                    <h3><span>Organizations that trusts</span><br /> our Big Data Services</h3>
                </div>
                <div className='img-container'>
                    <img width={"300"} src='https://hexaviewtech.com/wp-content/uploads/2021/06/adobe.svg' />
                    <img width={"300"} src='https://hexaviewtech.com/wp-content/uploads/2021/06/Nestle.svg' />
                </div>
            </section>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/Big-Data-Consulting.jpg"}
                h1Heading={"Would you like to know how Hexaview can be the Big Data Consulting Company for your needs?"}
                isScheduleCall={false}
                btnText={"Book your free consultation here"}
            />
            <RecentResources />
        </Grid>
    );
}

export default BigDataServices;

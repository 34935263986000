import React from 'react';
import Grid from '@mui/material/Grid';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import CloudGetTouchBox from '../commonSection/cloudGetTouchBox/Index'
import TrustBy from '../commonSection/trustBy/Index'
import ServicePartner from '../commonSection/servicePartner/Index'
import "./style.scss";
import IncreasedSpeedFlexibility from '../../../../../assets/cloud/Increased-speed-and-flexibility.svg'
import AutomaticScaling from '../../../../../assets/cloud/Automatic-scaling.svg'
import disasterRecovery from '../../../../../assets/cloud/disaster-recovery.svg'
import ReducedManagementCosts from '../../../../../assets/cloud/Reduced-management-costs.svg'
import SimplifiedTrafficSpikeHandling from '../../../../../assets/cloud/Simplified-traffic-spike-handling.svg'
import UniversallyAccessible from '../../../../../assets/cloud/Universally-accessible.svg'
import cloudMigrationsImg from '../../../../../assets/cloud/cloud-migrations-img1.webp'
import manageService1 from '../../../../../assets/cloud/manage-service-1.webp'
import PaymentServices from '../../../../../assets/cloud/payment-management-portal.png';
import Sheet from '../../../../../assets/cloud/svgexport-21.svg'
import Weight from '../../../../../assets/cloud/svgexport-20.svg'
import Security from '../../../../../assets/FinTech-wealth-management/lending/security.svg';
import TalkToExpert from '../../../../../assets/cloud/talk-to-expert.svg';

const Index = () => {

    const advantages = [
        {
            text: "Increased speed and flexibility",
            img: IncreasedSpeedFlexibility
        },
        {
            text: "Simplified traffic spike handling",
            img: SimplifiedTrafficSpikeHandling
        },
        {
            text: "Automatic scaling of server resources",
            img: AutomaticScaling
        },
        {
            text: "Reduced management costs",
            img: ReducedManagementCosts
        },
        {
            text: "Universally accessible",
            img: UniversallyAccessible
        },
        {
            text: "Enhanced disaster recovery planning",
            img: disasterRecovery
        }
    ]

    return (
        <Grid container className='cloud-migration-solutions paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/cloud-banner.jpg"}
                h1Heading="Cloud Migration Solutions"
                textSectionA={"Hexaview’s cloud migration solutions and services help you migrate your data to the cloud with minimal disruption while modernizing your business."}
                isScheduleCall={true}
            />
            {/*...service text section...*/}
            <section className='section-B Section'>
                <div className='container'>
                    <Grid item xs={12}>
                        <div className="heading">
                            <h2><span>Why is Cloud Migration</span> Important to Business Growth?</h2>
                        </div>
                        <div className="text-section element-widget-wrap">
                            <p>
                                Cloud Migration is when an organization’s databases, applications, and other business elements are moved from the local server to the cloud servers. With lots of innovative features and an unending array of business benefits, the Cloud computing trend has grown significantly in recent years, and so has the demand for cloud migration solutions.
                            </p>
                        </div>
                        <div className="text-section textBg">
                            <p>The advantages of application migration to the cloud</p>
                        </div>
                        <div className='elementory-section'>
                            {
                                advantages.map((item, i) => (
                                    <div className='elementory-column'>
                                        <img src={item.img} alt="" />
                                        <p>{item.text}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='hexa-primary-btn'>
                            <p>Talk to our Expert <img src={TalkToExpert} alt="" /></p>
                        </div>
                    </Grid>
                </div>
            </section>
            <section className='section-C Section'>
                <div className='container'>
                    <Grid item xs={12}>
                        <div className="heading">
                            <h2>Moving to the cloud can be overwhelming.</h2>
                            <h4>Hexaview's cloud migrations solutions make it simpler for you.</h4>
                            <p>We work with you to identify and determine the best suitable approach for your organization – public, private, or hybrid.</p>
                        </div>
                        <div className='section-C-inner'>
                            <Grid item xs={12} md={7}>
                                <div className="cloud-text-section">
                                    As a part of our cloud application migration solutions, we take a customer-centric approach to ensure migrations occur in a smooth, effective, and systematic way causing minimal disruptions and in alignment with your business goals. With our deep industry and business process experience in cloud migration services, we help you:
                                </div>
                                <div className="text-section">
                                    <div className='flexSection'>
                                        <Grid item xs={12} md={1}>
                                            <img src={Weight} alt="" />
                                        </Grid>
                                        <Grid item xs={12} md={11}>
                                            <h4>Improve Agility</h4>
                                            <p>Establish a cost-optimal cloud platform for your cloud migration and implement CI/CD approach for faster development.</p>
                                        </Grid>
                                    </div>
                                    <div className='flexSection'>
                                        <Grid item xs={12} md={1}>
                                            <img src={Sheet} alt="" />
                                        </Grid>
                                        <Grid item xs={12} md={11}>
                                            <h4>Optimize monthly cloud cost</h4>
                                            <p>With auto-scaling capabilities, our cloud servers empower you to increase or decrease the usage of the cloud services as per the requirement. It also helps in preventing downtimes.</p>
                                        </Grid>
                                    </div>
                                    <div className='flexSection'>
                                        <Grid item xs={12} md={1}>
                                            <img src={Security} alt="" />
                                        </Grid>
                                        <Grid item xs={12} md={11}>
                                            <h4>Uplift Security</h4>
                                            <p>Enhance disaster recovery planning and security of data by backing up your cloud environment more quickly than on-premises.</p>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div className='cloudMigrationImg'>
                                    <img src={cloudMigrationsImg} alt="" />
                                </div>
                            </Grid>
                        </div>
                        <div className="btn-section">
                            <div className='hexa-secondary-btn'>
                                <p>TALK TO EXPERT <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                            </div>
                        </div>
                    </Grid>
                </div>
            </section>
            <section className='section-D'>
                <div className='container-fluid'>
                    <Grid item xs={12}>
                        <div className="heading Section">
                            <h2>Move your applications to the</h2>
                            <h2>cloud and your business into the future</h2>
                            <p>Learn how Hexaview has helped companies like yours with cloud migration solutions</p>
                        </div>
                    </Grid>
                </div>
                <div className='section-D-inner'>
                    <div className='container'>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <div className='cloudMigrationImg'>
                                    <img src={manageService1} alt="" />
                                    <h3>Managed services to ensure zero downtime & high availability for a reputed financial services company</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className='cloudMigrationImg'>
                                    <img src={PaymentServices} alt="" />
                                    <h3>Established a user-friendly payment management portal on AWS cloud</h3>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            <ServicePartner />
            <TrustBy />
            <CloudGetTouchBox
                h2Heading="Looking for a Cloud Migration Partner?"
                textSection="Hexaview’s Cloud consultants and developers team carries out cloud migration solutions with minimum disruption and ensures you get sustainable business outcomes by leveraging cloud environments."
                buttonText="Migrate to Cloud"
            />
            <div className='gap-below-box'></div>
        </Grid>
    );
}

export default Index;
import React from 'react';
import Grid from '@mui/material/Grid';
import Adobe from '../../../../../../assets/cloud/adobe.svg'
import "./style.scss";


const Index = () => {
    return (
        <section className='trustBy Section'>
            <div className='container'>
                <Grid item xs={12}>
                    <div className="heading">
                        <h2>Trusted By</h2>
                        <p>Hexaview’s cloud cost optimization services and solutions empower you to monitor cloud usage continuously and gain insights to optimize your cloud spend. Our cloud optimization service delivers.</p>
                    </div>
                    <div className='trustBy-inner'>
                        <Grid item xs={12} sm={6}>
                            <div className='trustImg'>
                                <img src={Adobe} alt=""/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className='trustImg'>
                                <img src={Adobe} alt=""/>
                            </div>
                        </Grid>
                    </div>
                </Grid>
            </div>
        </section>
    );
}

export default Index;
import React from 'react';
import Grid from '@mui/material/Grid';
import "./style.scss";
import TifinService from '../../../../../../assets/cloud/Company/svgexport-22.svg';
import NIIT from '../../../../../../assets/cloud/Company/svgexport-23.svg';
import Adobe from '../../../../../../assets/cloud/Company/svgexport-24.svg';
import Nestle from '../../../../../../assets/cloud/Company/svgexport-25.svg';
import LPL from '../../../../../../assets/cloud/Company/svgexport-26.svg';
import Addepar from '../../../../../../assets/cloud/Company/svgexport-27.svg';
import Totum from '../../../../../../assets/cloud/Company/svgexport-28.svg';

type Props = {
    heading?: String,
    description?: String,
}

const Index = ({
    heading,
    description
}: Props) => {
    return (
        <section className='company-section Section'>
            <div className='container'>
                <Grid item xs={12}>
                    <div className='heading-wrapper'>
                        <h2>You’ll be in good company</h2>
                        <p>From agile enterprises to innovative start-ups, Hexaview has helped businesses own digital<br /> transformation by harnessing the power of technology.</p>
                    </div>
                    <div className="company-section-inner">
                        <Grid container wrap='wrap' >
                            <Grid xs={6} sm={4} md={3} lg={2}>
                                <div className='company'>
                                    <img src={TifinService} alt="" />
                                </div>
                            </Grid>
                            <Grid xs={6} sm={4} md={3} lg={2}>
                                <div className='company'>
                                    <img src={NIIT} alt="" />
                                </div>
                            </Grid>
                            <Grid xs={6} sm={4} md={3} lg={3}>
                                <div className='company'>
                                    <img src={Adobe} alt="" />
                                </div>
                            </Grid>
                            <Grid xs={6} sm={4} md={3} lg={3}>
                                <div className='company'>
                                    <img src={Nestle} alt="" />
                                </div>
                            </Grid>
                            {/* <Grid xs={6} sm={4} md={3} lg={2}>
                            </Grid> */}
                        </Grid>
                        <div className="spacing"/>
                        <Grid container wrap='wrap' spacing={-24} >
                            <Grid xs={6} sm={4} md={4} lg={3}>
                                <div className='company'>
                                    <img src={LPL} alt="" />
                                </div>
                            </Grid>
                            <Grid xs={6} sm={4} md={4} lg={3}>
                                <div className='company'>
                                    <img src={Addepar} alt="" />
                                </div>
                            </Grid>
                            <Grid xs={6} sm={4} md={4} lg={3}>
                                <div className='company'>
                                    <img src={Totum} alt="" />
                                </div>
                            </Grid>
                            <Grid lg={6}>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </div>
        </section>
    );
}

export default Index;

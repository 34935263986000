import React,{useEffect} from 'react';
import Grid from '@mui/material/Grid';
import PageTopSection from '../../../common-components/pageTop-section/PageTopSection';
import CheckIcon from '@mui/icons-material/Check';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import "./DataScience.scss";
import { type } from '@testing-library/user-event/dist/type';
import SwiperSlides from './SwiperSlides';
import ContainerWrapper from '../../../utils/custom-tags/ContainerWrapper';
import Chat from '../../../../assets/FinTech-wealth-management/capital-market/chat.svg'
const DataScience = () => {

    // useEffect(() => {
    //     document.title = 'Data Science Services Company | Data Science Consulting Company';
    //   },[]);
    return (
        <Grid container className='data-science-elements-main-container paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/data-science-banner.jpg"}
                colorH1Heading="DATA IS THE NEW OIL"
                textSectionA={"Helping Enterprises understand Big Data & enable accurate Decision-Making through AI."}
                textSectionB={"Adopt data-driven solutions to improve outcomes with Hexaview, one of the leading Data Science Consulting Company"}
                isScheduleCall={true}
            />
            <section className='data-science-section-B Section'>
               <div className='container'>
                    <Grid container className='inn-container'>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className='left-section'>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Counter
                                        icon={<ScheduleIcon />}
                                        number="20,000"
                                        suffix={"+"}
                                        textTitle={"Man Hours Saved in Analysis"}
                                    />
                                    <Counter
                                        icon={<ScheduleIcon />}
                                        number="30"
                                        suffix={"+"}
                                        textTitle={"Use Cases Investigated and Resolved"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Counter
                                        icon={<TrendingUpIcon />}
                                        number="97"
                                        suffix={"%"}
                                        textTitle={"Rise in Data Accuracy"}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className='text-section element-widget-wrap'>
                                <h2 className='heading'>Data Science Consulting Services Overview</h2>
                                <p>Hexaview places data science at the core of our data solutions. One of the biggest challenges faced by most companies today are is having accurate, logical, and reliable data. We are a data science services company, and with our extensive know how to solve complex data challenges. Our team develops data-driven strategies that lead to more meaningful user experiences and higher ROI. We have worked with industry leaders and key decision-makers to transform their business operations, create effective global strategies, expand overseas & enter new markets.</p>
                            </div>
                            <div className='text-section element-widget-wrap'>
                                <p>Our data science consulting services utilizes the superpower of Artificial Intelligence & Machine Learning to help our clients unleash the true power of their data and analyze customer buying patterns, predict demand to improve customer satisfaction and steer business strategies based on real-time data insights.</p>
                            </div>
                        </Grid>
                    </Grid>
               </div>
            </section>
            <section className='data-science-section-C Section'>
                <div className='container'>
                    <div className="heading"><h2>Our Data Science Services</h2></div>
                    <div className='text-section element-widget-wrap'>
                        <p>We offer end-to-end data science services — from consulting to development from scratch and tailoring your data</p>
                        <p>science as service software — to empower your business with the latest technological advancements.</p>
                    </div>
                </div>
            </section>
            <CommonsSection
                img={"https://hexaviewtech.com/wp-content/uploads/2021/08/analysis-and-visualization.svg"}
                heading={"Analytics and Visualization"}
                text={"Understand your organization’s data at a glance to gain real-time insights, identify hidden opportunities, enable better decision-making, and increase productivity across all departments."}
                isFlexDirectionRev={false}
                liItems={["Data Warehouse", "SaaS Platform Design", "ETL", "Reporting Automation", "Operation Optimization and Statistical Analytics"]}
            />
            <CommonsSection
                img={"https://hexaviewtech.com/wp-content/uploads/2021/08/business-intelligent.svg"}
                heading={"Business Intelligence (BI)"}
                text={"Our Business intelligence (BI) services help companies adopt efficient enterprise processes and manage their technologies ecosystem to streamline their operations, save costs, improve performance & power decision-making."}
                isFlexDirectionRev={true}
                liItems={["BI Consulting", "Data Modelling", "BI Implementation", "BI Migration"]}
            />
            <CommonsSection
                img={"https://hexaviewtech.com/wp-content/uploads/2021/08/big-data-services.svg"}
                heading={"Big Data Services"}
                text={"Through our big data & data science consulting services, we solve big data challenges for clients by providing effective business strategies and fully managed & automated big data services. We help companies become truly digital businesses through a scalable and cost-effective big-data environment."}
                isFlexDirectionRev={false}
                liItems={["Enterprise Data Strategy", "Big Data Development, Maintenance & Support", "Optimization", "DataOps Solutions"]}
            />
            <CommonsSection
                img={"https://hexaviewtech.com/wp-content/uploads/2021/08/data-mining.svg"}
                heading={"Data Mining and Data Engineering"}
                text={"In today’s digital era, business decisions are driven through a blend of data, algorithms, computing power, and human intelligence. Our data mining, and data engineering services enable businesses to unleash the power of their data and ensure that all useful data (structured or unstructured) is recognized, sourced, cleaned, analyzed, modeled and utilized – without losing on value as the data travels through this path."}
                isFlexDirectionRev={true}
                liItems={["Business Research & Analysis", "Data Collection", "Profiling & Segmentation", "Data Validation"]}
            />
            <CommonsSection
                img={"https://hexaviewtech.com/wp-content/uploads/2021/08/machine-learning.svg"}
                heading={"Machine Learning"}
                text={"Artificial Intelligence and Machine Learning have the potential to reshape industries. And there are a variety of machine learning solutions that can be tailored to different business needs. To ensure you get the most effective solution, our data science experts will select the best approach for the specific needs of your business and your market."}
                isFlexDirectionRev={false}
                liItems={["Deep Learning", "Predictive Analytics", "ML Model Development", "Natural Language Understanding", "Computer Vision"]}
            />
            <CommonsSection
                img={"https://hexaviewtech.com/wp-content/uploads/2021/08/robotic-process.svg"}
                heading={"Robotics Process Automation (RPA)"}
                text={"Getting RPA right is a complex process and involves a wide variety of rule-based and knowledge-based work. We bring over a decade of software services and process improvement experience combined with strong technical capabilities through our RPA services expertise. Hexaview supports you through your end-to-end RPA journey, from identifying the best process for automation to implementation, management, and expansion:"}
                isFlexDirectionRev={true}
                liItems={["RPA Consulting", "Due Diligence", "RPA Enablement & Implementation", "RPA COE setup", "Support"]}
            />
            <section className='data-science-carouselsection-E Section'>
                <div className='container'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='heading-elements'>
                        <h1 className='heading'>Testimonials</h1>
                        <div className='devider'><span></span></div>
                        </div>
                    <SwiperSlides />
                    </Grid>
                </div>
            </section>
        </Grid>
    );
}
type secProps = {
    img?: string,
    heading?: String,
    text?: String,
    isFlexDirectionRev?: Boolean,
    liItems?: any,
}
type counterProps = {
    icon?: React.ReactNode | string | String,
    number?: String,
    suffix?: String,
    textTitle?: String
}

export const Counter = ({ icon, number, suffix, textTitle }: counterProps) => {
    return (
        <div className="counter-container">
            <div className='icon'>{icon}</div>
            <div className='text-wrap'>
                <div className='number-conatiner'>
                    <div className='number'>{number}</div>
                    <div className='suffix'>{suffix}</div>
                </div>
                <div className='text-title'>{textTitle}</div>
            </div>
        </div>
    )
}
export const CommonsSection = ({ img, text, heading, isFlexDirectionRev, liItems }: secProps) => {
    const reverse = isFlexDirectionRev ? 'row-reverse' : 'row';
    return (
            <ContainerWrapper className='elements-row Section'>
                <Grid container style={{ flexDirection: reverse }}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div className='element-widget-wrap'>
                            <div className='icon'><img src={Chat} alt="" /></div>
                            <div className='heading'><h3>{heading}</h3></div>
                            <div className='text-section'><p>{text}</p></div>
                            <div className="check-marks">
                                <ul>
                                    {liItems && liItems.map((item: any) => {
                                        return (
                                            <li key={item}><CheckIcon className='check-icon' />{item}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='image-container'>
                            <img src={img} style={{ width: '80%' }} />
                        </div>
                    </Grid>
                </Grid>
            </ContainerWrapper>
    )

}

export default DataScience;
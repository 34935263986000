import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Bny from '../../../assets/home/bny.svg'
import Nestle from '../../../assets/home/nestle.svg'
import Adobe from '../../../assets/home/adobe.svg'
import Lpl from '../../../assets/home/lpl.svg'
import Niit from '../../../assets/home/niit.svg'
import Blaze from '../../../assets/home/blaze.png'
import Slider from "react-slick";
import "./style.scss";


const Index = () => {

  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    slidesToShow: 6,
    slidesToScroll: 1
};

  return (
    <Grid container className="slider">
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Slider {...settings}>
                <div className='img'>
                <img src={Bny}/>
                </div>
                <div className='img'>
                <img src={Nestle}/>
                </div>
                <div className='img'>
                <img src={Adobe}/>
                </div>
                <div className='img'>
                <img src={Lpl}/>
                </div>
                <div className='img'>
                <img src={Niit}/>
                </div>
                <div className='img'>
                <img src={Blaze}/>
                </div>
                <div className='img'>
                <img src={Bny}/>
                </div>
                <div className='img'>
                <img src={Nestle}/>
                </div>
                <div className='img'>
                <img src={Adobe}/>
                </div>
                <div className='img'>
                <img src={Lpl}/>
                </div>
                <div className='img'>
                <img src={Niit}/>
                </div>
                <div className='img'>
                <img src={Blaze}/>
                </div>
        </Slider>
      </Grid>
    </Grid>
  );
}

export default Index;
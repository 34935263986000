import React from 'react';
import Grid from '@mui/material/Grid';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import CloudGetTouchBox from '../commonSection/cloudGetTouchBox/Index'
import Consultant from '../commonSection/consultant/Index';
import CardWithLeftHeader from '../../../../common-components/card/CardWithLeftHeader';
import Counter from '../../../../common-components/counter-widget/Counter';
import Scalability from '../../../../../assets/cloud/scalability.svg';
import Alert from '../../../../../assets/cloud/alert.svg';
import Support from '../../../../../assets/cloud/support.svg';
import Code from '../../../../../assets/cloud/code.svg';
import Automation from '../../../../../assets/cloud/automation.svg';
import Goal from '../../../../../assets/FinTech-wealth-management/goal.svg';
import Cost from '../../../../../assets/cloud/cost.svg';
import CloudProgress from '../../../../../assets/cloud/cloud-progress.svg';
import Container from '../../../../../assets/cloud/svgexport-20.svg';
import "./style.scss";
import TalkToExpert from '../../../../../assets/cloud/talk-to-expert.svg';
const Index = () => {

    return (
        <Grid container className='cloud-migration-solutions paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/cloud-banner.jpg"}
                h1Heading="Cloud Migration Solutions"
                textSectionA={"Hexaview’s cloud migration solutions and services help you migrate your data to the cloud with minimal disruption while modernizing your business."}
                isScheduleCall={true}
            />
            {/*...service text section...*/}
            <section className='devops-consulting-service-section-B Section'>
                <div className='container'>
                    <div className="text-section">
                        <p>Hexaview, one of the fastest-growing DevOps consulting companies, brings together the best-in-class talent in DevOps. Our DevOps development services help optimize cloud implementation while enhancing the reliability, security, and scalability of the systems across the organization.</p>
                    </div>
                    <Grid container className='inn-container' spacing={4}>
                        <Grid className="section-B-left" item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="heading">
                                <h2 >What Is DevOps?</h2>
                            </div>
                            <div className="text-section">
                                <p>The term DevOps, a combination of the word ‘development’ and ‘operations’, reflects the process of incorporating these disciplines into one, continuous process. DevOps is a set of practices, tools, and skills that automate and streamline the processes between software development and IT teams. This approach emphasizes team empowerment, cross-team communication and collaboration, and technology automation.</p>
                            </div>
                            <div className='hexa-secondary-btn'>
                                <p>Get in Touch <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                            </div>
                        </Grid>
                        <Grid className="section-B-right" item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className='image-section'>
                                <img src='https://hexaviewtech.com/wp-content/uploads/2022/03/devops.svg' style={{ width: "350px", marginTop: "-60px" }} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className='devops-consulting-service-section-C Section'>
                <div className='container'>
                    <Grid container className='card'>
                        <Grid className="section-C-left" item xs={12} sm={12} md={3} lg={2} xl={2}>
                            <div className='image-section'>
                                <img src='https://hexaviewtech.com/wp-content/uploads/2022/03/AWS-Partner.svg' />
                            </div>
                        </Grid>
                        <Grid className="section-C-middle" item xs={12} sm={12} md={6} lg={7} xl={7}>
                            <div className="heading">
                                <h3 >AWS Consulting Partner</h3>
                                <h4>Hexaview is an AWS-certified consulting partner company.</h4>
                            </div>
                            <div className="text-section">
                                <div>
                                    <p>We focus on DevOps & Agile practices powered by AWS products helping customers plan, design, migrate, or build new applications on AWS.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid className="section-C-right" item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div className='hexa-primary-btn'>
                                <p>Get in Touch <img src={TalkToExpert} alt="" /></p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className='devops-consulting-service-section-D Section'>
                <div className='container'>
                    <Grid item xs={12}>
                        <div className='heading-wrapper'>
                            <h4>Product Development can be a tedious and costly effort</h4>
                            <h2>Here’s how Hexaview's DevOps Services can help</h2>
                        </div>
                        <div className="service-wrapper-card">
                            <Grid container wrap='wrap' >
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className='card'>
                                        <img src={Cost} alt="" />
                                        <h4>Minimize Cost</h4>
                                        <p>Stop making costly mistakes in deployment. With monitoring and logging practices, stay informed of performance in real-time so you can fix bugs proactively and improve reliability. Use automation to ensure a lean operation.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className='card'>
                                        <img src={Scalability} alt="" />
                                        <h4>Enhance Scalability & Reliability</h4>
                                        <p>Operate your infrastructure and development processes at scale. With DevOps Automation, manage your complicated and ever-changing systems economically and with minimized risk.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className='card'>
                                        <img src={CloudProgress} alt="" />
                                        <h4>Faster Speed-to-Market</h4>
                                        <p>In the evolving market needs, your business's requirements keep on changing to meet the customer’s demands. Therefore, robust deployment and infrastructure management processes assist you to launch your products faster.s</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className='card'>
                                        <img src={Goal} alt="" className='filter-green' />
                                        <h4>Better management</h4>
                                        <p>Ad-hoc work is a reality that most often impacts team productivity and distracts from the work at hand. However, with established procedures & clear prioritization, teams can better foresee and share unplanned work while focusing on planned work.</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </div>
            </section>
            <section className='devops-consulting-service-section-E Section'>
                <div className='container'>
                    <Grid item xs={12}>
                        <div className='heading-wrapper'>
                            <h2>We will lower your cloud costs<span>Significantly & Guaranteed</span></h2>
                            <h4>Gain visibility into cloud usage for your cloud environment.</h4>
                            <p>Hexaview's cloud cost optimization services and solutions empower you to monitor cloud usage continuously and gain insights to optimize your cloud spend. Our cloud optimization service delivers instant savings while helping you establish a long-term cost savings culture.</p>
                        </div>
                        <div className="service-wrapper-card">
                            <Grid container wrap='wrap' spacing={1} >
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CardWithLeftHeader
                                        headerImgUrl={Support}
                                        contentHeading="DevOps Assessment"
                                        descriptionText={"Let our team of DevOps engineers review your infrastructure, identify your desired state and evaluate your current practices to propose tailor-made DevOps solutions for you."}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CardWithLeftHeader
                                        headerImgUrl={Automation}
                                        contentHeading="DevOps Automation & Management"
                                        descriptionText={"To ensure continuous deployment & delivery, our DevOps experts offer support for automation, release management, continuous deployment, change management, and more and protect organizations from deployment risks increasing their output & revenue."}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CardWithLeftHeader
                                        headerImgUrl={Code}
                                        contentHeading="Continuous Integration & Delivery"
                                        descriptionText={"Shared repositories allow the DevOps team to build, test, and release software frequently, and stick to rapid delivery cycles. Being one of the top DevOps development companies, our advanced DevOps Services offer continuous delivery capabilities for increased productivity and automation."}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CardWithLeftHeader
                                        headerImgUrl={Alert}
                                        contentHeading="Monitoring and Alerting"
                                        descriptionText={"Once the cloud infrastructure has been set up, our DevOps experts provide continuous monitoring services to look at potential errors and report them in real-time. This helps upgrade customer experiences, and quick corrections prevent the further spread of the errors."}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CardWithLeftHeader
                                        headerImgUrl={Container}
                                        contentHeading="Containerization"
                                        descriptionText={"Containerization captures the application in a container with exclusive operating systems and other services essential to run the application. Let our experts help you with our Containerization DevOps services to deliver highly reliable, consistent, and efficient applications."}
                                    />
                                </Grid>
                            </Grid>
                            <div className='hexa-primary-btn'>
                                <p>Get in Touch <img src={TalkToExpert} alt="" /></p>
                            </div>
                        </div>
                    </Grid>
                </div>
            </section>
            <Consultant
                heading="Consult The Best DevOps Experts in USA, Europe & India Now."
                description={"Achieve outstanding measurable performance with Hexaview’s DevOps services and solutions."}
            />

            <section className="devops-consulting-service-section-G Section">
                <div className='container'>
                    <div className='heading-wrapper'>
                        <h2>Why choose our DevOps Consulting Services?</h2>
                        <p>With our DevOps consulting services, you will get the ability to deliver products and services in a robust manner empowering you to make incremental changes at multiple levels of the software development and testing lifecycle without affecting the quality. Let our DevOps experts help you with comprehensive delivery across digital and enterprise applications.</p>
                    </div>
                    <Grid container className='counterCard'>
                        <Counter prefix='' suffix='x' number="105" title="Faster Delivery Time" />
                        <Counter prefix='' suffix='x' number="2,600" title="Recovery Time" />
                        <Counter prefix='' suffix='x' number="7" title="Change Failure Rate" />
                    </Grid>
                    <div className='hexa-primary-btn'>
                        <p>Get in Touch <img src={TalkToExpert} alt="" /></p>
                    </div>
                </div>
            </section>
            <CloudGetTouchBox
                h2Heading="Choose Hexaview: Your DevOps consulting partner"
                textSection="Learn more about Hexaview, one of the top DevOps development company"
                buttonText="Migrate to Cloud"
            />
        </Grid>
    );
}

export default Index;
import { Card, Grid } from '@mui/material';
import React from 'react';
import PageTopSection from '../../../common-components/pageTop-section/PageTopSection';
import './Careers.scss';
import img from '../../../../assets/image.svg';
import career from '../../../../assets/career.png';
import Clients from '../../../pages/home/our_Clients/Index';
import Client from '../../../../assets/home/client.png';

const Careers = () => {
    return (
        <div className="career-main-container paddingTopSection">
            <PageTopSection
                img={'https://hexaviewtech.com/wp-content/uploads/2021/08/career-banner.svg'}
                h1Heading={'BUILD YOUR SKILLS AND YOUR CAREER'}
                textSectionA={'Join A Team That Celebrates You Daily!'}
                textSectionB={"Our people are not only our greatest asset but also our biggest competitive advantage. We don’t call our employees- employees, we call them associates."}
                isTalkToExpert={false}
                isScheduleCall={false} />

            <section className="career-section-B Section">
                <div className='container'>
                    <Grid container>
                        <Grid className="section-A-left" item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <div className="heading">
                                <h2>Why Join Our Team?</h2>
                            </div>
                            <div className="heading">
                                <h5>Be a part of a fun environment where you can learn new skills, earn rewards and work with some awe-inspiring people.</h5>
                            </div>
                            <div className="text-section">
                                    <p>
                                        We work & evolve together as a team connected by a shared passion. We highly believe that excellence deserves to be rewarded and so we provide all our employees with a range of rewards and benefits to make sure they know how much we appreciate everything they do. One of our core beliefs is curiosity, and we strive for our employees to be lifelong learners. We invest in high-quality training of our employees, partnering with external experts in the field.</p>
                                    <p>
                                        Along with this, we place a heavy value on trust and transparency. We believe in providing full transparency to everyone in Hexaview. Most importantly, work-life balance is among our priority lists. We offer the employee the choices of when and where to work, depending on the needs, location, and role. We always welcome people who have a great learning attitude and a firm belief to make a difference.</p>
                            </div>
                            <div className="buttonWithNoBorder">
                                <p>Apply for A job <img src={img} alt="Image Button" /></p>
                            </div>
                        </Grid>

                        <Grid className="section-A-right" item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <div className="image-section">
                                <img style={{ width: "450px", height: "500px" }} src={career} alt="Main Image" />
                            </div>

                        </Grid>
                    </Grid>
                </div>
            </section>

            {/* Testimonials page left out */}


            <section className="career-section-C Section">
                <div className='container'>
                    <h3>HIRING 2022 GRADUATES!</h3>
                    <h4>Here’s your chance to be ahead of the pack and get industry-level training before graduating. Join our team and kick-start your career with the most in-demand skills.
                    </h4>
                    <div className='hexa-primary-btn'>
                        <p>Apply for A Job <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt="" /></p>
                    </div>
                </div>
            </section>

            <section className="career-section-D Section">
                <div className='container'>
                    <div className="heading">
                        <h1>Current openings</h1>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <CommonCard
                                location={"Noida, India"}
                                designation={"Sr. Project Manager"}
                                refer={'#'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <CommonCard
                                location={"Pune, India"}
                                designation={"Java Lead"}
                                refer={'#'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <CommonCard
                                location={"Pune, India"}
                                designation={"Engineering Manager / Java Architect"}
                                refer={'#'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <CommonCard
                                location={"Noida, India"}
                                designation={"Engineer"}
                                refer={'#'}
                            />
                        </Grid>
                    </Grid>
                </div>
            </section>
        </div >
    )
}

interface props {
    location?: String,
    designation?: String,
    refer?: string
}

export const CommonCard = ({ location, designation, refer }: props) => {
    return (
        <Card className="common-card">
            <div className="location">
                <p> {location}</p>
            </div>
            <div className="desig">
                <h4>{designation}</h4>
            </div>
            {refer && <div className="button">
                <a href={refer}>View Details</a>
            </div>}
        </Card>
    );
}

export default Careers
import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

type Props = {
    headerIcon?: String | React.ReactNode,
    headerImgUrl?: string,
    contentHeading?: String,
    location?: String | React.ReactNode,
    contact?: React.ReactNode | String,
    size?: any,
    id?: string


}
const ContactCards = ({ headerIcon, headerImgUrl, contentHeading,
    location, contact, size = 4, id = "" }: Props) => {
    return (
        <Grid className='card-container' item xs={12} sm={12} md={size} lg={size} xl={size}>
            <Card className='card' id={id} sx={{ maxWidth: 390 }}>
                {/* <CardActionArea> */}
                {headerIcon && (<CardHeader avatar={headerIcon} />)}
                {headerImgUrl && (<CardMedia
                    component="img"
                    height="200"
                    image={headerImgUrl}
                    alt=""
                />)}
                <CardContent className='card-content'>
                    <Typography className='card-content-heading' gutterBottom variant="h6" component="div">
                        {contentHeading}
                    </Typography>
                    <Typography variant="body2">
                        {location}
                    </Typography>
                    <Typography variant="body2">
                        {contact}
                    </Typography>
                </CardContent>
                {/* </CardActionArea> */}
            </Card>
        </Grid>
    );
}

export default ContactCards;
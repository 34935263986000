import React from 'react';
import Grid from '@mui/material/Grid';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import "./AiMachineLearningServices.scss";
import Cards from '../../../../common-components/card/Card';
import CardWithLeftHeader from '../../../../common-components/card/CardWithLeftHeader';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PsychologyIcon from '@mui/icons-material/Psychology';
import CheckIcon from '@mui/icons-material/Check';
import CardChip from '../../../../common-components/card/CardChip';
import IncreasedSpeedFlexibility from '../../../../../assets/cloud/Increased-speed-and-flexibility.svg';
import dl4j from '../../../../../assets/dl4j.png';
import Kotlin_logo from '../../../../../assets/Kotlin_logo.png';
import technologies_tensorflow from '../../../../../assets/technologies-tensorflow.png';
import technologies_scikit_learn from '../../../../../assets/technologies-scikit-learn.png';
import Wear_OS_Logo from '../../../../../assets/Wear_OS_Logo.png';
import technologies_kafka from '../../../../../assets/technologies-kafka.png';
import ibmwatson from '../../../../../assets/ibmwatson.png';
import spring from '../../../../../assets/spring.png';
import steps_line from '../../../../../assets/steps-line.svg';
import RecentResources from '../common/RecentResources';
import Scalability from '../../../../../assets/data-science/scalability.svg';
import Segmentation from '../../../../../assets/data-science/segmentation.svg';
import TimeZone from '../../../../../assets/data-science/time-zone.svg';
import Team from '../../../../../assets/data-science/team.svg';
import DL from '../../../../../assets/data-science/svgexport-19.svg';
import Progress from '../../../../../assets/data-science/svgexport-20.svg';
import Brain from '../../../../../assets/data-science/svgexport-21.svg';
import Chat from '../../../../../assets/data-science/svgexport-22.svg';
import Community from '../../../../../assets/data-science/svgexport-23.svg';
import Roots from '../../../../../assets/data-science/svgexport-25.svg';
const AiMachineLearningServices = () => {
    let cardChipArr = [
        {
            img: dl4j
        },
        {
            img: Kotlin_logo
        },
        {
            img: technologies_tensorflow
        },
        {
            img: technologies_scikit_learn
        },
        {
            img: Wear_OS_Logo
        },
        {
            img: technologies_kafka
        },
        {
            img: ibmwatson
        }, {
            img: spring
        }
    ]
    return (
        <Grid container className='ai-machine-learning-main-container paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/machine-learning-banner.jpg"}
                h1Heading={"AI & Machine Learning Development Services"}
                textSectionA={"Hexaview helps drive operational growth and efficiency with advanced Artificial Intelligence (AI) and Machine Learning (ML) consulting services."}
                isScheduleCall={true}
            />
            <section className="ai-machine-services-section-B Section">
                <div className='container'>
                    <div className="headings-element-wrapper">
                        <h2>One Of the The Fastest Growing<br />
                            <span style={{ color: '#1B78FE' }}>Machine Learning Development Company</span><br />
                            <span>for Every Business Need</span>
                        </h2>
                        <h4>We Provide Comprehensive Machine Learning Development Services</h4>
                    </div>
                    <div className="text-wrapper element-widget-wrap">
                        <p>We apply deep technological expertise in our AI & Machine Learning Consulting services to help you automate your internal processes, deliver personalized customer experiences, and implement next-gen solutions that will transform your business operations at scale. Our R&D competencies and tailored machine learning solutions empower clients to grow their market size, become more efficient, and achieve measurable business outcomes.</p>
                    </div>
                    <div className="cards-element-container">
                        <Cards
                            headerImgUrl='https://hexaviewtech.com/wp-content/uploads/2022/04/Faster-and-informed.png'
                            contentHeading={"Faster decision making"}
                            contentText="Deeper data analysis allows business leaders to make better, more informed decisions that benefit their operations."
                            isActionBtn={false}
                        />
                        <Cards
                            headerImgUrl='https://hexaviewtech.com/wp-content/uploads/2022/04/Predictive-modelling.png'
                            contentHeading={"Predictive modelling"}
                            contentText="Our predictive analytics solutions reduce time, effort, and costs in forecasting business outcomes."
                            isActionBtn={false}
                        />
                        <Cards
                            headerImgUrl='https://hexaviewtech.com/wp-content/uploads/2022/04/Sales-forecasting.png'
                            contentHeading={"Sales forecasting"}
                            contentText="Determine present-day or future sales using data like past sales, seasonality, festivities, economic conditions, etc."
                            isActionBtn={false}
                        />
                    </div>
                    <div className="btn-container">
                        <div className='btn'>
                            <a href="https://hexaviewtech.com/contact/">Talk to our Expert</a>
                            <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                        </div>
                    </div>
                </div>
            </section>
            <section className='ai-machine-services-section-C Section'>
                <div className='container'>
                    <Grid container className='element-inner-section'>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='text-section element-widget-wrap'>
                                <h2 style={{ fontSize: '38px' }}><span>Hire</span> Machine Learning Developers</h2>
                                <div className='text'>
                                    <p>Want to become a precedent in the business industry with machine learning? Hexaview drives Machine learning services and potency for your business.</p>
                                </div>
                            </div>
                            <div className="btn-container">
                                <div className='btn'>
                                    <a href="https://hexaviewtech.com/contact/">Get ML Engineer</a>
                                    <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className='img-container'>
                                <img width="80%" src="https://hexaviewtech.com/wp-content/uploads/2022/04/get-ml-engineer.png" alt="" loading="lazy" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className='ai-machine-services-section-D Section'>
                <div className='container'>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <img width="100%" src="	https://hexaviewtech.com/wp-content/uploads/2022/04/Amazing-Machine-Learning-Services-img2.svg" alt="" loading="lazy" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ position: 'relative' }}>
                            <div className='heading-wrapper'>
                                <h2><span>Why Choose Hexaview’s</span><br /> Amazing Machine Learning Services</h2>
                            </div>
                            <div className="service-wrapper-card">
                                <Grid container wrap='wrap' >
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CardWithLeftHeader
                                            headerImgUrl={Scalability}
                                            contentHeading="Scalability"
                                            descriptionText={"Our team of skilled ML developers can help you to create your desired business solutions and easily scale- up your team for Machine Learning development."}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CardWithLeftHeader
                                            headerImgUrl={Segmentation}
                                            contentHeading="Better Customer Segmentation"
                                            descriptionText={"We use data analysis and representing the behavioural pattern of the users that help businesses to forecast the conversions."}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CardWithLeftHeader
                                            headerImgUrl={TimeZone}
                                            contentHeading="Same Time Zone"
                                            descriptionText={"We realize that two-way communication is a must for a seamless and robust work process, therefore coordinate during your working hours to cut off any communication gap."}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CardWithLeftHeader
                                            headerImgUrl={Team}
                                            contentHeading="Dedicated Team"
                                            descriptionText={"Our certified ML developer upscales your software development process. We provide reliable, secure, scalable, and robust solutions via our Machine Learning consulting services."}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <div className='ai-machine-services-section-E Section'>
                <div className='container'>
                    <div className="heading-wrapper">
                        <h2>
                            ML Services to Build<br />
                            <span>Modern Digital Experiences</span>
                        </h2>
                    </div>
                    <div className='card-wrapper'>
                        <Grid container wrap='wrap'>
                            <Cards
                                headerIcon={<img src={DL} />}
                                contentHeading={"Deep Learning"}
                                contentText="A more advanced version of Machine Learning. We examine complex data carefully to uncover the pool of opportunities and deliver perfection with real-time deep learning algorithms."
                                isActionBtn={false}
                                size={3}
                            />
                            <Cards
                                headerIcon={<img src={Progress} />}
                                contentHeading={"AI-based Solutions"}
                                contentText="Empower you to drive smart transformation of your workflows, tech stack, and entire organization and help your business to be more streamlined and grow exponentially."
                                isActionBtn={false}
                                size={3}
                            />
                            <Cards
                                headerIcon={<img src={Brain} />}
                                contentHeading={"Machine Learning"}
                                contentText="We build robust algorithms to automate your processes across all the departments, minimize human invention, increase operation speed, reduce errors, decrease cost, and boost reliability."
                                isActionBtn={false}
                                size={3}
                            />
                            <Cards
                                headerIcon={<img src={Chat} />}
                                contentHeading={"Natural Language Processing"}
                                contentText="Hexaview offers NLP services to help your org build a next-gen virtual assistant that is contextually appropriate, understands people’s language, and make better decisions."
                                isActionBtn={false}
                                size={3}
                            />
                        </Grid>
                    </div>
                </div>
            </div>
            <section className='ai-machine-services-section-f Section'
                style={{
                    backgroundImage: `url(https://hexaviewtech.com/wp-content/uploads/2022/04/AI-driven-Smart-Solutions.jpg)`,
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    backgroundSize: 'cover'
                }}
            >
                <div className='container'>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div className="heading-wrapper">
                            <h2>Technologies We Use to Create <span>AI-driven Smart Solutions for You</span></h2>
                        </div>
                        <div className="card-chip-section">
                            {cardChipArr.map(item => {
                                return (
                                    <CardChip
                                        img={item.img}
                                    />
                                )
                            })}
                        </div>
                    </Grid>
                </div>
            </section>
            <section className='ai-machine-services-section-G Section'>
                <div className='container'>
                    <div className='heading-wrapper'>
                        <h2>Stages of our successful<br /><span>Machine learning development</span></h2>
                    </div>
                    <div className="cards-wrapper">
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <CardWithLeftHeader
                                headerImgUrl={DL}
                                contentHeading="Discovery"
                                isDescriptionInListView={true}
                                liItems={["dentify business opportunities and scope of project.", "Establish ROI.", "Conduct feasibility assessment."]}
                            />
                            <CardWithLeftHeader
                                headerImgUrl={Community}
                                contentHeading="Model Creation"
                                isDescriptionInListView={true}
                                liItems={["Select algorithm and develop ML model.", "Develop, test, and maintain data platform"]}
                            />
                            <CardWithLeftHeader
                                headerImgUrl={Community} contentHeading="Solutions Deployment"
                                isDescriptionInListView={true}
                                liItems={["Manage ML model deployment and business process integration", "Deploy ML model and model refresh cycle", "Set up data flow and automation model"]}
                            />
                        </Grid>
                        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}>
                            <div className='steps-devider'
                                style={{
                                    backgroundImage: `url(${steps_line})`,
                                    backgroundRepeat: 'no-repeat',
                                    width: '100%',
                                    backgroundSize: 'contain',
                                    height: '100%',
                                    marginLeft: '5em',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} style={{ marginTop: '4em' }}>
                            <CardWithLeftHeader
                                headerImgUrl={Brain}
                                contentHeading="Solutions Design"
                                isDescriptionInListView={true}
                                liItems={["Define business process and data integration strategy",
                                    "Determine ML approach"
                                    , "Establish execution and deployment plan."]}
                            />
                            <CardWithLeftHeader
                                headerImgUrl={Roots}
                                contentHeading="Model Evaluation"
                                isDescriptionInListView={true}
                                liItems={["Conduct business risk assessment",
                                    "Analyse the effectiveness, accuracy, and bias of ML model",
                                    "Provide data visualization and reporting"]}
                            />
                            <CardWithLeftHeader
                                headerImgUrl={Roots}
                                contentHeading="Continuous Improvement"
                                isDescriptionInListView={true}
                                liItems={["Track and report progress against business goals",
                                    "Govern and manage data quality"]}
                            />
                        </Grid>
                    </div>
                </div>
            </section>
            <section className='ai-machine-services-section-H Section'
                style={{
                    backgroundImage: `url(https://hexaviewtech.com/wp-content/uploads/2022/04/AI-driven-Smart-Solutions.jpg)`,
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    backgroundSize: 'cover'
                }}
            >
                <div className='container'>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div className='heading-wrapper'>
                            <h1>Looking for AI & Machine Learning Development Services?</h1>
                            <h4>We are here to provide you with the right business expertise.</h4>
                        </div>
                        <div className="check-marks">
                            <ul>
                                <li><CheckIcon color="primary" fontSize='small' />On-call assistance in all time zones</li>
                                <li><CheckIcon color="primary" fontSize='small' />Projects consulting by experts</li>
                                <li><CheckIcon color="primary" fontSize='small' />Detail project estimation</li>
                            </ul>
                        </div>
                    </Grid>
                </div>
            </section>
            <RecentResources />
        </Grid>
    );
}

export default AiMachineLearningServices;
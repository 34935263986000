import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Government from '../../../../assets/home/Government.svg'
import HealthCare from '../../../../assets/home/Healthcare.svg'
import ManyMore from '../../../../assets/home/manyMore.svg'
import Education from '../../../../assets/home/education.svg'
import Automotive from '../../../../assets/home/Automotive.svg'
import Technical_SEO from '../../../../assets/home/Technical_SEO.svg'
import "./style.scss";


const Index = () => {

  return (
    <section className='industries-section Section'>
        <div className='container'>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className='heading-elements'>
                    <h4>Industries We Serve</h4>                
                    <h2>Delivering Software Solutions for a <span>Spectrum of Industries</span></h2>
                    <p>Over the past 11 years, we have served clients across a wide spectrum of industries verticals across the globe, particularly the Financial Services industry, Information Technology industry, Healthcare industry, and a lot more. We have been providing cutting-edge digital solutions to our clients by imbibing the most advanced technologies, including AI and ML, to enhance the user experience and operations. Our solutions focus on solving challenges faced by industries and help them to remain agile and lift to success.</p>
                </div>
            </Grid>
            <Grid container className='outerDiv'>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className='card'>
                        <div className='card_header'>
                            <div className='img'>
                                <img src={Government} alt=""/>
                            </div>
                            <div className='indHeading'>
                              <h5>Banking, Financial Services and Insurance</h5>
                            </div>
                        </div>
                        <p>Scale your business while we fulfill your outreach orders and send you white</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className='card'>
                        <div className='card_header'>
                            <div className='img'>
                                <img src={Technical_SEO} alt=""/>
                            </div>
                            <div className='indHeading'>
                                <h5>Information Technology</h5>
                            </div>
                        </div>
                        <p>Scale your business while we fulfill your outreach orders and send you white</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className='card'>
                        <div className='card_header'>
                            <div className='img'>
                                <img src={HealthCare} alt=""/>
                            </div>
                            <div className='indHeading'>
                               <h5>Healthcare</h5>
                            </div>
                        </div>
                        <p>Scale your business while we fulfill your outreach orders and send you white</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className='card'>
                        <div className='card_header'>
                            <div className='img'>
                                <img src={Education} alt=""/>
                            </div>
                            <div className='indHeading'>
                               <h5>Education</h5>
                            </div>
                        </div>
                        <p>Scale your business while we fulfill your outreach orders and send you white</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className='card'>
                        <div className='card_header'>
                            <div className='img'>
                                <img src={Automotive} alt=""/>
                            </div>
                            <div className='indHeading'>
                                <h5>Travel & Tourism</h5>
                            </div>
                        </div>
                        <p>Scale your business while we fulfill your outreach orders and send you white</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className='card'>
                        <div className='card_header'>
                            <div className='img'>
                                <img src={ManyMore} alt=""/>
                            </div>
                            <div className='indHeading'>
                                <h5>And Many More</h5>
                            </div>
                        </div>
                        <p>Scale your business while we fulfill your outreach orders and send you white</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    </section>
  );
}

export default Index;
import React from 'react';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import CloudGetTouchBox from '../commonSection/cloudGetTouchBox/Index'
import TrustBy from '../commonSection/trustBy/Index'
import ServicePartner from '../commonSection/servicePartner/Index'
import "./style.scss";

const Index = () => {

    return (
        <Grid container className='cloud-migration-solutions paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/cloud-banner.jpg"}
                h1Heading="Cloud Migration Solutions"
                textSectionA={"Hexaview’s cloud migration solutions and services help you migrate your data to the cloud with minimal disruption while modernizing your business."}
                isScheduleCall={true}
            />
            {/*...service text section...*/}
            <section className='governance-security-service-section-B Section'>
                 <div className='container'>
                    <Grid container>
                        <Grid className="governance-security-service-section-left" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="heading">
                                <h4>What is Cloud</h4>
                                <h2 >Governance?</h2>
                            </div>
                            <div className="text-section">
                                <div>
                                <p>When you run your business in the cloud, there are some rules you have to comply with. Generally, these rules are related to data security and privacy, but there aren’t any pre-set rules for how businesses should function in the cloud.</p>
                                </div>
                                <div className='bigBadge'>
                                    <p>Our cloud governance solutions model aims to</p>
                                </div>
                                <div className="check-marks">
                                    <ul>
                                        <li><CheckIcon className='icon' />Enhance data security</li>
                                        <li><CheckIcon className='icon' />Manage risk</li>
                                        <li><CheckIcon className='icon' />Increase business efficiency</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='hexa-default-btn'>
                            <p>Get in Touch <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                            </div>
                        </Grid>
                        <Grid className="governance-security-service-section-right" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <img src={"https://hexaviewtech.com/wp-content/uploads/2022/03/governance.svg"} style={{width:"100%"}}/>
                        </Grid>
                    </Grid>
                 </div>
            </section>

            <section className='governance-security-service-section-C Section'>
                <div className='container'>
                    <Grid item xs={12}>
                        <div className='heading-wrapper'>
                            <h2>Why does your business<span>need cloud governance services?</span></h2>
                            <p>Migrating to the cloud is not a once-and-done event. It demands ongoing maintenance, monitoring, and reporting.</p>
                            <div className='bigBadge'>
                                <p>We can help you with that.</p>
                            </div>
                            <p>With our Cloud governance services, be assured about your complex environment meeting the ever-changing market demands, organizational policies, best security practices, and compliance obligations.</p>
                        </div>
                        <div className="service-wrapper-card">
                            <Grid container wrap='wrap' >
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className="outer-card">
                                    <div className='card'>
                                        <h4>Improve Cloud Resource Management</h4>
                                        <p>A robust cloud governance solutions strategy enables organizations to manage multiple accounts and access per the requirement and designation. It strengthens data security and supports resource allocation plans on the cloud.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className="outer-card">
                                    <div className='card'>
                                        <h4>Reduces Costs</h4>
                                        <p>Instead of counting on your employees to perform time-consuming manual processes, cloud governance solutions allow you to automate the budget and access management. Automated triggers also reduce the human resources requirement, saving time and costs.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className="outer-card">
                                    <div className='card'>
                                        <h4>Enhances Cloud Security</h4>
                                        <p>Cloud governance services protect information confidentiality, integrity, and accessibility. It ensures that appropriate security controls are in place at all times and sensitive information is available while addressing challenges like encryption, access rules, and suspicious activities.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3} className="outer-card">
                                    <div className='card'>
                                        <h4>Improved Compliance</h4>
                                        <p>It is crucial to always comply with relevant laws, regulations, and policies. Cloud Governance services help in the early detection of any deviation from the rules and regulations.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{textAlign:"center"}}>
                                    <div className='hexa-secondary-btn'>
                                        <p>Get in Touch <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </div>
            </section>
            <section className='governance-security-service-section-D'>
               <div className='container-fluid'>
                <Grid xs={12} md={8}>
                        <div className='security-service-inner'>
                            <h2>Utilize Hexaview’s Managed <br /><span>Cloud Security Services</span></h2>
                            <p>As the data regulations are getting rigorous constantly, Cloud governance must be the top-most priority for businesses operating in the cloud.</p>
                            <h4>Are you concerned about the <span>Optimization</span> <br />of your cloud infrastructure?</h4>
                            <p>Our cloud governance and security services provide you with a scalable infrastructure, required IT resources, security management, and the 24×7 uptime and availability you demand. With our Cloud Security Managed Services, you no longer have to worry about the budget, resources, time, and hardware expense to build/ maintain your cloud infrastructure. Hexaview’s cloud experts empower you to focus on your core operations and grow your business, not cloud maintenance; it’s our job to make your cloud implementation successful.</p>
                            <div className='hexa-default-btn'>
                                <p>Get in Touch <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                            </div>
                        </div>
                    </Grid>
               </div>
            </section>


            <ServicePartner />
           <TrustBy />
            <CloudGetTouchBox
                h2Heading="Looking for a Cloud Migration Partner?"
                textSection="Hexaview’s Cloud consultants and developers team carries out cloud migration solutions with minimum disruption and ensures you get sustainable business outcomes by leveraging cloud environments."
                buttonText="Migrate to Cloud"
            />
        </Grid>
    );
}

export default Index;
import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Button } from "antd";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
// import "../pdf-scrapper/PdfScrapper.scss";
import Cards from '../../../common-components/card/Card';
import CardChip from '../../../common-components/card/CardChip';
import money_bill_solid from "../../../../assets/money-bill-solid.svg"
import user_tie_solid from "../../../../assets/user-tie-solid.svg"
import data_visualization from "../../../../assets/data_visualization.svg";
import video_template from "../../../../assets/video_template.svg";
import video_runtime from "../../../../assets/video_runtime.svg";
import Ai_engine from "../../../../assets/Ai_engine.svg";
import auto_voice from "../../../../assets/auto_voice.svg";
import todo from "../../../../assets/todo.svg"
import calendar from "../../../../assets/calendar.svg"
import customizable_data from "../../../../assets/customizable_data.svg"
import "./VideoStatements.scss";
const VideoStatements = () => {

    const gotoSection = (e:any) => {
        e.preventDefault();
        const element = document.getElementById('findDetail');
        element && element.scrollIntoView({
            block: 'start',
            behavior: 'smooth' // smooth scroll
        })
  };
    return (
        <Grid container className='main-wrapper-container paddingTopSection' style={{ overflow: 'auto' }}>
            <section className='section-A'
                style={{
                    backgroundImage: `url(https://hexaviewtech.com/wp-content/uploads/2020/12/1.svg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}
            >
                <div className='container'>
                    <div className='image-elements-section'>
                        <div className='element-image-text-wrapper'>
                            <h3 className='heading-text'>The Next Level in Financial Reporting</h3>
                            <div className='text-section-b'>With Video Statement, you can create video reports quickly for a more extensive set of portfolios. It identifies both textual and numeric data through its native AI/ ML engine.</div>
                            <div className='learn-more'>
                                <a href='' onClick={gotoSection}>
                                    <Button type='primary' className='learn-more-btn'>Learn More</Button>
                                </a>
                                {/* <h5 style={{ color: '#43BAFF' }}>Learn More</h5> */}
                            </div>
                        </div>
                        <div className='empty-divider' style={{ width: '100px' }}></div>
                        <div className='image-element-section'>
                            <img width='100%' src='https://hexaviewtech.com/wp-content/uploads/2021/01/Video-Statement-Banner_v0.2.png' />
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-B Section'>
                <div className='container'>
                    <div className="heading-wrapper">
                        <h2>Challenges for Advisors & HNWIs</h2>
                    </div>
                    <div className="cardes-wrapper">
                        <CardChip img={user_tie_solid} descriptionText={"Creating new reports takes time, money, and a lot of to-and-fro coordination"} />
                        <CardChip img={user_tie_solid} descriptionText={"Advisors struggle to automate the process of customizing, packaging and distributing the reports to HNWIs"} />
                        <CardChip img={money_bill_solid} descriptionText={"Difficulty understanding and interpreting data reports without an advisor on hand"} />
                        <CardChip img={money_bill_solid} descriptionText={"Generated reports often fail to provide the interactivity to be meaningful"} />
                    </div>
                </div>
            </section>
            <section className='section-C Section' id="findDetail"
                style={{
                    backgroundImage: `url(https://hexaviewtech.com/wp-content/uploads/2020/12/light-bulb-lightbulb-light-1246043.jpg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed'
                }}
            >
                <div className='img-overlay' />
                <div className='container'>
                    <Grid className='element-inner-section' item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className='text-section'>
                            <h2>Solution</h2>
                            <p>
                                Our platform leveraged by <strong>AI & ML </strong>technologies automates portfolio reports’ formation and delivery. It creates custom eye-catching reports videos from scratch, providing a supreme & simplified client experience.
                            </p>
                        </div>
                    </Grid>
                </div>
            </section>
            <section className='section-D Section' >
                <div className='container'>
                    <div className="element-video-wrapper">
                        <div className="heading-wrapper">
                            <h2>Demo Reporting Videos</h2>
                        </div>
                        <Grid container wrap='wrap'>
                            <Grid className='element-inner-section' item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="iwrapper">
                                    <iframe
                                        src="https://www.youtube.com/embed/rb7vE5GyFek?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fhexaviewtech.com&widgetid=1" 
                                        title="new"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                                </div>
                            </Grid>
                            <Grid className='element-inner-section' item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="iwrapper">
                                    <iframe
                                        src="https://www.youtube.com/embed/WTmAqaqYCH8" 
                                        title="Video Statement: Marketing Demo | Showcasing Financial Advisors | Hexaview"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            <section className='section-E Section' >
                <div className='container'>
                    <div className="element-docs-wrapper">
                        <Grid container wrap='wrap'>
                            <Grid className='element-inner-section' item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className='element-widget-wrap'>
                                    <div className="items-wrapper">
                                        <div><img  src={data_visualization}/></div>
                                        <div className="text-wrapper">
                                            <h3>DATA VISUALIZATION</h3>
                                            <p>Create, save, and distribute a portfolio video summary with engaging visuals</p>
                                        </div>
                                    </div>
                                    <div className="items-wrapper">
                                        <img src={todo}/>
                                        <div className="text-wrapper">
                                            <h3>AUDIT TRAIL</h3>
                                            <p>View detailed documentation of a sequence of activities</p>
                                        </div>
                                    </div>
                                    <div className="items-wrapper">
                                        <img src={calendar}/>
                                        <div className="text-wrapper">
                                            <h3>SCHEDULE IMPORTS</h3>
                                            <p>Schedule date and time to auto-import data for generating videos</p>
                                        </div>
                                    </div>
                                    <div className="items-wrapper">
                                        <img src={customizable_data}/>
                                        <div className="text-wrapper">
                                            <h3>CUSTOMIZABLE DATA</h3>
                                            <p>Create tailored videos that align with your business requirements</p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid className='element-inner-section' item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className="image-wrapper"
                                    style={{
                                        backgroundImage: `url(https://hexaviewtech.com/wp-content/uploads/2020/12/cyber-network-technology-3400789.jpg)`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundAttachment:'fixed'
                                    }}
                                >
                                    <div className="text-wrapper">
                                        <h2>Features</h2>
                                    </div>
                                </div>
                            </Grid>
                            <Grid className='element-inner-section' item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className='element-widget-wrap'>
                                    <div className="items-wrapper">
                                        <div><img  src={data_visualization}/></div>
                                        <div className="text-wrapper">
                                            <h3>DATA VISUALIZATION</h3>
                                            <p>Create, save, and distribute a portfolio video summary with engaging visuals</p>
                                        </div>
                                    </div>
                                    <div className="items-wrapper">
                                        <img src={todo}/>
                                        <div className="text-wrapper">
                                            <h3>AUDIT TRAIL</h3>
                                            <p>View detailed documentation of a sequence of activities</p>
                                        </div>
                                    </div>
                                    <div className="items-wrapper">
                                        <img src={calendar}/>
                                        <div className="text-wrapper">
                                            <h3>SCHEDULE IMPORTS</h3>
                                            <p>Schedule date and time to auto-import data for generating videos</p>
                                        </div>
                                    </div>
                                    <div className="items-wrapper">
                                        <img src={customizable_data}/>
                                        <div className="text-wrapper">
                                            <h3>CUSTOMIZABLE DATA</h3>
                                            <p>Create tailored videos that align with your business requirements</p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
            <section className='section-F Section'>
            <div className='img-overlay'></div>
                <div className='container'>
                    <div className="heading-wrapper">
                        <h2>Designed with standout tools</h2>
                    </div>
                    <div className="cards-wrapper">
                        <CardChip img={video_template} descriptionText={"Pre-defined & customizable repository of video templates"} />
                        <CardChip img={video_runtime} descriptionText={"Ability to add dynamic data & graphs inside videos at runtime"} />
                        <CardChip img={Ai_engine} descriptionText={"AI/ML based engine"} />
                        <CardChip img={auto_voice} descriptionText={"Auto voice integration"} />
                    </div>
                </div>
            </section>
            <section className="btn-container Section">
                <Button className='btn' type='primary'>REQUEST A DEMO</Button>
            </section>
        </Grid>
    );
}

export default VideoStatements;
import React from 'react'
import PageTopSection from '../../../common-components/pageTop-section/PageTopSection';
import './CustomSoftwareServices.scss';
import Banner from '../../../../assets/custom-software-services/custom-software-banner.jpg';
import { Grid } from '@mui/material';
import EnterpriseDev from '../../../../assets/custom-software-services/enterprise-product-development.svg';
import CapativeService from '../../../../assets/custom-software-services/capative-as-services.svg'
import MobileDev from '../../../../assets/custom-software-services/mobile-development.svg';
import CheckIcon from '@mui/icons-material/Check';
import CardWithLeftHeader from '../../../common-components/card/CardWithLeftHeader';
import Settings from '../../../../assets/FinTech-wealth-management/capital-market/settings.svg';
import Chat from '../../../../assets/FinTech-wealth-management/capital-market/chat.svg';
import FlowerHands from '../../../../assets/FinTech-wealth-management/flowerHands.svg';
import Desktop from '../../../../assets/custom-software-services/Desktop.svg';
import CaseStudy from '../../../common-components/caseStudy/Index';
import caseStudy1 from '../../../../assets/caseStudy/1.jpg'
import caseStudy2 from '../../../../assets/caseStudy/2.jpg'
import caseStudy3 from '../../../../assets/caseStudy/3.jpg'
import caseStudy4 from '../../../../assets/caseStudy/4.jpg'
import caseStudy5 from '../../../../assets/caseStudy/5.jpg'
import Client from '../../../../assets/home/client.png'
import OurClients from '../../home/our_Clients/Index'

const CustomSoftwareServices = () => {
    const caseStudies = [
        {
          image: caseStudy1,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy2,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy3,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy4,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
        {
          image: caseStudy5,
          heading2: "Cloud Cost Optimization: Reduced AWS OpEx by 45%",
          heading1: "Cloud Service",
          text: "Do you think it’s not possible to reduce AWS OpEx by 45%? In this case study, we will show how we helped our client in doing so."
        },
      ]
    
      const clients = [
        {
          image: Client,
          name: "Larry Baker",
          text: "We find Hexaview’s team to be very transparent & responsive. They are open to solving any problem, however intricate and time-bound. With regular check-ins, they ensure that any feedback is acted upon promptly. Hexaview resources have always helped Adobe ship good quality products.",
          designation: "BaySys Technologies LLC"
        },
        {
          image: Client,
          name: "Larry Baker",
          text: "We find Hexaview’s team to be very transparent & responsive. They are open to solving any problem, however intricate and time-bound. With regular check-ins, they ensure that any feedback is acted upon promptly. Hexaview resources have always helped Adobe ship good quality products.",
          designation: "BaySys Technologies LLC"
        },
        {
          image: Client,
          name: "Larry Baker",
          text: "We find Hexaview’s team to be very transparent & responsive. They are open to solving any problem, however intricate and time-bound. With regular check-ins, they ensure that any feedback is acted upon promptly. Hexaview resources have always helped Adobe ship good quality products.",
          designation: "BaySys Technologies LLC"
        }
      ]
    return (
        <div className='css-main-container paddingTopSection'>
            <PageTopSection
                img={Banner}
                colorH1Heading="Custom Software Services"
                textSectionA={"Now don’t worry about high infrastructure costs, lack of skilled resources, frequent downtime, and application failures."}
                textSectionB={"We provide full support under Custom Software Services to ensure that your project stays on track in terms of time, cost and quality."} />

            <div className="css-section section-A">
                <CommonSection
                    image={EnterpriseDev}
                    h3Heading="Enterprise Product Development"
                    mainList={["We understand your business—and your competitors. Our team of certified professionals provides you end-to-end product development services to build innovative, flexible, high-end, secure, and easy-to-deploy apps and solution",
                        "We leverage the top emerging technologies for web, mobile, cloud, and social to help you gain a competitive edge.",
                        "Our customer-oriented process begins with an in-depth analysis of your requirements and continues with continual support and updates post-delivery."]}
                    h4Heading="Benefits of Enterprise Product Development"
                    isFlexDirectionReverse={false}
                    cardA={[{ image: Settings, heading: "Ultimate User Experience", description: "Our Custom software development solutions help companies enhance user experience keeping them ahead of the market trend." }, { image: Chat, heading: "Innovation", description: "We bring innovation and engagement to your product development while still meeting essential customer needs." }]}

                    cardB={[{ image: FlowerHands, heading: "Sustainability", description: "We help our clients create a sustainable product that can survive multiple market changes." }, { image: Desktop, heading: "Product Growth", description: "We take in whatever exists and build even better products for your company." }]} />
            </div>
            <div className="css-section section-B">
                <CommonSection
                    image={CapativeService}
                    h3Heading="Captive as a Service"
                    mainList={["We establish a fully integrated, multi-functional center in India, offering a complete range of services surrounding feasibility study, development, implementation, integration, and project management.",
                        "CaaS helps you develop your in-house capabilities with a low setup cost and high-order infrastructure that drastically lowers your financial and operational risks. When you outsource your services with us, we provide you 24-7 support, 365 days a year!"]}
                    h4Heading="Benefits of Captive as a Service"
                    isFlexDirectionReverse={true}
                    cardA={[{ image: Settings, heading: "Lower Set-Up Investments", description: "Reduces financial and operational risks." }, { image: Chat, heading: "Simplified Operations", description: "Enhanced product performance through collaborative teamwork." }]}

                    cardB={[{ image: FlowerHands, heading: "Agile Functionality", description: "Effectively responsive to change, risk & uncertainty." }, { image: Desktop, heading: "Data Security and Privacy", description: "Our clients have greater control over both information handled in our center and the people who do the processing." }]} />
            </div>

            <div className="css-section section-c">
                <CommonSection
                    image={MobileDev}
                    h3Heading="Mobile Development"
                    mainList={["We provide you end-to-end mobile application development services. We work on many ubiquitous technologies. Our device diagnostics in many domains gives us the capability to create advanced solutions that meet your unique requirements.",
                        "Our team of experts has a collective 500+ man-years of software development and testing experience that has helped clients achieve a bounty of simple yet effective solutions.",
                        "Whether you're building from scratch or updating an existing product, whether you're a fresh-faced start-up or a long-established enterprise—we are here for you!"]}
                    h4Heading="Benefits of Mobile Development"
                    isFlexDirectionReverse={false}
                    cardA={[{ image: Settings, heading: "Have the Best Performance", description: "Our custom software development solutions leverage your device’s processing speed bringing you mobile apps that are very fast and responsive." }, { image: Chat, heading: "Few Bugs During Development", description: "We provide mobile application development services with the most recent features & fewer dependencies for bugs to occur." }]}

                    cardB={[{ image: FlowerHands, heading: "More Secure", description: "We provide our users reliable data protection." }, { image: Desktop, heading: "More Interactive & Intuitive", description: "We offer a superior user experience to our clients." }]} />
            </div>
            <OurClients array={clients} />
            <CaseStudy array={caseStudies} />
        </div>
    )
}

interface props {
    image?: string,
    h3Heading?: String,
    h4Heading?: String,
    mainList?: any,
    isFlexDirectionReverse?: Boolean,
    cardA?: any,
    cardB?: any,

}
export const CommonSection = ({ image, h3Heading, h4Heading, mainList, isFlexDirectionReverse, cardA, cardB }: props) => {
    const reverse = isFlexDirectionReverse ? 'row-reverse' : 'row';
    return (
        <div className='outer-grid'>
            <div className="inner-grid" style={{ flexDirection: reverse }}>
                <div className='inner-grid-text-section'>
                    <div className="heading">
                        <h3 style={{ fontWeight: "700" }}>{h3Heading}</h3>
                    </div>
                    {
                        mainList.map((item: any) => {
                            return <li> <CheckIcon style={{ width: "25px", paddingRight: "6px" }} /> <p> {item} </p> </li>
                        })
                    }
                    <div className="heading">
                        <h4>{h4Heading}</h4>
                    </div>
                    <Grid container>
                        {
                            cardA.map((item: any) => {
                                return (<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <div className="card-left boxes-grid">
                                        <CardWithLeftHeader
                                            headerImgUrl={item.image}
                                            contentHeading={item.heading}
                                            descriptionText={item.description} />
                                    </div>
                                </Grid>)
                            })
                        }
                    </Grid>

                    <Grid container rowSpacing={2}>
                        {
                            cardB.map((item: any) => {
                                return (<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <div className="card-left boxes-grid">
                                        <CardWithLeftHeader
                                            headerImgUrl={item.image}
                                            contentHeading={item.heading}
                                            descriptionText={item.description} />
                                    </div>
                                </Grid>)
                            })
                        }
                    </Grid>
                </div>
                <div className="image-section">
                    <img src={image} />
                </div>
            </div>
        </div>
        
    )
}

export default CustomSoftwareServices
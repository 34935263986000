import React from 'react';
import ScheduleCall from '../schedule-call/ScheduleCall';
import { useNavigate } from 'react-router-dom';
import "./PageTopSection.scss";
import FluidContainerWrapper from '../../utils/custom-tags/FluidContainerWrapper';
type Props = {
    img?: String,
    isScheduleCall?: Boolean,
    colorH1Heading?: String,
    h1Heading?: String,
    textSectionA?: String,
    textSectionB?: String,
    isTalkToExpert?: Boolean,
    btnText?: String,
}
const PageTopSection = ({
    img,
    isScheduleCall = true,
    colorH1Heading,
    h1Heading, textSectionA,
    textSectionB,
    isTalkToExpert = true,
    btnText = "Talk to our Expert"
}: Props) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/contact');
    }
    return (
        <FluidContainerWrapper className='section-A'
            style={{
                backgroundImage: `url(${img})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition:'center'
            }}
        >
            {/* <div className='container-fluid'> */}
                <div className='image-elements-section'>
                    <div className='element-image-text-wrapper'>
                        <h1 className='heading-text'>{h1Heading}</h1>
                        <h1 className='color-heading'>{colorH1Heading}</h1>
                        <div className='text-section-a'>{textSectionA}</div>
                        <div className='text-section-b'>{textSectionB}</div>
                        {isTalkToExpert && (<div className='talk-to-epert'>
                            <h5 style={{ color: '#43BAFF' }} onClick={handleClick}>{btnText}</h5>
                            <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                        </div>)}
                    </div>
                    <div className='empty-divider' style={{ width: '100px' }}></div>
                    <div className='form-element-section'>
                        {isScheduleCall && (<ScheduleCall />)}
                    </div>
                </div>
            {/* </div> */}
        </FluidContainerWrapper>
    );
}

export default PageTopSection;
import React from 'react';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import RecentResources from '../common/RecentResources';
import "./DataEMServices.scss";
const DataEMServices = () => {
    return (
        <Grid container className='dem-main-container paddingTopSection' style={{ overflow: 'auto' }}>
            <PageTopSection img={"https://hexaviewtech.com/wp-content/uploads/2022/04/Data-Engineering-banner.jpg"}
                h1Heading={"Data Engineering & Data Mining Services"}
                textSectionA={"Extract actionable & decision-fuelling insights from heaps of structured or unstructured data that can deliver progress and transformation for your business."}
                isScheduleCall={true}
            />
            <section className="dem-section-B Section">
                <div className='container'>
                    <Grid container wrap='wrap' justifyContent="space-between" className='element-inner-section'>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className="immage-section">
                                <img width="100%" src='https://hexaviewtech.com/wp-content/uploads/2022/04/Drive-Growth-data-engineering.svg' loading="lazy" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <div className="text-section element-widget-wrap">
                                <h2>Drive Growth with <span>Data Engineering</span> and <span>Data Mining</span> Services</h2>
                                <div className='pera'>
                                    <p>
                                        There is an increasing demand for data engineering services & data mining services. In today’s digital era, business decisions are driven through a blend of data, algorithms, computing power, and human intelligence. Our data mining, and data engineering services enable businesses to unleash the power of their data and ensure that all useful data (structured or unstructured) is recognized, sourced, cleaned, analyzed, modeled, and utilized – without losing on value as the data travels through this path.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="dem-section-C Section">
                <div className='container'>
                    <Grid container wrap='wrap' justifyContent="space-between" className='element-inner-section'>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="text-section element-widget-wrap">
                                <h3>Efficient and Reliable Enterprise Data Engineering Service</h3>
                                <div className='pera'>
                                    <p>There is an increasing demand for data engineering services & data mining services. In today’s digital era, business decisions are driven through a blend of data, algorithms, computing power, and human intelligence. Our data mining, and data engineering services enable businesses to unleash the power of their data and ensure that all useful data (structured or unstructured) is recognized, sourced, cleaned, analyzed, modeled, and utilized – without losing on value as the data travels through this path.</p>
                                    <p>Our dedicated specialists – data engineers – take a holistic approach to data engineering and provide solutions that optimize data science and analytics to help organizations extract maximum value from enterprise data. They are involved with the pre-processing of data which reduces the overheads for data scientists and analysts in the data preparation stages.</p>
                                    <p>Hexaview’s enterprise data lake engineering services will help you make better decisions to maintain robust and scalable data lakes to integrate, manage, analyze and share growing volumes of data.</p>
                                </div>
                                <div className='list-item-wrapper'>
                                    <p>We follow mechanisms for collecting and validating the information that includes:</p>
                                    <ul>
                                        <li><CheckCircleIcon fontSize='small' />Data Modelling (Entity Relationship Diagram (ERD))</li>
                                        <li><CheckCircleIcon fontSize='small' />ETL (Extract, Transform, Load) structures and framework, and</li>
                                        <li><CheckCircleIcon fontSize='small' />Integration of multiple data sources into a single usable model.</li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <div className="immage-section">
                                <img width="100%" src='https://hexaviewtech.com/wp-content/uploads/2022/04/Drive-Growth-data-engineering.svg' loading="lazy" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="dem-section-D Section"
                style={{
                    backgroundImage: `url(https://hexaviewtech.com/wp-content/uploads/2022/04/Insightful-Data-Mining.jpg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}
            >
                <div className='container'>
                    <div className='elements-wrapper'>
                        <div className="text-section element-widget-wrap">
                            <h2>Insightful Data Mining Services</h2>
                            <p>Data Mining is the ability to develop models to get actionable information out of the data once it is in a usable format (from the Data Engineer). That is where companies need data miners. Our Data Mining experts use machine learning techniques, statistical models, regression, time series techniques, or even something as simple as grouping and averages to extract value out of a data set. We offer highly customized data mining services to our clients at many cost-effective rates. Backed by a team of highly competent professionals, we assist you to manage and extract relevant information from data of any size, within the desired time.</p>
                        </div>
                        <div className='btn-wrapper'>
                        <div className='hexa-primary-btn'>
                            <p>Talk to our Expert <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <RecentResources />
        </Grid>
    );
}

export default DataEMServices;
import React from 'react';
import {useNavigate, NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Arrow from '../../../../assets/header/arrow.svg'
import Cloud from '../../../../assets/header/cloud.svg'
import DataScience from '../../../../assets/header/dataScience.svg'
import Fintech from '../../../../assets/header/fintech.svg'
import Gptw from '../../../../assets/header/GPTW.png'
import Plateform from '../../../../assets/header/platform.png'
import Salesforce from '../../../../assets/header/salesforce.svg'
import Software from '../../../../assets/header/software.svg'
import Readmore from '../../../../assets/readmore-icon.svg'
import './style.scss';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const Index = ()=> {

    const [value, setValue] = React.useState(0);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
    


    const mainMenu = (img:any, heading:any, subHeading:any) => <div className='menuDiv'>
        <div className='imgSection'>
            <img src={img} alt="" />
        </div>
        <div className='content'>
            <h5>{heading}</h5>
            <p>{subHeading}</p>
        </div>
        <div className='arrow'>
            <img src={Arrow} alt="" />
        </div>
    </div>




    return (
        <div className='submenu'>
        <Grid container>
            <Grid item xs={12} md={12} lg={4}>
                <div className='home-section-inner-left'>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label={mainMenu(Fintech, "Fintech", "Providing Seamless data flow")} className='customTab' {...a11yProps(0)} />
                            <Tab label={mainMenu(Salesforce, "Salesforce", "Providing Seamless data flow")} className='customTab' {...a11yProps(1)} />
                            <Tab label={mainMenu(Software, "Custom software", "Providing Seamless data flow")} className='customTab' {...a11yProps(2)} />
                            <Tab label={mainMenu(Cloud, "Cloud", "Providing Seamless data flow")} className='customTab' {...a11yProps(3)} />
                            <Tab label={mainMenu(DataScience, "Data Science", "Providing Seamless data flow")} className='customTab' {...a11yProps(4)} />
                            <Tab label={mainMenu(DataScience, "Blockchain", "Providing Seamless data flow")} className='customTab' {...a11yProps(5)} />
                        </Tabs>
                    </Box>
                </div>
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
                <div className='home-section-inner-right'>
                    <TabPanel value={value} index={0}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='middlePart'>
                                <ul>
                                    <li><NavLink to="">Fintech</NavLink></li>
                                    <li><NavLink to="/services/fintech/wealth-management">Wealth Management</NavLink></li>
                                    <li><NavLink to="/services/fintech/capital-market">Capital Market</NavLink></li>
                                    <li><NavLink to="/services/fintech/lending">Lending</NavLink></li>
                                    <li><NavLink to="/services/fintech/on-demand-solutions">On-Demand Solutions</NavLink></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='rightPart'>
                                <h5>FEATURED READS</h5>
                                <img src={Plateform} alt="" />
                                <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
                                <div className='hexa-default-btn'>
                                    <p>Read More <img src={Readmore} alt=""/></p>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='middlePart'>
                                <ul>
                                    <li><NavLink to="/services/Salesforce">Salesforce</NavLink></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='rightPart'>
                                <h5>FEATURED READS</h5>
                                <img src={Plateform} alt="" />
                                <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
                                <div className='hexa-default-btn'>
                                    <p>Read More <img src={Readmore} alt=""/></p>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='middlePart'>
                                <ul>
                                    <li><NavLink to="/services/custom-software-services">Custom Software</NavLink></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='rightPart'>
                                <h5>FEATURED READS</h5>
                                <img src={Plateform} alt="" />
                                <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
                                <div className='hexa-default-btn'>
                                    <p>Read More <img src={Readmore} alt=""/></p>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='middlePart'>
                                <ul>
                                    <li><NavLink to="/services/cloud">Cloud</NavLink></li>
                                    <li><NavLink to="/services/cloud/cloud-migration-solutions">Cloud Migration Solutions</NavLink></li>
                                    <li><NavLink to="/services/cloud/cloud-cost-optimization-services">Cloud Cost Optimization Services</NavLink></li>
                                    <li><NavLink to="/services/cloud/cloud-governance-security-services">Cloud Governance & Security Services</NavLink></li>
                                    <li><NavLink to="/services/cloud/cloud-application-development-and-deployment-services">Cloud Application Development and Deployment Services</NavLink></li>
                                    <li><NavLink to="/services/cloud/hybrid-cloud-computing-solutions">Hybrid Cloud Computing Solutions</NavLink></li>
                                    <li><NavLink to="/services/cloud/devops-consulting-services">DevOps Consulting Services</NavLink></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='rightPart'>
                                <h5>FEATURED READS</h5>
                                <img src={Plateform} alt="" />
                                <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
                                <div className='hexa-default-btn'>
                                    <p>Read More <img src={Readmore} alt=""/></p>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='middlePart'>
                                <ul>
                                    <li><NavLink to="/services/data-science">Data Science</NavLink></li>
                                    <li><NavLink to="/services/data-science/ai-machine-learning-development-services">AI & Machine Learning Development Services</NavLink></li>
                                    <li><NavLink to="/services/data-science/business-intelligence-consulting-services">Business Intelligence Consulting Services</NavLink></li>
                                    <li><NavLink to="/services/data-science/big-data-services">Big Data Services</NavLink></li>
                                    <li><NavLink to="/services/data-science/data-visualization-and-analytics-services">Data Visualization and Analytics Services</NavLink></li>
                                    <li><NavLink to="/services/data-science/data-engineering-data-mining-services">Data Engineering & Data Mining Services</NavLink></li>
                                    <li><NavLink to="/services/data-science/rpa-consulting-development-services">RPA Consulting & Development Services</NavLink></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='rightPart'>
                                <h5>FEATURED READS</h5>
                                <img src={Plateform} alt="" />
                                <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
                                <div className='hexa-default-btn'>
                                    <p>Read More <img src={Readmore} alt=""/></p>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='middlePart'>
                                <ul>
                                    <li><NavLink to="">BlockChain</NavLink></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className='rightPart'>
                                <h5>FEATURED READS</h5>
                                <img src={Plateform} alt="" />
                                <p>Providing seamless data flow solutions through Salesforce & NetSuite Integration</p>
                                <div className='hexa-default-btn'>
                                    <p>Read More <img src={Readmore} alt=""/></p>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                    </TabPanel>
                </div>
            </Grid>
        </Grid>
        </div>
    );
}

export default Index;
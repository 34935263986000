import logo from './logo.svg';
import './App.css';
import '../node_modules/slick-carousel/slick/slick.scss'
import '../node_modules/slick-carousel/slick/slick-theme.scss'


import Core from './core/Core';
import RouterFlow from './web/routes/RouterFlow';
import Header from "./web/pages/Header";
import Footer from './web/pages/Footer';

const App = ()=> {
  return (
   <Core>
    <Header />
      <RouterFlow/>
    <Footer />
    </Core>
  );
}

export default App;

import { Grid } from '@mui/material';
import PageTopSection from '../../../../common-components/pageTop-section/PageTopSection';
import './Sustain.scss';
import img from '../../../../../assets/image.svg';
import flower from '../../../../../assets/flower.svg';
import Adaptation from '../../../../../assets/corporate-overview/adapt.svg';
import Card2 from '../../../../../assets/corporate-overview/Clean-and-Safe-Water.png';
import Card3 from '../../../../../assets/corporate-overview/Energy-Conservation.png';
import Card4 from '../../../../../assets/corporate-overview/Optimum-usage-of-equipment.png';
import Card1 from '../../../../../assets/corporate-overview/Sustainable-Remote-Work.png';
const Sustain = (props: any) => {
    return (
        <Grid container className="sustain-elements-main-container paddingTopSection">
            <PageTopSection
                img={"https://hexaviewtech.com/wp-content/uploads/2022/07/sustainability-banner.jpg"}
                colorH1Heading="SUSTAINABILITY"
                textSectionA="Developing technology solutions to help businesses and societies prosperwe are making sustainability a force for change"
                isScheduleCall={false}
                isTalkToExpert={false}
            ></PageTopSection>

            {/* Section A words from the ceo and cdo */}

            <section className="sustain-section-A element-widget-wrap Section">
                <div className='container'>
                    <Grid container className='element-inner-section'>
                        <Grid className="section-A-left" item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <div className="heading">
                                <h2>WORDS FROM <span style={{ color: "#43BFFA" }}> CEO AND CDO</span></h2>
                            </div>
                            <div className="text-section">
                                <div>
                                    <p>
                                        “Being a global company, we take the responsibility to use our expertise and resources to help address the global climate change issues. I am super proud to say that Hexaview commits to reduce its carbon footprint and to extend our expertise in AI, ML, Cloud, IoT, and related technologies to help our global 50 clients meet their sustainability goals, reduce their carbon footprints and sharpen their competitiveness.”
                                    </p>
                                </div>
                            </div>
                            <div className="hexa-primary-btn">
                                <p>Join Network<img src={img} alt="Image Button" /></p>
                            </div>
                        </Grid>

                        <Grid className="section-A-right" item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <div className="image-section">
                                <img style={{ width: "400px", height: "400px" }} src="https://hexaviewtech.com/wp-content/uploads/2022/07/ceo-cdo-1.jpg" alt="Main Image" />
                            </div>

                        </Grid>
                    </Grid>
                </div>
            </section>

            {/* Section B our Mission*/}
            <section className="sustain-section-B Section">
                <div className='container'>
                    <div className="heading">
                        <h2 style={{ color: "#43BAFF" }}>OUR MISSION</h2>
                    </div>
                    <Grid container className="boxes">
                        <Boxes
                            image={flower}
                            text={"Protect and restore the environment by using natural and artificial resources optimally and responsibily"} />
                        <Boxes
                            image={Adaptation}
                            text={"Continuously adapt and use greener sources of fuel to run our operations."} />
                        <Boxes
                            image={flower}
                            text={"Reduce, Reuse, Recycle, and Manage waste."} />
                    </Grid>
                    <div className="text-section">
                        <p style={{ color: "#ffffff", padding: "0rem 1.5rem 0rem 1.5rem" }}>
                            In September 2015, at the UN Sustainable Development Summit held in New York, 17 Sustainable Development Goals were adopted to make our world more prosperous, inclusive, sustainable, and resilient.</p>
                    </div>

                    {/* Join Network Button from the Section A  */}
                    <div className="hexa-primary-btn">
                        <p>Join Network<img src={img} alt="Image Button" /></p>
                    </div>
                </div>
            </section>

            <section className="sustain-section-C sustain-section-A element-widget-wrap">
                <div className='container'>
                    <Grid container className='element-inner-section'>
                        <Grid className="section-C-left section-A-left" item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="heading">
                                <h3>HEXAVIEW'S COMMITMENTS AND ACTIVITIES</h3>
                            </div>
                            <div className="heading">
                                <h2 style={{ color: "#43BAFF" }}>TOWARDS SUSTAINABLE DEVELOPMENT</h2>
                            </div>
                            <div className="text-section">

                                <p>
                                    Sustainable practices represent a core part of Hexaview’s culture and history, driving continuous improvement in every aspect of its business. It is of paramount importance to observe these sustainability principles to ensure continuity of results and to add value to our development as we grow.</p>


                                <p>
                                    Hexaview Technologies strives to uphold the ten principles of the United Nations Global Compact (UNGC) at all times in the areas of human rights, labor, environment, and anti-corruption. Hexaview applies its firms’ skills, resources, and influence to pursue Global Goals. Our people (HVTians) contribute to all 17 Sustainable Development Goals through their practices. Our team leads and participates in different initiatives that address critical social and environmental issues. We aim to contribute to the development of underprivileged people actively. We believe in bettering local communities’ lives and supporting their sustainability, cleanliness drives, waste management, clean water management, and green initiatives.</p>

                            </div>
                        </Grid>
                        <Grid className="section-C-right" item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className="image-section">
                                <img style={{ width: "300px", height: "500px" }} src="https://hexaviewtech.com/wp-content/uploads/2022/07/sustainable-energy-campaign-tree-light-bulb-media-remix-3.jpg" alt="Main Image" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>

            {/* Section D Alternate Left and right */}
            <section className="sustain-section-D Section">
                <div className='container'>
                    <div className="main-heading">
                        <h2>WORKING TOWARDS OUR GOALS</h2>
                    </div>

                    <Common
                        image={Card1}
                        heading={"Sustainable Remote Work"}
                        text={"The COVID-19 pandemic enforced upon us a work-from-model model since March 2020. The remote working model has allowed us to operate our development centers with minimal staff and resources. We assisted every HVTian to work remotely by providing laptops known to consume lower amounts of power than a computer. Most of our hardware infrastructure was in the cloud, and we expanded it further as we supported our people and customers remotely. This resulted in considerable savings in power usage at our offices. With the work-from-home fashion continuing to be the new normal, we aim to do more to ensure our business operations run sustainably."}
                        isFlexDirectionRev={true}></Common>
                    <Common
                        image={Card2}
                        heading={"Providing Clean and Safe Water for All"}
                        text={"Safe water is crucial to daily life, not only for consumption but also for other purposes and overall health. The fact that almost 30% of the world’s population lives without it is disheartening. To make clean drinking water available to all, our CSR team installed a Reverse Osmosis (RO) based Water Cooler outside Hexaview’s Parking Zone’s exit gate."}
                        isFlexDirectionRev={false}></Common>
                    <Common
                        image={Card3}
                        heading={"Energy Conservation"}
                        text={"We implement measures to conserve energy by using energy-efficient equipment with the latest technologies, which would help preserve energy."}
                        isFlexDirectionRev={true}></Common>
                    <Common
                        image={Card4}
                        heading={"Optimum usage of equipment"}
                        text={"We ensure optimum usage of all our electric or power gadgets by ensuring that there is no cool air leakage; Usage of LED / LCD monitors (energy-efficient); Turning off monitors when not in use; Turning off lights in all floors when people are not working; Turning off the air conditioners during non-peak hours and on weekends; Usage of treated water to recharge ground water; Installation of sun film to dissipate heat; Usage of LED lights for all its lighting solution."}
                        isFlexDirectionRev={false}></Common>
                </div>
            </section>

        </Grid>
    );
}

type props = {
    image?: string,
    heading?: String,
    text?: String,
    isFlexDirectionRev?: Boolean
}

export const Common = ({ image, heading, text, isFlexDirectionRev }: props) => {
    const reverse = isFlexDirectionRev ? 'row-reverse' : 'row';
    return (
        <Grid spacing={2} className="section-D-common" style={{ flexDirection: reverse }}>
            <Grid container item xs={12} sm={12} md={8} lg={8} xl={8}>
                <div className="element-widget-wrap">

                    <div className="heading"> <h3 style={{ color: "#43BAFF" }}> {heading} </h3> </div>
                    <div className="text-section-D"> <p> {text} </p> </div>
                </div>

            </Grid>
            <div className="image-container"> <img style={{ width: "250px", height: "250px" }} src={image} alt="" /> </div>


        </Grid>
    );
}

type boxProps = {
    image?: string,
    text?: String | React.ReactNode
}

export const Boxes = ({ image, text }: boxProps) => {
    return (
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="boxes-main-container">
                <div className="box">
                    <div className="box-image"> <img src={image} alt="" /> </div>
                    <div className="text-section"> <p> {text} </p> </div>
                </div>
            </div>
        </Grid>
    )
}

export default Sustain;
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Button, Input } from 'antd';
import LinkedInIcon from '../../../assets/socialIcons/linkedin.svg';
import YouTubeIcon from '../../../assets/socialIcons/youtube.svg';
import TwitterIcon from '../../../assets/socialIcons/twitter.svg';
import FacebookIcon from '../../../assets/socialIcons/facebook.svg';
import EmailIcon from '../../../assets/socialIcons/email.svg';
import CallIcon from '../../../assets/socialIcons/phone.svg';
import "./Footer.scss";
const Footer = () => {

    const navigate = useNavigate();
    
    const handleClick = () => {
        navigate('/contact');
    }
    return (
        <footer className="footer-section">
             <div className='footer-top-section-elements-row'>
                <div className='container-fluid'>
                <Grid container className='element-inner-section'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='footer-top-section-text-elements'>
                            <h4>Discover your digital transformation journey</h4>
                            <h2>Let's do something amazing together</h2>
                            

                            <div className='hexa-default-btn'>
                              <p onClick={handleClick}>Get Started <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" alt=""/></p>
                            </div>
                        </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className='footer-top-section-btn-elements'>
                            <Button className='btn' onClick={handleClick}>Talk to Expert</Button>
                        </div>
                    </Grid> */}
                </Grid>
                </div>
             </div>
                <div className='footer-menu-div'>
                    <div className='container'>
                        <div className="footer-section-B">
                            <div className="footer-section-B-Service">
                                <div className='titles'>Services</div>
                                <div className='section-B-Service-items'>
                                    <div>
                                        <ul>
                                            <li><NavLink to="/services/Salesforce"><span>Salesforce</span></NavLink></li>
                                            <li><NavLink to="/services/data-science"><span>Data Science</span></NavLink></li>
                                            <li><NavLink to="/services/cloud"><span>Cloud</span></NavLink></li>
                                            <li><NavLink to="/services/css"><span>Custom Software Services</span></NavLink></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul>
                                            <li><NavLink to="/services/FinTech/wm"><span>Wealth Management</span></NavLink></li>
                                            <li><NavLink to="/services/FinTech/Lending"><span>Lending</span></NavLink></li>
                                            <li><NavLink to="/services/FinTech/cm"><span>Capital Market</span></NavLink></li>
                                            <li><NavLink to="/services/FinTech/ods"><span>On-Demand Solutions</span></NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='footer-section-B-products'>
                                <div className='titles'>Products</div>
                                <div className='footer-section-B-products-items'>
                                    <ul>
                                        <li><a href="https://www.reportwa.com/"><span>ReportWa</span></a></li>
                                        <li><NavLink to="/products/pdf-scrapper"><span>PDF Scrapper</span></NavLink></li>
                                        <li><NavLink to="/products/video-statements"><span>Video Statements</span></NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-section-B-Service">
                                <div className='titles'>Quick Links</div>
                                <div className='section-B-Service-items'>
                                    <div>
                                        <ul>
                                            <li><NavLink to="/corporate-overview/About"><span>About Us</span></NavLink></li>
                                            <li><NavLink to="/corporate-overview/Security"><span>Security</span></NavLink></li>
                                            <li><NavLink to="/corporate-overview/Tech_Stack"><span>Tech Stack</span></NavLink></li>
                                            <li><NavLink to="/corporate-overview/Careers"><span>Career</span></NavLink></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul>
                                            <li><a href="https://hexaviewtech.com/salesforce/"><span>Blog</span></a></li>
                                            <li><a href="https://hexaviewtech.com/salesforce/"><span>Success Stories</span></a></li>
                                            <li><NavLink to="/Resources/Press_Release"><span>Press Release</span></NavLink></li>
                                            <li><NavLink to="/corporate-overview/csr/si"><span>Social Impact</span></NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-section-B-connect">
                                <div className='titles'>Quick Connect</div>
                                <div className='footer-section-B-connect-icons'>
                                    <img src={LinkedInIcon} alt="" />
                                    <img src={YouTubeIcon} alt="" />
                                    <img src={TwitterIcon} alt="" />
                                    <img src={FacebookIcon} alt="" />
                                </div>
                                <div className='social-Icons'>
                                    <p><img src={EmailIcon} alt="" /> hello@hexaviewtech.com</p>
                                    <p><img src={CallIcon} alt="" /> +1 (646) 403-4525</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-subsription-div'>
                    <div className='footer-section-C'>
                        <div className='container'>
                            <div className="footer-section-C-row">
                                <div className='address-container'>
                                    <h3><strong>Hexaview Technologies, Inc.</strong></h3>
                                    <p><span>Headquarters (USA)</span>
                                    #7, Quaker Dr., East Brunswick, NJ, USA 08816</p>

                                </div>
                                <div className='subscribe-text-container'>
                                    <p>Stay in the know with our newsletter</p>
                                    <h2 >Subscribe</h2>
                                </div>
                                <div className='input-btn-container'>
                                    <input className='cust-input' placeholder='Enter Email' />
                                    <img src="https://hexaviewtech.com/wp-content/uploads/2021/06/banner-readmore.svg" />
                                </div>

                            </div>
                        </div>
                </div>
            </div>
            <div className='footer-copyriht-div'>
                <div className='footer-section-D'>
                    <div className='container'>
                        <div className="footer-section-D-row">
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="copy-right-container">
                                        <div >
                                            <p>Copyright © 2022 Hexaview Technologies, Inc.</p>
                                        </div>
                                        <div className='terms-item'>
                                            <ul className='list-item'>
                                                <li ><a href="/privacy-policies"><span style={{ paddingLeft: '0px' }}>Privacy Policies</span></a></li>
                                                <li ><a href='/cookie-policy'><span>Cookie Policy</span></a></li>
                                                <li ><a href='/terms-and-conditions'><span>Terms and Conditions</span></a></li>
                                                <li ><a href='/legal-notice'><span>Legal Notice</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="copy-right-images-container">
                                        {/* <img width="9%" src='https://hexaviewtech.com/wp-content/uploads/2021/11/great-placeto-work-badge.svg' /> */}
                                        <img width="20%"src='https://hexaviewtech.com/wp-content/uploads/2021/06/ISO-2-2.png' />
                                        <img width="20%"src='https://hexaviewtech.com/wp-content/uploads/2021/06/ISO-3-2.png' />
                                        <img width="17%"src='https://hexaviewtech.com/wp-content/uploads/elementor/thumbs/PledgeOneLogo2-pkozq4awg1dk817bte3wtl0zg66qwd5uh7wpb5tp0o.png'/>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    </div>
                </div>
        </footer>
    );
}

export default Footer;